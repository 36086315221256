@import '../../styles/variables';

.journal-popup-content {
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  padding: 1rem;
  border-radius: 0.5rem;
}

.journal-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 1020;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.header-profile-hover {
  margin: 0 1.2px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  &:hover {
    color: #ff0f51 !important;
  }
  & > p {
    font-weight: 500;
  }
  & p:hover {
    color: #ff0f51 !important;
    margin: 0;
    transition: 300ms;
  }
}
.header_offset {
  height: 69.8px;
}

.headercustomcss {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  }
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: $white;
  box-shadow: 0px 1px 3px $gray-7;

  &__logo {
    img {
      width: 218.35px;
      -webkit-backface-visibility: hidden;
      -ms-transform: translateZ(0); /* IE 9 */
      -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
      transform: translateZ(0);
      @media screen and (max-width: 767px) {
        width: 30vw;
        min-width: 110px;
      }
      @media screen and (max-width: 380px) {
        flex-grow: 1;
        min-width: unset;
      }
      margin-right: auto;
    }
  }

  &__logo-new {
    img {
      width: 218.35px;
      -webkit-backface-visibility: hidden;
      -ms-transform: translateZ(0); /* IE 9 */
      -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
      transform: translateZ(0);
      @media screen and (max-width: 767px) {
        width: 30vw;
        min-width: 110px;
      }
      @media screen and (max-width: 380px) {
        width: unset;
        min-width: unset;
      }
    }
    @media screen and (max-width: 991px) {
      margin-right: auto;
      margin-left: auto;
    }
    @media screen and (max-width: 380px) {
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }
  }

  &__container {
    padding-left: 66px;
    padding-right: 75px;
  }

  &__dropdown {
    display: none;
  }

  &__nav {
    padding-left: 30px;
  }

  &__link-item {
    margin-right: 16px;
    margin-left: 60px;
    @media screen and (max-width: 991px) {
      display: none;
    }
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      .headercustomcss__link {
        &:hover {
          color: $black !important;
          border-bottom: none;

          &:after {
            content: none;
          }
        }
      }
    }
  }

  &__link-item-new {
    margin-right: 11vw;
    margin-left: 60px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__form {
    flex-grow: 1;
    margin-left: 28px;

    & > div:nth-child(1) {
      display: flex;
      flex-grow: 1;
      border: 1px solid #ecedee;
      border-radius: 8px;
      padding: 8px 12px;
      align-items: center;
      & > input {
        flex-grow: 1;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 14px;
        border: 0;

        &:focus {
          border: 0;
          outline: 0;
        }
      }
    }
    @media screen and (max-width: 991px) {
      margin-left: 0;
      margin-top: 8px;
      & > div:nth-child(1) {
        & > input {
          font-size: 12px;
        }
      }
    }

    & > .search-bar-new-open {
      position: absolute;
      margin-top: 12px;
      box-shadow: 0px 1px 3px #eaeaea;
      background-color: white;
      border: 1px solid #dcdacb;
      border-radius: 4px;
      z-index: 1000;
      overflow: hidden;
      max-height: 40vh;
      overflow-y: scroll;
      padding: 1.6rem 0;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #f2f2f2;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(133, 133, 133);
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgb(126, 126, 126);
      }

      & > h2 {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 14px;
        padding: 0.8rem 1.6rem;
      }

      & > a {
        display: flex;
        align-items: center;
        padding: 0.8rem 1.6rem;

        &:hover {
          background-color: rgb(222, 222, 222);
        }

        & > div:nth-child(1) {
          width: 120px;
          height: auto;
          margin-right: 16px;
        }
        & > div:nth-child(2) {
          max-width: 70%;
          & > h2 {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 6px;
          }
          & > h4 {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 12px;
            margin-bottom: 6px;
          }
          & > h3 {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 12px;
            margin-bottom: 0;
          }
        }
      }
      @media screen and (max-width: 991px) {
        border: none;
        padding: 0;
        box-shadow: none;
        & > h2 {
          font-size: 12px;
        }

        & > a {
          padding: 0.8rem;
          & > div:nth-child(1) {
          }
          & > div:nth-child(2) {
            max-width: 55%;
            & > h2 {
              font-size: 12px;
            }
            & > h4 {
              font-size: 10px;
            }
            & > h3 {
              font-size: 10px;
            }
          }
        }
      }
    }

    & > .search-bar-new-close {
      height: 0;
      position: absolute;
      transition: all 0.1s ease-out;
      overflow: hidden;
      padding: 0 1.6rem;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    text-transform: uppercase;
    padding: 24px 3px;
    transition: all 0.3s;
    font-size: 16px;

    &:hover {
      color: $pink !important;

      &:after {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 33px;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: $pink;
      opacity: 0;
      transition: all 0.3s;
    }
  }

  &__link-new {
    position: relative;
    display: flex;
    padding: 24px 3px;
    transition: all 0.3s;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      color: $pink !important;
      & path {
        fill: $pink !important;
        transition: all 0.3s;
      }

      &:after {
        opacity: 1;
      }
    }

    &:after {
      background-color: $pink;
      opacity: 0;
    }
  }

  &__link-arrow {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 10px;
  }

  &__menu-button {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
    display: none;
  }

  &__user {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    &a {
      font-size: 16px;
    }
  }

  &__user-icon {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  &__user-separator {
    margin: 0 5px;
  }
}

@media screen and (max-width: 1200px) {
  .headercustomcss {
    &__link {
      font-size: 12px !important;
    }
    &__user a {
      font-size: 12px !important;
    }
  }
}

@media screen and (max-width: 1100px) {
  .headercustomcss {
    &__container {
      padding-left: 30px;
      padding-right: 30px;
    }
    &__logo {
      padding: 7px 0;
      margin-left: 14px;
    }
  }
}

@media screen and (max-width: 991px) {
  .header_offset {
    height: 62px;
  }
  .headercustomcss {
    height: 60px;

    &__nav {
      display: none;
    }

    &__account {
      display: none;
    }

    &__menu-button {
      display: flex;
      justify-content: center;
      min-width: 80px;
    }

    &__container {
      padding: 10px 16px;
    }

    &__logo {
      max-width: 60%;
    }
  }

  .masuk-daftar {
    color: white;
    font-size: 16px;
  }
}

@media screen and (max-width: 510px) {
  .headercustomcss {
    height: 60px;
    &__container {
      padding-left: 16px;
      padding-right: 16px;
    }

    &__logo {
      max-width: 60%;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 380px) {
  .headercustomcss {
    &__menu-button {
      display: flex;
      justify-content: center;
      min-width: unset;
    }
    & .ant-btn {
      padding: 4px 0 4px 8px;
    }
  }
}

.ant-dropdown-menu-item:hover {
  background-color: white !important;
}

.contanctuswa-btn {
  transition: margin-bottom 0.2s ease;
  width: 98vw;
  height: 0;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  margin-bottom: 48px;
  margin-right: 24px;
  z-index: 3;
  @media screen and (max-width: 740px) {
    margin-bottom: 40px;
    margin-right: 12px;
  }

  &__text1 {
    margin-bottom: 0;
    font-size: 12px;
    @media screen and (max-width: 740px) {
      font-size: 10px;
    }
  }

  &__text2 {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 20px;
    @media screen and (max-width: 740px) {
      font-size: 14px;
      line-height: 14px;
    }
  }

  &__img {
    height: 36px;
    width: 36px;
  }

  &__btn {
    color: #067134;
    align-items: center;
    display: flex;
    background-color: #92e4b4;
    padding: 12px 18px;
    font-size: 18px;
    box-shadow: 0 4px 8px 0 rgba(63, 63, 63, 0.2), 0 8px 12px 0 rgba(27, 27, 27, 0.2);
    border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease-out;
    border: 1px solid #219653;

    &:hover {
      background-color: #7ae4a6;
      color: #067134;
    }

    -webkit-transition: all 0.2s ease-out;
    -webkit-box-shadow: 0 4px 8px 0 rgba(27, 27, 27, 0.2), 0 8px 12px 0 rgba(27, 27, 27, 0.075);

    @media screen and (max-width: 740px) {
      padding: 8px 12px;
      font-size: 12px;
    }
  }
}

.contanctuswa-btn-course-detail {
  transition: margin-bottom 0.2s ease;
  margin-bottom: 160px;
  @media screen and (max-width: 911px) {
    margin-bottom: 128px;
  }
  @media screen and (max-width: 416px) {
    margin-bottom: 110px;
  }
}

.menu-new-homepage {
  top: -5px;
  font-family: 'Poppins';
  display: flex;
  padding: 20px 20px 36px 20px !important;

  & > .ant-dropdown-menu-item {
    border-right: 1px solid #ecedee;
    padding: 0 16px 0 4px;
    min-width: 140px;

    & > span > a {
      margin: 0;
      padding: 8px 16px;
      font-family: 'Poppins';
      color: #000000;
      font-weight: 400;
    }

    & > span > h2 {
      margin: 0;
      padding: 8px 16px 8px 16px;
      color: #000000;
      font-weight: 600;
      font-family: 'Poppins';
      font-size: 18px;
      border-bottom: 1px solid #ecedee;
    }

    & > span > .menu-new-bold {
      font-weight: 600;
    }
  }
  & > .ant-dropdown-menu-item:nth-child(3) {
    padding: 0 40px 0 24px;
  }
  & > .ant-dropdown-menu-item:nth-child(3) {
    border-right: 0;
    padding: 0 24px 0 12px;
  }
}
