.course-video {
  margin-top: 30px;
  min-height: 440px;
  background-color: black;
}

.sticky-container-video {
  width: 810px;
}

.button_video {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.video-btn-card {
  width: 160px;
  cursor: pointer;
}

.video-btn-card-disabled {
  width: 160px;
  cursor: pointer;
  color: rgb(187, 187, 187) !important;
  box-shadow: 0px 1px 3px rgba(149, 149, 149, 0.25) !important;
}

@media screen and (max-width: 991px) {
  .course-video {
    margin-top: 4px;
    min-height: 200px;
    background-color: black;
  }

  .button_video {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
  }

  .video-btn-card {
    width: 120px;
    cursor: pointer;
  }

  .video-btn-card-disabled {
    width: 120px;
    cursor: pointer;
    color: rgb(187, 187, 187) !important;
    box-shadow: 0px 1px 3px rgba(149, 149, 149, 0.25) !important;
  }
}

@media screen and (max-width: 370px) {
  .button_video {
    font-weight: 300;
    font-size: 8px;
  }

  .video-btn-card {
    width: 80px;
    cursor: pointer;
  }
}

.video-preview-bottom {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  }
  & > h2 {
    font-weight: 600;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 20px;
  }
}

.video-preview {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  }
  padding: 40px 0;
  @media screen and (max-width: 1200px) {
    padding: 40px 16px;
    padding-right: 16px !important;
  }
  @media screen and (max-width: 991px) {
    padding: 16px;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
    & > div:nth-child(1) {
      width: 56.5%;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 991px) {
        width: calc(100vw - 32px);
      }
      & > h2 {
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
        margin-bottom: 16px;
      }
      & > .video-preview-bar {
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 24px;
        @media screen and (max-width: 991px) {
          min-height: 244px;
          height: 54.27vw;
          & .btn {
            padding: 10px 16px;
          }
        }
        & > h2 {
          font-weight: 700;
          font-size: 16px;
          color: #ffffff;
          margin-bottom: 32px;
          text-align: center;
        }
      }
    }
    & > div:nth-child(2) {
      width: 42.6666666667%;
      margin-left: auto;
      @media screen and (max-width: 991px) {
        width: calc(100vw - 32px);
        margin-left: 0;
      }
      & > div:nth-child(1) {
        & > div {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 991px) {
            flex-direction: column;
          }
        }
        & h2 {
          font-weight: 700;
          font-size: 16px;
          color: #ffffff;
          margin-bottom: 16px;
          margin-left: 8px;
          @media screen and (max-width: 991px) {
            margin-bottom: 4px;
            margin-top: 24px;
            font-weight: 600;
            margin-left: 0;
          }
        }
        & h2:nth-child(2) {
          font-weight: 600;
          @media screen and (max-width: 991px) {
            margin-bottom: 24px;
            margin-top: 0;
            font-weight: 600;
            margin-left: 0;
          }
        }
      }
      & > div:nth-child(2) {
        flex-direction: column;
        height: 381px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          background: none;
        }
        &::-webkit-scrollbar-thumb {
          background: #ffffff;
          border-radius: 100px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #e4e2e2;
        }
        & > div {
          margin-bottom: 8px;
          & > div {
            background: #3f4f5a;
            padding: 12px 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            & > h2 {
              font-weight: 500;
              font-size: 16px;
              color: #ffffff;
              margin-bottom: 0;
              margin-left: 8px;
              display: block;
              @media screen and (max-width: 991px) {
                font-size: 14px;
              }
            }
            & > h2:nth-child(3) {
              text-align: right;
              margin-left: auto;
              max-width: unset;
              width: 35%;
              display: inline-block;
              white-space: unset;
              overflow: unset;
            }
            &:hover {
              background: #313c45;
            }
          }
          & > div:nth-child(1) {
            & > h2 {
              font-weight: 600;
              font-size: 16px;
              color: #ffffff;
              margin-bottom: 0;
              max-width: unset;
              margin-left: 0;
            }
            &:hover {
              background: #3f4f5a;
            }
          }
        }
        & > div:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &-selected {
    background: #313c45 !important;
  }
}

.lessons {
  &__lesson__title {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column !important;
  }

  &__lesson__dates__date {
    color: white;
    font-size: 0.75rem !important; /* 12px */
    line-height: 1rem !important; /* 16px */
  }
}
