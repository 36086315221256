.course-resource {
    margin-top: 30px;
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    padding: 25px 20px;
    height: 549px;
    overflow-y: scroll;
    @media screen and (max-width: 991px) { 
        height: 300px;
    }
    
    &::-webkit-scrollbar {
        width: 4px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }

    & h1, p {
        font-family: 'Montserrat';
    }

    &__title {
        text-align: center;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        @media screen and (max-width: 991px) { 
            font-size: 16;

        }
    }

    &__row {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 17px 20px;
        background-color: white;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 12px;
        @media screen and (max-width: 991px) { 
            padding: 10px 14px;
        }
    }

    &__name {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        width: 80%;
        @media screen and (max-width: 991px) { 
            font-size: 14px;
        }
    }

    &__icon {
        margin-left: 13px;
        margin-right: 19px;
        @media screen and (max-width: 991px) { 
            width: 32px;
            height: 32px;
        }
    }

    &__desc {
        font-weight: 300;
        font-size: 12px;
        line-height: 24px;
        @media screen and (max-width: 991px) { 
            font-size: 10px;
            line-height: 18px;
        }
    }

    &__desc__bold {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        margin-left: 8px;
        @media screen and (max-width: 991px) { 
            font-size: 10px;
        }
    }

    &__icon-dl {
        width: 24px;
        height: 16px;
        @media screen and (max-width: 991px) { 
            width: 16px;
            height: 12px;
        }
    }

    &__btn {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid #FF0F51;
        box-sizing: border-box;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 8px;
        min-width: 144px !important;
        cursor: pointer;
        @media screen and (max-width: 991px) { 
            padding: 4px;
            min-width: 105px !important;
        }   

        &:hover {
            background: #f5f5f5;
        }
    }

    &__content {
        flex-grow: 1;
    }
}

.course-resource-btn {
    @media screen and (max-width: 991px) { 
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    &__icon-dl {
        width: 24px;
        height: 16px;
        @media screen and (max-width: 991px) { 
            width: 12px;
            height: 8px;
        }
    }
    &__title {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 991px) { 
            font-size: 12px;
            margin-bottom: 0;
            margin-top: 5px;
            margin-left: auto;
        }
    }

    &__card {
        display: flex;
        flex-direction: row;
        padding: 10px 16px;
        align-items: center;
        width: fit-content;
        @media screen and (max-width: 991px) { 
            padding: 4px;
            margin-left: 10px;
        }
    }
}