.course-rating-detail {
    padding: 28px;
    margin: 20px 0;

    

    &__review-row {
        display: flex;
        padding: 20px 0 0 0;

        &__img {
            margin-right: 12px;
            &__img {
                width: 30px !important;
                height: 30px !important;
            }
            
        }

        &__rating {
            width: 90%;
            
            padding-bottom: 16px;
        }

        &__name {
            font-weight: 700;
            line-height: 14px;
            font-size: 14px;
        }

        &__stars {
            margin-bottom: 4px;
        }

        &__review {
            font-weight: 500;
            margin-bottom: 8px;
        }

        &__disukai {
            font-size: 12px;
        }

        &__cons {
            display: flex;
            margin-top: 8px;
            flex-wrap: wrap;
            &__child {
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                color: #6A6060;
                border: 1px solid #6A6060;
                box-sizing: border-box;
                border-radius: 144px;
                padding: 6px 16px;
                margin-right: 8px;
                margin-bottom: 8px;
            }
        }
    }

    &__summary {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 12px !important;
        background-color: #EDF8FF;
        border: 1px solid #5A97BA;
        box-sizing: border-box;
        border-radius: 4px;

        &__big {
            font-size: 36px;
            line-height: 42px;
            margin: 0 !important;
            color: #10608E;
            font-weight: 700;
        }

        &__small {
            margin: 0 !important;
            color: #10608E;
            font-weight: 500;
        }

        &__count {
            font-weight: 500;
        }
    }

    &__legend {
        align-items: center;

        &__small {
            margin-bottom: 0 !important;
            font-weight: 500;
            margin-left: 6px;
            margin-right: 12px;
        }
        &__bar {
            flex: 1;
            height: 10px;
            background-color: #DDDDDD;
            border-radius: 20px;

            &__active {
                height: 10px;
                background: #FFC632;
                border-radius: 20px;
            }
        }
    }

    &__main-title {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
    }
}



@media screen and (max-width: 960px) {
    .course-rating-detail {
        padding: 16px;
        margin: 16px 0;
    
        &__wrap {
            padding-left: 0;
        }
    
        &__main-title {
            font-size: 18px;
            margin-bottom: 0;
        }
    }
}

.course-rating-detail-new {
    padding-top: 0;
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    margin: 24px 0;
    @media screen and (max-width: 992px) {
        padding: 0 16px;
    }

    &__review-row {
        background: #FFFFFF;
        border: 1px solid #CED3D7;
        box-sizing: border-box;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 16px;

        & > div {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            & > div:nth-child(1) {
                width: 46px;
                height: 46px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            & > div {
                & > div {
                }
    
                & > h2 {
                    margin: 0 auto 0 10px;
                    font-weight: 700;
                    font-size: 16px;
                }
            }
            & > div:nth-child(2) {
                & > div {
                    min-width: 90px;
                    margin: 0 auto 0 8px;
                }
                
            }

            & > div:nth-child(3) {
                align-self: flex-start;
                margin-left: auto;
                & > h3 {
                    font-weight: 600;
                    font-size: 14px;
                    text-align: right;
                }
            }
            

        }

        & > h2 {
            font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
            font-weight: 600;
            font-size: 16px;
        }

        & > p {
            font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
            font-weight: 400;
            font-size: 16px;
        }

        & > div:nth-child(3) {
            display: block;
            margin-top: 10px;
            margin-bottom: 0;
            & > p {
                font-weight: normal;
                font-size: 14px;
                color: #738492;
            }

            & > div {
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
                & > p {
                    text-transform: uppercase;
                    background: #FFFFFF;
                    border: 1px solid #ECEDEE;
                    box-sizing: border-box;
                    border-radius: 100px;
                    padding: 8px 10px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    margin-bottom: 4px;
                }
                

            }
        }
    }

    &__summary {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px !important;
        background: #FFFFFF;
        border: 1px solid #CED3D7;
        box-sizing: border-box;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        margin-bottom: 16px;
        @media screen and (max-width: 991px) {
            border: none;
            box-shadow: none;
            border-radius: 0;
            margin-bottom: 0;
            padding: 0 !important;
        }

        &__big {
            font-weight: 600;
            font-size: 54px;
            line-height: 56px;
            margin: 0 !important;
            color: #10608E;
            font-weight: 700;
            @media screen and (max-width: 991px) {
                font-size: 32px;
                line-height: 34px;
            }
        }

        &__small {
            margin: 0 !important;
            color: #10608E;
            font-weight: 400;
            font-size: 21.32px;
        }

        &__count {
            font-weight: 500;
        }
    }

    &__legend-wrap {
        padding: 16px !important;
        background: #FFFFFF;
        border: 1px solid #CED3D7;
        box-sizing: border-box;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        margin-bottom: 16px;
        @media screen and (max-width: 991px) {
            flex-grow: 1;
            border: none;
            box-shadow: none;
            border-radius: 0;
            margin-bottom: 0;
            padding: 0 !important;
            margin-left: 16px;
        }
        & > p {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 8px;
        }
    }

    &__legend {
        align-items: center;
        margin-bottom: 4px;

        &__small {
            margin-bottom: 0 !important;
            font-weight: 500;
            margin-left: 6px;
            margin-right: 12px;
        }
        &__small-right {
            margin-bottom: 0 !important;
            font-weight: 500;
            margin-left: 6px;
            width: 30px;
        }
        &__bar {
            flex: 1;
            height: 10px;
            @media screen and (max-width: 991px) {
                height: 8px;
            }
            background-color: #DDDDDD;
            border-radius: 20px;

            &__active {
                height: 10px;
                @media screen and (max-width: 991px) {
                    height: 8px;
                }
                background: #FFC632;
                border-radius: 20px;
            }
        }
    }

    &__main-title {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px;
        @media screen and (max-width: 991px) {
            font-size: 16px;
        }
    }
    &__wrap {
        flex-wrap: wrap;
        & > div:nth-child(1) {
            padding-left: 0;
            @media screen and (max-width: 991px) {
                display: flex;
                padding: 16px;
                background: #FFFFFF;
                border: 1px solid #CED3D7;
                box-sizing: border-box;
                box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
                border-radius: 8px;
                margin-bottom: 16px;

            }
        }
        & > div:nth-child(2) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    & > div:nth-child(3) {
        @media screen and (max-width: 991px) {
            justify-content: center !important;
        }
    }
}   



@media screen and (max-width: 960px) {
    .course-rating-detail {
        padding: 16px;
        margin: 16px 0;
    
        &__wrap {
            padding-left: 0;
        }
    
        &__main-title {
            font-size: 18px;
            margin-bottom: 0;
        }
    }
}