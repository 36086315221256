.all-instructor {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    display: flex;
    justify-content: center;

    & .form-control {
        &:focus {
            box-shadow: none;
        }
    }

    & > div {
        width: 83.33333%;
        margin-bottom: 40px;
        max-width: 1200px;
        @media screen and (max-width: 991px) {
            width: 100%;
            padding: 0 16px;
        }
    }

    @media screen and (max-width: 991px) {
        margin-right: 0;
        margin-left: 0;
    }

    &__text {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
    }

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
        @media screen and (max-width: 991px) {
            font-size: 20px;
        }
    }

    &__name {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        @media screen and (max-width: 991px) {
            font-size: 20px;
        }
    }

    &__search-instructor-margin{
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-top: -32px;
        @media screen and (max-width: 991px) {
            margin-left: 0;
            width: 100%;
        }
    }

    & .row {
        margin-bottom: 32px;
    }

}

.instructor-list {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    
    background-color: white;
    height: 292px;
    padding-top: 16px;
    padding-left: 16px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin: 1px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 991px) {
        height: 340px;
        background: none;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0);
        border-radius: 0;
        border-bottom: 1px solid #DDDDDD;
        flex-direction: row;
        height: 100%;
        min-height: 100px;
        padding-bottom: 20px;
    }

    &__img {
        max-height: 230px;
        margin-top: auto;
        margin-left: auto;
        @media screen and (max-width: 991px) {
            margin-top: 0;
            margin-left: 0;
            max-height: 62px;
            border-radius: 30px;
            margin-right: 12px;
        }   
    }   

    &:hover {
        color: #FF0F4F !important;
    }
    &:active {
        color: #FF0F4F !important;
    }
}

.instructor-detail {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    display: flex;
    justify-content: center;
    & > div {
        width: 83.33333%;
        margin-bottom: 40px;
        max-width: 1200px;
        @media screen and (max-width: 991px) {
            width: 100%;
            padding: 0 16px;
        }
    }
    &__img {
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        justify-content: center;
        display: flex;
        height: 100%;

        @media screen and (max-width: 991px) {
            background: none;
            border: 0;
        }

        &-photo {
            @media screen and (max-width: 991px) {
                max-height: 120px;
                border-radius: 60px;
            }
        }
    }   

    &__img-margin {
        @media screen and (max-width: 991px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
            margin-bottom: 44px;
        }
    }

    &p {
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        @media screen and (max-width: 991px) {
            font-size: 12px !important;
        }
    }

    &__row-margin {
        margin: 0 -12px;
        @media screen and (max-width: 991px) {
            margin: 30px 0 0 0;
            padding-left: 34px;
            padding-right: 34px;
        }
        & > .justify-content-center {
            padding-right: 12px;
            padding-left: 12px;
            width: 100%;
        }
    }

    &__text {
        & p {
            font-weight: 300;
            font-size: 14px;
            line-height: 22px;
            @media screen and (max-width: 991px) {
                font-size: 12px !important;
            }
        }
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }
}

.container-instructor-detail {
    margin-top: 32px;
}