.class-content {
    font-size: 24px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.class-contentdetailpage {
    font-size: 24px;
    font-weight: 500;
    font-family: 'Montserrat';
    @media screen and (max-width: 911px) {
        font-size: 16px;
    }
}

.class-subcontent-hosted-detail {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    cursor: default;
    }

.class-content-detail {
    font-size: 16px;
    font-weight: 300;
    font-family: 'Montserrat';
}

.class-content-materi {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat';
    white-space: nowrap;
}

.hosted-table {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    padding: 12px 24px;
    box-sizing: border-box;
    margin-top: -2px;
    cursor: pointer;
    transition: ease;
}

.hosted-table-selected {
    background-color: #EEFAFF;
}

// .ant-dropdown-menu-item:hover {
//     // background-color: #FFFFFF;
// }

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child,
.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
    padding: 0;
    margin-top: 10px;
}

.table {
    .ant-table-ping-right .ant-table-cell-fix-right-first:after,
    .ant-table-ping-right .ant-table-cell-fix-right-last:after,
    .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container:after {
        display: none;
    }

    &__icon {
        display: flex;
        align-items: center;
        & svg {
            margin-right: 8px;
        }
    }

    &.ant-table-wrapper {
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
    }

    .ant-table-thead > tr > th {
        background: #EEFAFF;
        color:#000000;
        font-size: 12px;
        font-weight: 500;
        padding-top: 11px;
        padding-bottom: 11px;

        @media screen and (min-width: 576px) {
            font-size: 16px;
        }

        
        &:first-child {
            padding-left: 48px;
        }

        &:nth-of-type(2) {
            padding-left: 42px;
        }

        &:nth-of-type(3) {
            text-align: center;
        }

        @media screen and (min-width: 576px) {
            &:last-child {
                display: flex;
                justify-content: flex-end;
                padding-right: 82px;
            }
        }
    }

    .ant-table-row.ant-table-row-level-0 {
        
        & .ant-table-cell {
            font-size: 16px;
            padding-top: 11px;
            padding-bottom: 10px;
            font-weight: 300;

            color:#000000;


            &:first-child {
                padding-left: 46px;
            }

            &:nth-of-type(2) {
                padding-left: 12px;
            }

            &:nth-of-type(3) {
                text-align: center;
            }

            @media screen and (min-width: 576px) {
                &:last-child {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 82px;
                }
            }
            
        }
    } 

    &--info {
        .ant-table-row {
            background: url('/images/courses/listLiveCourse.png') no-repeat 20px center;
            padding-left: 40px ; 
            margin-bottom: 3px;
        }

        .ant-table-thead > tr > th {
            font-size: 14px;

            &:first-child {
                padding-left: 17px;

                @media screen and (min-width: 576px) {
                    // padding-left: 26px;
                }
            }
    
            &:last-child {
                padding-right: 20px;
            }
        }
        .ant-table-row.ant-table-row-level-0 {
            pointer-events: none;
            & .ant-table-cell {
                &:first-child {
                    background: url('/images/courses/listLiveCourse.png') no-repeat 20px center;
                    padding-left: 37px;

                    @media screen and (min-width: 76px) {
                        padding-left: 54px;
                    }
                }

                
    
                &:last-child {
                    padding-right: 24px;
                    font-size: 14px;
                    line-height: 2;
                    padding-top: 8px;
                    background-color: white;
                }
            }
        }
    }

    &--schedule {
        .ant-table-thead > tr > th {
            &:first-child {
                @media screen and (max-width: 575px) {
                    padding-left: 8px;
                }
            }

            :nth-of-type(2) {
                padding-left: 32px;
            }
        }

        .ant-table-row.ant-table-row-level-0 {
            .ant-table-cell {
                @media screen and (max-width: 575px) {
                    font-size: 12px;
                    // padding: 8px;
                }

                &:first-child {
                    @media screen and (max-width: 575px) {
                        padding-left: 8px;
                    }
                }
            }
        }

        .ant-table-tbody > tr > td,
        .ant-table-thead > tr > th,
        .ant-table tfoot > tr > td,
        .ant-table tfoot > tr > th {
            padding: 8px;
        }
    }

    &--schedule-pick {
        .ant-table-thead > tr > th {
            &:first-child {
                @media screen and (max-width: 575px) {
                    padding-left: 8px;
                }
            }

            :nth-of-type(2) {
                text-align: left;
            }

            &:nth-of-type(4) {
                text-align: center;
            }
            &:nth-of-type(5) {
                text-align: center;
            }

            &:last-child {
                display: table-cell;
                text-align: center;
                padding-right: 24px;
            }
        }

        .ant-table-row.ant-table-row-level-0 {
            .ant-table-cell {
                cursor: pointer;
                text-align: center;
                font-size: 14px;
                @media screen and (max-width: 575px) {
                    font-size: 12px;
                    // padding: 8px;
                }
                &:nth-of-type(5) {
                    text-align: center;
                }
                &:nth-of-type(2) {
                    text-align: left;
                }
                &:first-child {
                    padding-left: 8px;
                    width: 20px;
                    text-align: center;
                    @media screen and (max-width: 575px) {
                        width: 36px;
                        padding-left: 8px;
                    }
                }
                &:last-child {
                    padding-right: 24px;
                    font-size: 14px;
                }
            }
        }

        .ant-table-tbody > tr > td,
        .ant-table-thead > tr > th,
        .ant-table tfoot > tr > td,
        .ant-table tfoot > tr > th {
            padding: 8px;
        }

        .ant-table-thead > tr > th {
            &:nth-of-type(2) {
                padding-left: 12px;
            }
        }
    }

    &--schedule-qna {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        .ant-table-thead > tr > th {
            background-color: white;
            &:first-child {
                padding-left: 20px;
                @media screen and (max-width: 575px) {
                    padding-left: 8px;
                }
            }

            &:nth-of-type(2) {
                text-align: center;
            }

            &:nth-of-type(3) {
                text-align: right;
                @media screen and (max-width: 575px) {
                    width: 40px;
                }
            }
            &:nth-of-type(4) {
                width: 156px;
                margin-left: 48px;
                @media screen and (max-width: 575px) {
                    padding-left: 16px;
                    width: 90px;
                }
            }
            &:last-child {
                display: table-cell;
                padding-right: 24px;
            }
        }

        .ant-table-row.ant-table-row-level-0 {
            .ant-table-cell {
                @media screen and (max-width: 575px) {
                    font-size: 12px;
                    // padding: 8px;
                }

                &:first-child {
                    padding-left: 20px;
                    @media screen and (max-width: 575px) {
                        padding-left: 8px;
                    }
                }
                &:nth-of-type(2) {
                    text-align: center;
                }
                &:nth-of-type(3) {
                    text-align: right;
                    @media screen and (max-width: 575px) {
                        width: 40px;
                    }
                }
                &:nth-of-type(4) {
                    width: 156px;
                    padding-left: 48px;
                    @media screen and (max-width: 575px) {
                        padding-left: 16px;
                        width: 90px;
                    }
                }
                &:last-child {
                    display: flex;
                    justify-content: center;
                    padding-right: 24px;
                    font-size: 14px;
                    line-height: 2;
                    padding-top: 8px;
                    background-color: white;
                }
            }
        }

        .ant-table-tbody > tr > td,
        .ant-table-thead > tr > th,
        .ant-table tfoot > tr > td,
        .ant-table tfoot > tr > th {
            padding: 8px;
        }

        .ant-table-thead > tr > th {
            &:nth-of-type(2) {
                padding-left: 12px;
            }
        }
    }
}