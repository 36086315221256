.terms-and-privacy {
  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    font-size: 14px;
  }

  ul,
  ol {
    margin-left: 24px;
  }
}
