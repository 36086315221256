// White
$white: #FFFFFF;

// Gray
$gray-1: #626262;
$gray-2: #DDDDDD;
$gray-3: #8F8F8F;
$gray-4: #F5F5F5;
$gray-5: #333333;
$gray-6: #C4C4C4;
$gray-7: #EAEAEA;

//Black 
$black: #000000;

// Blue
$blue-1: #004F8A;
$blue-2: #C6EAF8;
$blue-3: #A4E6FF;
$blue-4: #288AAF;
$blue-5: #76CAEA;

// Pink
$pink: #FF0F51;

$body-bg: #FAFAFA;