* {
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
}

.how-to-download {
  display: flex;
  flex-direction: column;
  gap: 24px;

  & h2 {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  }

  .flex-gap-6 {
    display: flex;
    gap: 24px;
    align-items: center;

    @media screen and (max-width: 991px) {
      align-items: flex-start;
    }

    & p {
      font-size: 16px;

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }

    a.link {
      color: #e5002c !important;
      font-weight: 600;

      :hover {
        color: #e5002c !important;

        :focus {
          color: #e5002c !important;
        }
      }
    }

    .number {
      min-width: 48px;
      min-height: 48px;
      border-radius: 100%;
      background-color: #ffe8ef;
      display: flex;
      justify-content: center;
      align-items: center;

      & p {
        font-size: 24px;
        font-weight: 700;
        color: #f88c9a;
      }
    }
  }
}
