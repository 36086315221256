.landing-card-new {
    margin: 0 24px 4px 2px;
    margin-top: 24px;
    & > .card {
        width: 100%;
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 10px;
        & > a {
            width: 100%;
            &:hover {
                color: unset !important;
            }
           
            & > div:nth-child(1) {
                min-height: 300px;
                background-position: left top;
                background-size: cover;
                display: flex;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                flex-direction: column;
                & > div:nth-child(1)  {
                    margin: 8px 8px auto 0;
                    display: flex;
                    justify-content: flex-end;
                    
                    & > p {
                        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                        padding: 4px 8px;
                        font-weight: 400;
                        font-size: 12px;
                        color: #FFFFFF;
                        background: rgba(32, 40, 46, 0.8);
                        border-radius: 100px;
                    }
                }
                & > div:nth-child(2)  {
                    margin: 0 0 16px 20px;
                }
            }
            & > div:nth-child(2) {
                padding: 16px;
                & > h2 {
                    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                    font-weight: 700;
                    font-size: 16px;
                    color: #20282E;
                    height: 48px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
                & > h3 {
                    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    color: #20282E;
                }
                & > p  {
                    display: flex;
                    align-items: center;
                    & > p {
                        font-size: 14px;
                        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                    }
                }

                & > div {
                    display: flex;
                    align-items: center;
                    & > div {
                        display: flex;
                        margin-top: 12px;
                    }

                    & .retail-price-new {
                        & > p {
                            font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                            font-weight: 600;
                            font-size: 16px;
                            color: #20282E; 
                            margin-right: 4px;
                        }
                        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        color: #20282E;
                    }

                    & .retail-price-new-not {
                        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                        font-weight: 400;
                        font-size: 12px;
                        text-decoration-line: line-through;
                        color: #738492;
                    }

                    
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        & > .card{
            & > a {
                & > div:nth-child(1) {
                        min-height: 180px;
                    & > div:nth-child(1)  {
                        & > p {
                            font-size: 12px;
                        }
                    }
                    & > div:nth-child(2)  {
                    }
                    
                }
                & > div:nth-child(2) {
                    & > h2 {
                        font-size: 14px;
                        height: 35px;
                    }
                    & > h3 {
                        font-size: 12px;
                    }
                    & > p  {
                        font-size: 12px;
                            & > p  {
                            font-size: 12px;
                        }
                    }
                }
            }
            & > div {
                padding: 0 16px 16px 16px;
            
            }
        }
    }
}

.non-slider-card {
    margin: 0;
}