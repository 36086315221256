.info {
    padding: 18px 10% 33px 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Montserrat';

    &__text {
        margin-bottom: 14px;
    }

    &__title {
        margin-bottom: 8px;
        margin-top: 9px;
        font-family: 'Montserrat';
        font-weight: 500;
    }

    &__button {
        cursor: pointer;
    }

    &__detail li {
        background: url('/images/courses/listCheck.png') no-repeat left 3px;
        padding-left: 28px;
        font-size: 16px;
        margin-bottom: 4px;
        font-weight: 500;
    }

    &__detail li > ul {
        margin-top: 4px;
    }

    &__detail li > ul > li {
        background: url('/images/courses/listCheck.png') no-repeat left 4.5px;
        background-size: 12px;
        padding-left: 22px;
        font-size: 14px;
        margin-bottom: 4px;
        font-weight: 500;
    }

    &__detail_external li {
        background: url('/images/courses/listExternal.png') no-repeat left 3px;
        padding-left: 28px;
        font-size: 16px;
        margin-bottom: 4px;
    }

    &__detail_wrap {
        margin-bottom: 20px;
    }
}

.infoItem {
    &__title {
        padding: 6px 0 3px;
        font-family: 'Montserrat';
        margin-bottom: 0;
        font-weight: 500;
    }

    &__item-text {
        padding-left: 11px;
        line-height: 26px;
        font-family: 'Montserrat';
        font-weight: 300;
    }

    &__list-item {
        padding: 5px 0;
    }
}

@media screen and (max-width: 960px) {
    .info {
        padding: 18px 16px 14px;
        margin-top: 22px;
        margin-bottom: 18px;

        &__text {
            margin-bottom: 0;
        }

        &__title {
            margin-bottom: 6px;
            font-size: 18px;
        }
    }

    .infoItem {
        &__title {
            padding-top: 10px;
        }
    }
}

.info-new {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }

    padding: 0;
    margin-top: 24px;
    margin-bottom: 20px;
    @media screen and (max-width: 991px) {
        padding: 0 16px;
        margin-top: 0;
    }

    &__button {
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        text-decoration-line: underline;
        color: #FF0F4F;
        margin-left: 4px;
    }

    & > h1 {
        
        margin-bottom: 16px;
        display: inline-block;
        vertical-align: middle;
        @media screen and (max-width: 991px) {
            margin-bottom: 0;
            line-height: 18px;
            margin-top: 8px;
        }
        @media screen and (max-width: 460px) {
            margin-top: 0;
        }
        & > span:nth-child(1) {
            font-weight: 600;
            font-size: 32px;
            line-height: 24px;
            @media screen and (max-width: 991px) {
                font-size: 22px;
                margin-top: 8px;
            }
            @media screen and (max-width: 460px) {
                font-size: 16px;
                margin-top: 0;
            }
        }
        & > span:nth-child(2) {
            background: #222a30c4;
            border-radius: 100px;
            color: #FFFFFF;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            padding: 0 10px;
            text-transform: capitalize;
            margin-bottom: 80px;
            position: relative;
            bottom: 4px;
            left: 8px;
            white-space: nowrap;
        }
    }

    & > h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 24px;
        @media screen and (max-width: 991px) {
            font-size: 16px;
            line-height: unset;
            margin-bottom: 16px;
            margin-top: 8px;
        }
        @media screen and (max-width: 460px) {
            font-size: 12px;
        }
    }

    &__nav {
        display: flex;
        border-bottom: 1px solid #CED3D7;
        margin-bottom: 32px;
        @media screen and (max-width: 991px) {
            margin-bottom: 24px;
        }
        & > div {
            cursor: pointer;
            & > p {
                padding: 8px 24px;
                font-weight: 400;
                font-size: 16px;
            }
        }

        &-selected:after {
            width: 100% !important; 
            left: 0 !important; 
        }
        & > div:after {    
            background: none repeat scroll 0 0 transparent;
            bottom: 0;
            content: "";
            display: block;
            left: 50%;

            height: 3px;
            position: relative;
            background: #FF0F4F;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
            margin-bottom: -3px;
        }
        &-selected {
            & p {
                color: #FF0F4F;
                font-weight: 700 !important;
                margin-bottom: -3px;
            }
            & svg {
                margin-bottom: -3px;
            }
        }
    }
}

.info-new-detail {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 40px;
    @media screen and (max-width: 991px) {
        padding: 0 16px;
        margin-bottom: 24px;
    }

    & > h2 {
        font-weight: 700;
        font-size: 16px;
        @media screen and (max-width: 991px) {
            margin-bottom: 16px;
         }
    }
    & > p {
        white-space: pre-wrap;
        font-size: 16px;
        color: #20282E;
        @media screen and (max-width: 991px) {
            text-align: left;
        }
        & > span {
            white-space: pre-wrap;
        }
    }

    &__level {
        display: flex;
        align-items: flex-end;
        margin-top: 24px;
        @media screen and (max-width: 991px) {
            max-width: 100%;
            justify-content: flex-start;
            background: #FFFFFF;
            border: none;
            box-sizing: border-box;
            box-shadow: none;
            border-radius: 8px;
            padding: 8px 0;
            margin-bottom: 16px;
        }
        @media screen and (max-width: 460px) {
            max-width: 100%;
            justify-content: center;
            border: 1px solid #ECEDEE;
            box-sizing: border-box;
            box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        }
        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 24px;
            border-left: 1px solid #CED3D7;
            @media screen and (max-width: 991px) {
                padding: 0 16px;
            }
            & > p {
                margin-top: 8px;
                font-weight: 700;
                font-size: 16px;
                @media screen and (max-width: 991px) {
                    font-size: 14px;
                }
            }
            & > h2 {
                height: 48px;
                display: flex;
                align-items: center;
                margin-bottom: 0;
                font-weight: 700;
                font-size: 32px;
            }
        }
        & > div:nth-child(1) {
            padding-left: 0;
            border-left: none;

        }
    }

    &__detail {
        & li {
            background: url('/images/courses/listCheck2.png') no-repeat left 1px;
            padding-left: 32px;
            font-size: 16px;
            margin-bottom: 6px;
            font-weight: 400;
            & > ul {
                margin-top: 4px;
                & > li {
                    background: url('/images/courses/listCheck2.png') no-repeat left 4.5px;
                    background-size: 12px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
    
    &__title {
        font-weight: 700 !important;
        font-size: 16px !important;
        margin-bottom: 16px;
    }

    &__detail_external li {
        background: url('/images/courses/listExternal.png') no-repeat left 3px;
        padding-left: 28px;
        font-size: 16px;
        margin-bottom: 4px;
    }

    &__detail_wrap_outline {
        margin-top: 32px;
        background: #FFFFFF;
        border: 1px solid #ECEDEE;
        box-sizing: border-box;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        padding: 24px;
        & > div:nth-child(1) {
            margin-bottom: 16px;
            padding-bottom: 10px;
            border-bottom: 1px solid #CED3D7;

        }
    }
    
}

.infoItem {
    &__title {
        padding: 6px 0 3px;
        font-family: 'Montserrat';
        margin-bottom: 0;
        font-weight: 500;
    }

    &__item-text {
        padding-left: 11px;
        line-height: 26px;
        font-family: 'Montserrat';
        font-weight: 300;
    }

    &__list-item {
        padding: 5px 0;
    }
}

@media screen and (max-width: 960px) {
    .info {
        padding: 18px 16px 14px;
        margin-top: 22px;
        margin-bottom: 18px;

        &__text {
            margin-bottom: 0;
        }

        &__title {
            margin-bottom: 6px;
            font-size: 18px;
        }
    }

    .infoItem {
        &__title {
            padding-top: 10px;
        }
    }
}
