
.assignment {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    padding: 16px;
    
    &__title {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
    }

    &__description {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        padding: 0 16px; 
    }

    &__download {
        text-align: left;
        font-size: 14px;
        margin: 0 32px 0 32px;
        padding: 8px 16px;
        background-color: rgba(164, 230, 255, 0.19);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: 7px solid #A4E6FF;
    }

    &__button {
        margin: 0 32px 0 32px;
        width: 86%;
    }
}

.rating {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    

    &__desc {
        text-align: left;
        font-size: 12px;

        &_1 {
            padding: 8px 16px 16px 16px;
            background-color: rgba(164, 230, 255, 0.19);
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-left: 7px solid #A4E6FF;
            font-size: 14px;
        }

        &_2 {
            margin: 0 32px 0 32px;
            font-size: 14px;
        }

        &_3 {
            margin: 0;
            font-size: 14px;
        }
    }

    &__stars {
        margin: 20px 0;
        .course-rating {
           margin: 0 4px; 
        }
    }

    &__small {
        font-size: 12px;
        font-weight: 300;
        margin-left: 32px;
    }

    &__pros-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 16px;
    }

    &__pros {
        border-radius: 144px;
        margin: 8px 8px;
        padding: 8px 16px;
        font-size: 12px;
        white-space: nowrap;
        border: 1px solid #FF0F51;
        cursor: pointer;

        &-unselected {
            background: white;
            color: #FF0F51;
        }

        &-selected {
            color: white;
            background: #FF0F51;
        }
    }

    &__clickable {
        &__clicked {
            padding: 8px 24px;
            background-color: #FF0F51;
            border-radius: 28px;
            font-size: 12px;
            color: white;
            cursor: pointer;
        }

        &__not {
            padding: 8px 24px;
            border: 1px solid #FF0F51;
            color: #FF0F51;
            border-radius: 28px;
            font-size: 12px;
            background-color: white;
            cursor: pointer;
        }
    }

    &__ulasan {
        text-align: center;
        font-size: 16px;
        margin: 0 32px 0 32px;
        font-weight: 500;

        &__input{
            border: 0;
            border-bottom: 1px solid #FF0F51;
            padding: 0;
            border-radius: 0;
            transition: 0.5s;

            &:focus{
                box-shadow: 0 0 0 0 rgba(0,123,255,.25);
                border-bottom: 2px solid #FF0F51;
                padding-bottom: 8px;
            }
        }
    }

}

.modal-size-course {
    width: 560px;
    max-width: 100vw;
}

.rating-modal {
    padding: 0 28px 16px 28px;
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    & > h1 {
        font-weight: 600;
        font-size: 20px;
    }
    & > h2 {
        font-weight: 500;
        font-size: 16px;
        align-self: flex-start;
    }

    & > small {
        align-self: flex-start;
        font-size: 10px;
        font-style: italic;

    }

    &__input{
        width: 100%;
        margin-bottom: 12px;

        & > p {
            margin-bottom: 2px;
            font-size: 12px;
            margin-top: 12px !important;
        }

        & > .form-control {
            border: 0;
            width: 100%;
            border-bottom: 1px solid #FF0F51;
            padding: 0;
            border-radius: 0;
            transition: 0.5s;
            padding-left: 8px;

            &:focus{
                box-shadow: 0 0 0 0 rgba(0,123,255,.25);
                border-bottom: 2px solid #FF0F51;
                padding-bottom: 8px;
            }
        }

        & > textarea {
            height: 4rem !important;
            padding-top: 6px !important;
            border-radius: 4px;
        }
    }

    &__btn {
        color: white;
        margin-top: 20px;
        margin-bottom: 20px;

        &:hover {
            color:  #FF0F51;
        }
    }

    & .btn {
        color: white !important;
    }
}

.modal-dialog-course {
    transform: none;
    margin: 1.75rem auto;
}

.modal-content-course {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}

.course_modal {
    background-color: rgba(0, 0, 0, .9);
    bottom: 0;
    content: "";
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.btn-assignment {
    padding: 6px 18px;
    font-weight: 500;
}


.btn-assignment-white {
    color: #FF0F51 !important;
}

.btn-assignment-pink {
    color: white !important;
}

@media screen and (max-width: 761px) { 
    .course_modal {
        background-color: rgba(0, 0, 0, .9);
        bottom: 0;
        content: "";
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        display: flex;
        align-items: flex-end;
    }

    .modal-size-course {
        width: 100%;
    max-width: 100vw;
}

    .modal-dialog-course {
        margin: 0;
        align-items: flex-end;
        min-height: 100vh;
        max-width: 100vw;

    }

    .modal-content-course  {
        border-radius: 10px 10px 0px 0px;
        min-height: 50vh;
    }

    .tab-content-course  {
        flex: 1;
    }

    .fade-scale {
        transform: scale(0);
        opacity: 0;
        -webkit-transition: all .25s linear;
        -o-transition: all .25s linear;
        transition: all .25s linear;
    }
    
    .fade-scale.in {
        opacity: 1;
        transform: scale(1);
    }
}

.info-kelas-self-vprogress-warning {
    padding: 8px 16px;
    margin: 26px 33px 33px 33px;
    background: rgba(164, 230, 255, 0.19);
    border-radius: 8px;
    border-left: 10px solid #A4E6FF;
    & h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    & p {
        font-weight: 300;
        font-size: 16px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 20px;
        margin: 0 33px 0 33px;
    }
}

.modal-hosted-course {
    padding: 0 20px 20px 20px;

    & .btn {
        color: white !important;
    }
}