.course-exam {
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    padding: 20px;
    margin-top: 30px;
    display: flex;
    min-height: 500px;
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
}

.vertical-line {
    border-right: 1px solid #DDDDDD;
}

.exam-finish {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    width: 100%;
    height: 100%;
    // background-image: url('/images/courses/examFailed.png');
    background-repeat: no-repeat;
    padding: 20px;
    margin-top: 30px;
    display: flex;
    min-height: 500px;
    @media screen and (max-width: 991px) { 
        width: 99vw;
        padding: 12px;
        background-size: contain;
    }

    &__btn {
        color: white !important;
        font-size: 16px;
        padding-top: 12px;
        padding-bottom: 12px;

        &:hover {
            color: white !important;
        }
    }

    &__modal {
        padding: 24px;
        background-color: white;
        border: 1px solid #DDDDDD;
        @media screen and (max-width: 991px) { 
            width: 99vw;
        }

    }

    &__title {
        font-weight: 500;
        font-size: 20px;
        margin-top: 8px;
        margin-bottom: 42px;
        text-align: center;
    }

    &__title-perlecture {

        & > h1 {
            font-weight: 600;
            font-size: 20px;
            margin-top: 8px;
            text-align: center;
            margin-bottom: 8px;
        }
        & > p {
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            margin-bottom: 20px;
        }
        
    }

    &__score_out {
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        padding: 12px;
    }

    &__score_in {
        padding: 0 48px;
        @media screen and (max-width: 991px) { 
            padding: 0 12px;
        }
    }

    &__score {
        font-family: 'Montserrat';
        &__benar{
            font-size: 24px;
            font-weight: 500;
            color: #1BC588;
        }
        &__salah{
            font-size: 24px;
            font-weight: 500;
            color: #FF0F51;
        }
        &__nilai{
            font-size: 24px;
            font-weight: 500;
            color: #004F8A;
        }
    }
}

.exam {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    &__number span {
        font-size: 28px;
        background-color: #FF0F51;
        color: white;
        display: flex;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 8px;
        @media screen and (max-width: 991px) { 
            width: 36px;
            height: 36px;
            font-size: 20px;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 0;
        @media screen and (max-width: 991px) { 
            font-size: 16px;

        }
    }

    &__next {
        background: #FFFFFF;
        border: 1px solid #FF0F51;
        box-sizing: border-box;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        font-weight: 500;
        color: #FF0F51;
        &:hover {
            color: #FF0F51 !important;
        }
        &:active {
            color: #FF0F51 !important;
        }
        &:focus {
            color: #FF0F51 !important;
        }
    }

    &__kumpulkan {
        background: #FF0F51;
        border: 1px solid #FF0F51;
        box-sizing: border-box;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        font-weight: 500;
        color: white !important;
        min-width: 120px;
        &:hover {
            color: #FFF !important;
        }
        &:active {
            color: #FFF !important;
        }
        &:focus {
            color: #FFF !important;
        }
       
    }
}

.exam-answers {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    cursor: pointer;
    &__answer{
        background-color: white;
        padding: 12px;
        border-radius: 8px;
        font-size: 14px;
        line-height: 24px;

        @media screen and (max-width: 991px) { 
            font-size: 14px;

        }

        &__selected {
            border: 1px solid #FF0F51;
            background-color: white;
            padding: 12px;
            border-radius: 8px;
            font-size: 14px;
            line-height: 24px;
        }

    }

    &__option {
        color: black;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 24px;
        @media screen and (max-width: 991px) { 
            font-size: 16px;

        }

        &__selected {
            color: #FF0F51;
            font-weight: 500;
            font-size: 24px;
        }
    }
}

