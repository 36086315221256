.onboarding-register {
    & p, h1, h2, h3, h4, a, span, button, label { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    & > div {
        padding-top: 24px;
        padding-bottom: 120px;
        & > div:nth-child(1) {
            cursor: pointer;
            & img {
                width: 220px;
                height: 32px;
            }
        }
        & > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            & .inner-container {
                width: 496px;
                @media screen and (max-width: 767px) {
                    max-width: 90vw;
                }
                & > div:nth-child(1) {
                    display: flex;
                    justify-content: flex-end;
                }
                & > div:nth-child(2) {
                    display: flex;
                    justify-content: space-between;
                    & > div {
                        width: 31%;
                        padding-top: 8px;
                        & div {
                            height: 4px;
                            background: #E0F3FC;
                            border-radius: 70px;
                            transition: background 0.2s ease;
                        }
                    }
                    & .selected {
                        & div {
                            background: #4EBAEA;
                        }
                    }
                    
                }
                & > div:nth-child(3) {
                    & .form-minat {
                        & > div {
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 8px;
                            & > div {
                                border: 1px solid #FF0F51;
                                box-sizing: border-box;
                                border-radius: 10px;
                                margin: 0px 16px 16px 0;
                                padding: 6px 10px;
                                cursor: pointer;
                                & > p {
                                    color: #FF0F51;
                                    font-weight: 500;
                                }
                            }
                            & > .unselected {
                                border: 1px solid  #8B9AA5;
                                & > p {
                                    color:  #8B9AA5;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                    & .form-provinsi {
                        margin-top: 8px;
                    }
                    & .form-group {
                        flex-direction: column;
                        display: flex;
                        margin-bottom: 20px;
                    }
                    & .form-control {
                        margin-top: 8px;
                        font-size: 14px;
                        padding: 0 12px;
                        font-weight: 500;
                    }
                    & .form-datepicker {
                        display: flex;
                        align-items: center;
                        & .react-datepicker-wrapper {
                            width: 80%;
                            margin-right: auto;
                        }
                        & input {
                            border: none;
                            width: 100%;
                            margin-left: 8px;
                            &:focus-visible {
                                outline: none;
                            }
                        }
                        & > div:last-child {
                            cursor: pointer;
                        }
                        & span {
                            color: black;
                        }
                    }
                    & > h2 {
                        margin-top: 36px;
                        margin-bottom: 42px;
                        font-weight: 700;
                        font-size: 20px;
                    }
                    & span {
                        font-weight: 600;
                        color: #FF0F51;
                        font-style: normal;
                    }
                    & h4 {
                        font-style: italic;
                        font-size: 12px;
                        margin-top: 42px;
                    }
                    & .btn {
                        width: 100%;
                        margin-top: 26px;
                        font-weight: 500;
                        height: 48px;
                    }
                }
            }
        }
    }

    & p, label {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .react-datepicker-popper {
        width: 496px;
        right: 20px !important;
        @media screen and (max-width: 767px) {
            max-width: 90vw;
        }
        

        & div { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
        & .react-datepicker {
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #F0F0F0;
            box-sizing: border-box;
            box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
            border-radius: 4px;
            &__triangle {
                display: none;
            }
            &__year-read-view--selected-year {
                margin-top: 12px;
                font-size: 16px;
                @media screen and (max-width: 767px) {
                    font-size: 12px;
                }
            }
            &__year-read-view--down-arrow {
                margin-top: 4px;
            }

            &__navigation--previous {
                margin-top: 16px;
                &:focus-visible {
                    outline: none;
                }
                &:focus {
                    outline: none;
                }
            }

            &__navigation-icon--previous::before {
                border-color: black;
                border-width: 1px 1px 0 0;
            }
            &__navigation-icon--next::before {
                border-color: black;
                border-width: 1px 1px 0 0;
            }

            &__navigation--next {
                margin-top: 16px;
                &:focus-visible {
                    outline: none;
                }
                &:focus {
                    outline: none;
                }
            }

            &__navigation {
                overflow: inherit;
            }

            &__current-month {
                font-weight: 400;
                font-size: 16px;
                margin-top: 16px;
                margin-bottom: 8px;
            }

            &__month-container {
                width: 100%;
            }

            &__header {
                background: white;
                border-bottom: none;
                margin-bottom: 20px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 4px;
                }
            }

            &__day-names {
                display: flex;
                justify-content: space-around;
                & div {
                    font-weight: 700;
                    font-size: 16px;
                    @media screen and (max-width: 767px) {
                        font-size: 12px;
                    }
                }
            }
            &__week {
                display: flex;
                justify-content: space-around;
                margin-bottom: 16px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 4px;
                }
                & div {
                    font-weight: 400;
                    font-size: 16px;
                    @media screen and (max-width: 767px) {
                        font-size: 12px;
                    }
                } 
            }
        }
    }

}