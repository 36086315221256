@import './variables';

.d-flex {
  display: flex;
}

.justify-content {
  &-between {
    justify-content: space-between;
  }

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }
}

.align-items {
  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.btn {
  color: $white;
  line-height: 1.5;
  padding: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
  &-pink {
    background-color: $pink;
    &:hover {
      background-color: #db003c !important;
      color: white !important;
    }
  }

  &-white {
    &:hover {
      background-color: #ffcfdc !important;
      color: #db003c !important;
    }
  }

  &:hover {
    cursor: pointer;
    transition: all 0.3s;
  }
}

.dashboard-container {
  min-height: 800px;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.main-container {
  padding-left: 75px;
  padding-right: 75px;
}
.main-container-hosted-login {
  padding-left: 40px;
  padding-right: 40px;
  background-color: white;
  min-height: 60vh;
}

.container-mesx {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 646px) {
    max-width: 620px;
  }
  @media (min-width: 848px) {
    max-width: 800px;
  }
  @media (min-width: 1052px) {
    max-width: 1040px;
  }
  @media (min-width: 1260px) {
    max-width: 1220px;
  }
}

.card {
  background: $white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &__title {
    margin-bottom: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-2;
  }
}

.change_pass_form {
  padding: 28px 28px 28px 28px;
  width: 360px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

span.ant-radio + * {
  padding-left: 26px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $blue-4 !important;
  background: radial-gradient(
    53.57% 53.57% at 50% 50%,
    #ffffff 41.85%,
    #288aaf 59.38%,
    rgba(255, 255, 255, 0) 99.99%
  ) !important;

  &:after {
    background-color: $white;
  }
}

.ant-radio .ant-radio-inner {
  background: $white;
  border: 1px solid $gray-4;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.ant-radio:hover .ant-radio-inner {
  border-color: $blue-4 !important;
}

.ant-dropdown {
  z-index: 1020;
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
  }
}

.ant-dropdown-menu-item > span > a,
.ant-dropdown-menu-submenu-title > span > a {
  margin: 0;
  padding: 16px 104px 16px 22px;
  color: $black;
  font-weight: 500;
  display: block;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child,
.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  margin-top: 0 !important;
}

.ant-dropdown.ant-dropdown-placement-bottomLeft {
  top: 70.21px !important;
}

.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  margin-left: -27px;
  box-shadow: 0px 1px 3px $gray-7;
  border-radius: 0px 0px 4px 4px;
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  margin: 0 12px;
  background-color: $gray-2;
}

.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: $pink !important;
}

.dropdown-calendar {
  max-width: 396px;
  width: 100%;
}

.text-align {
  text-align: center;
}

.text-align-end {
  text-align: end;
}

.text-line {
  text-decoration: line-through;
}

.pt-20 {
  padding-top: 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pos-relative {
  position: relative;
}

.navbar-mob,
.banner-mob {
  display: none;
}

.margin {
  &-left {
    margin-left: auto;
  }
}

.sidebar {
  max-width: 330px;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .mob-white {
    background-color: white;
  }
  .main-container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .main-container-hosted-login {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 991px) {
  .sidebar {
    max-width: 100%;
  }

  .check-icon {
    width: 10px;
    height: 10px;
  }

  .banner-desc {
    display: none;
  }

  .navbar-desc {
    display: none;
  }

  .navbar-mob,
  .banner-mob {
    display: block;
  }

  .main-row {
    flex-direction: column-reverse;
  }

  span.ant-radio + * {
    padding-left: 22px;
  }

  .list,
  .file {
    width: 18px;
    height: 18px;
  }

  .ant-radio-group.ant-radio-group-outline {
    padding-left: 16px;
  }
}

.def {
  position: absolute;
  height: 10px;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.menu-shadow {
  box-shadow: 1px 0px 3px #eaeaea;
}

.future-ready-section {
  margin: 0 20px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.future-ready-bg-black {
  background-image: url('/images/futureReady/bg-black.png');
  background-size: cover;
  width: 100vw;
  margin-left: -75px;
  @media screen and (max-width: 1200px) {
    margin-left: -16px;
  }
}
.future-ready-banner {
  padding-top: 60px;
  padding-bottom: 60px;
  @media screen and (max-width: 1200px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  @media screen and (max-width: 600px) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.future-ready-banner > img {
  width: 800px;
  @media screen and (max-width: 1200px) {
    width: 500px;
  }
  @media screen and (max-width: 600px) {
    width: 65vw;
  }
}

.future-ready-banner > p {
  color: white;
  font-size: 20px;
  margin-top: 12px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}
.future-ready-banner > div {
  margin-top: 36px;
  @media screen and (max-width: 600px) {
    margin-top: 12px;
  }
}

.future-ready-banner > div > p {
  color: white;
  font-size: 14px;
  margin: 0 6px;
  text-align: center;
}

.future-ready-banner > div > p > img {
  height: 24px;
  margin: 0 6px;
  @media screen and (max-width: 600px) {
    height: 12px;
    margin: 6px 6px;
  }
}

.future-ready-booking > div > div > h3 {
  color: #171b22;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Montserrat';
  text-align: center;
}

.future-ready-booking > div > div > h4 {
  color: #171b22;
  font-size: 24px;
  margin-top: 24px;
  font-weight: 500;
  font-family: 'Montserrat';
  text-align: center;
}

.future-ready-booking > div > div > section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.section-border {
  border-left: 1px solid rgb(207, 207, 207);
  @media screen and (max-width: 755px) {
    border: 0;
  }
}

.future-ready-booking > div > div > section > p {
  text-align: justify;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  padding: 0 12px;
}

.future-ready-booking > div > div > section > h4 {
  text-align: center;
  color: #171b22;
  font-size: 32px;
  font-weight: 500;
  font-family: 'Montserrat';
  margin-top: 24px;
}

.future-ready-booking > div > div > div > div > div {
  margin-top: 20px;
  display: flex;
  background-color: #324192;
  padding: 12px 36px;
  border-radius: 24px;
  color: white;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.future-ready-booking > div > div > div > div > h4 {
  margin-top: 20px;
  display: flex;
  padding: 12px 36px;
  border-radius: 24px;
  border: 1px solid #19304f;
  background-color: #19304f;
  color: white;
  font-family: 'Montserrat';
  font-size: 14px;
  text-align: center;
  @media screen and (max-width: 1200px) {
    padding: 12px 18px;
  }
}

.future-ready-booking > div > div > div > p {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgb(211, 211, 211);
  line-height: 0.1em;
  margin: 36px 0 20px;
  font-family: 'Montserrat';
  font-weight: 500;
  width: 80%;
}

.future-ready-booking > div > div > div > p span {
  background: rgb(243, 243, 243);
  padding: 0 10px;
}

.future-ready-timeline {
  padding-top: 60px;
  padding-bottom: 0px;
  @media screen and (max-width: 766px) {
    padding-bottom: 0px;
  }
}

.future-ready-timeline > h4 {
  color: white;
  font-size: 28px;
  font-weight: 700;
  font-family: 'Montserrat';
  letter-spacing: 6px;
  text-align: center;
}

.future-ready-timeline > div {
  width: 70%;
  @media screen and (max-width: 1200px) {
    width: 90%;
  }
}

.future-ready-timeline > div > div {
  margin-bottom: 36px;
  @media screen and (max-width: 766px) {
    margin-bottom: 60px;
  }
}

.future-ready-timeline > div > h3 {
  color: white;
  font-size: 18px;
  margin-top: 24px;
  font-family: 'Montserrat';
  letter-spacing: 6px;
  padding-bottom: 8px;
  border-bottom: 1px solid white;
  margin-bottom: 20px;
}

.future-ready-timeline > div > div > div > div > p {
  color: white;
}

.future-ready-timeline > div > div > div > div > h3 {
  color: white;
  font-size: 28px;
  font-weight: 700;
}

.future-ready-timeline > div > div > div > div > h4 {
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  & > img {
    height: 14px;
    margin-left: 8px;
  }
}

.future-ready-timeline > div > div > div > div > div > h2 {
  color: white;
  font-size: 14px;
  background-color: rgb(206, 54, 54);
  padding: 2px 12px;
  border: 1px solid white;
  border-radius: 10px;
  margin-bottom: 0;
}

.future-ready-timeline > div > div > div > div > div {
  @media screen and (max-width: 970px) {
    margin-bottom: 12px;
  }
}

.future-ready-timeline > div > div > div > div > div > p {
  color: white;
  font-size: 12px;
  margin-left: 8px;
  & > a {
    color: white !important;
    text-decoration: underline;
  }
}

.future-ready-timeline > div > div > div > div > p {
  color: white;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 12px;
}

.future-ready-timeline > div > div > div > div > img {
  height: 220px;
  margin-left: 12px;
  border-radius: 12px;
  @media screen and (max-width: 1100px) {
    height: 160px;
  }
  @media screen and (max-width: 750px) {
    height: 220px;
  }
  @media screen and (max-width: 360px) {
    height: 160px;
  }
}

.foto-pembicara {
  @media screen and (max-width: 750px) {
    justify-content: center;
  }
}

.future-ready-ticket > h3 {
  color: #171b22;
  font-size: 36px;
  font-weight: 700;
  font-family: 'Montserrat';
  text-align: center;
  margin-top: 48px;
}

.future-ready-ticket > h4 {
  color: #171b22;
  font-size: 24px;
  font-weight: 500;
  font-family: 'Montserrat';
  text-align: center;
  margin-top: 46px;
}

.future-ready-ticket > p {
  margin-top: 46px;
}

.future-ready-ticket {
  padding-bottom: 0px;
}

.future-ready-ticket > h5 {
  color: #171b22;
  background-color: #ffca43;
  border: 1px solid #dba211;
  padding: 16px 28px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Montserrat';
  text-align: center;
  margin-top: 12px;
  border-radius: 24px;
}

.future-ready-ticket > div {
  margin-top: 28px;
  background-color: #19304f;
  border: 2px solid black;
  border-radius: 12px;
  padding: 24px;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  & > div {
    width: 80px;
    margin: 0 8px 0 12px;
  }

  & > p {
    margin-right: 24px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    @media screen and (max-width: 970px) {
      margin-right: 16px;
      font-size: 14px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 16px;
      margin-right: 0;
    }
  }
}

.harga-tiket {
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: 24px;
  color: white;

  @media screen and (max-width: 755px) {
    width: 70%;
  }

  & > div > div {
    background: none !important;
    padding: 12px 36px 12px 0px !important;
  }

  & > div {
    justify-content: space-between;
  }

  & > div > div > img {
    width: 80px;
    height: 41.73px;
    margin: 0 8px 0 12px;
  }

  & > div > p {
    margin-right: 24px;
    color: black;
    font-size: 18px;
    font-weight: 500;
    @media screen and (max-width: 970px) {
      margin-right: 16px;
      font-size: 14px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 16px;
      margin-right: 0;
    }
  }
}

.harga-tiket-title {
  text-align: center !important;
  color: #171b22 !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  font-family: 'Montserrat' !important;
  margin-top: 24px !important;
}

.future-ready-section > div > div {
  @media screen and (max-width: 755px) {
    margin-bottom: 36px;
  }
}

.sticky-buy-btn {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  z-index: 900;
  cursor: pointer;
  & > div {
    background-color: #19304f;
    color: white;
    margin-top: 16px;
    padding: 12px 36px;
    border-radius: 36px;
    font-family: 'Montserrat';
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    @media screen and (max-width: 755px) {
      padding: 8px 24px;
      margin-top: 8px;
      font-size: 14px;
    }
  }
}

.future-cocok {
  padding-top: 36px;

  &__margin {
    margin-left: 4.33333%;
    @media screen and (max-width: 755px) {
      margin-left: 0;
    }
  }
  & > h4 {
    color: #171b22;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Montserrat';
    text-align: center;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  & > div {
    & > div {
      & > ul {
        list-style-type: unset !important;
        & > li {
          margin-left: 4.33333%;
          text-align: justify;
          margin-bottom: 8px;
          & > span {
            position: relative;
            left: 20px;
            @media screen and (max-width: 755px) {
              left: 0;
            }
          }
        }
      }
    }
  }
}

.future-ready-modal {
  display: flex;
  flex-direction: column;
  padding: 0 36px;

  & > h3 {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Montserrat';
    text-align: center;
  }
  & > h4 {
    font-weight: 700;
    margin-bottom: 16px;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
  }
  & > div {
    // border-bottom: 1px solid rgb(197, 196, 196);
    & > h4 {
      font-weight: 700;
      margin-bottom: 16px;
    }

    & > div {
      & > div {
        margin-bottom: 12px;
        & > h5 {
          font-weight: 500;
          font-family: 'Montserrat';
          font-size: 14px;
          margin-bottom: 0;
        }
        & > p {
          font-weight: 300;
          font-family: 'Montserrat';
          font-size: 12px;
        }
      }
    }
    padding: 20px 0 12px 0;
  }

  &__btn {
    cursor: pointer;
    padding-bottom: 24px !important;
    padding-top: 0 !important;
    display: flex;
    justify-content: flex-end;
    & > div {
      background-color: #fcec4f;
      padding: 4px 16px;
      border-radius: 20px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

.future-harga-tiket {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h4 {
    text-align: center;
    color: #171b22;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Montserrat';
    margin-top: 24px;
  }

  & > div {
    width: 80%;
  }

  & > div > div {
    display: flex;
    background: none !important;
    color: black !important;
    margin-top: 0 !important;
    text-align: left !important;
    padding: 0 !important;
    & > div {
      margin-bottom: 28px;
      height: 100%;
      width: 100%;
    }
  }

  & > div > div > div {
    border-radius: 12px;
    & > h3 {
      color: #171b22;
      margin-top: 12px;
      font-size: 18px;
      font-weight: 700;
      font-family: 'Montserrat';
      text-align: center;
      margin-bottom: 16px;
    }

    & > ul {
      list-style-type: unset !important;
      margin: 24px 0;
      width: 100%;

      & > li {
        margin: 0 4.33333%;
        font-size: 20px;
        & > span {
          position: relative;
          left: 8px;
          @media screen and (max-width: 755px) {
            left: 0;
          }
        }
      }
    }
  }

  & > div > div > div > div {
    display: flex;
    justify-content: center;
  }

  & > div > div > div > div > img {
    width: 120px;
    height: 62.6px;
    @media screen and (max-width: 755px) {
      width: 80px;
      height: 41.73px;
    }
  }
}

.disqus-row {
  @media screen and (max-width: 991px) {
    flex-direction: column !important;
  }
}

.cert-verification {
  & p {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 18px;
  }

  & h3 {
    font-family: 'Montserrat';
    font-size: 20px;
  }

  & h2 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 12px;
    }
  }

  .form-group {
    margin-top: 12px;
  }

  & button {
    margin-top: 12px;
    background-color: #ff0f51;
  }
}
.cert-verification-wrap {
  background-color: white;
  max-width: 1140px !important;
  padding: 72px 0 80px 0;
  box-shadow: 0px 1px 3px #eaeaea;
  & > h1 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    margin-bottom: 0;
  }
}

.cert-search-result {
  padding: 24px 48px;
  & > h2 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
    align-items: center;
    text-align: center;
  }

  & > p {
    font-family: 'Montserrat';
    font-weight: 700;
    text-align: center;
  }
  & button {
    background-color: #ff0f51;
    margin-right: 8px;
  }

  & > div {
    display: flex;
    justify-content: center;
  }

  & td {
    padding: 2px 32px 2px 0;
  }

  & th {
    padding-bottom: 12px;
  }
}

.course-detail-new-container {
  margin-top: 32px;
  max-width: 1200px;
  @media screen and (max-width: 1199px) {
    width: 83.33333%;
  }
  @media screen and (max-width: 991px) {
    width: unset;
    margin-top: 0;
  }
}

.course-detail-new {
  & > div > .row {
    & > div:nth-child(1) {
      position: relative;
      width: 72.25%;
      padding-right: 2.5%;
      max-width: 72.25%;
    }
    & > div:nth-child(2) {
      position: relative;
      width: 27.416666666%;
      max-width: 27.416666666%;
      padding-left: 2.833333%;
    }
  }

  @media screen and (max-width: 991px) {
    & > div > .row {
      display: flex;
      flex-wrap: wrap;
      & > div:nth-child(1) {
        width: 100%;
        padding-right: 0;
        max-width: 100%;
      }
      & > div:nth-child(2) {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
      }
    }
  }

  & .row-black {
    background: #20282e;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    & .row {
      & > div:nth-child(1) {
        position: relative;
        width: 100%;
        padding-right: 0;
        max-width: unset;
      }
    }
    & > div {
      width: unset;
    }
  }

  & .row-blue {
    & > .course-detail-new-container {
      margin-top: 0;
    }
    & .row {
      & > div:nth-child(1) {
        position: relative;
        width: 100%;

        padding-right: 0;
        max-width: unset;
      }
    }
    background: #e0f3fc;
    margin-bottom: -12px;
  }
}

.student-dashboard {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
  }
  & a:hover {
    color: unset !important;
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.route404 {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
  }
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1 {
    font-size: 60px;
  }
}

.container-mesx {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  }
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 646px) {
    max-width: 620px;
  }
  @media (min-width: 848px) {
    max-width: 800px;
  }
  @media (min-width: 1052px) {
    max-width: 1040px;
  }
  @media (min-width: 1260px) {
    max-width: 1220px;
  }
}

.terms-privacy-page {
  padding: 60px 0;
  & > h1 {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 24px;
  }
  & > div {
    & > h1 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 2px;
    }
    & > p {
      font-size: 14px;
      opacity: 0.9;
    }
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(226, 226, 226);
  }
}

.lesson__zoom {
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &__name {
    font-weight: bold;
    font-size: 1.5rem;
  }
  &__description {
  }
  &__date {
  }
  &__link {
    width: fit-content;
  }
}

.py-10 {
  padding: 170px 0 40px 0;

  @media screen and (max-width: 991px) {
    padding: 16px
  }
}
