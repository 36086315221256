.edit-profile {
    &__picture {
        width: 200px;
        height: 200px;
        border-radius: 200px;
        @media screen and (max-width: 991px) {
            width: 106px;
            height: 106px;
            border-radius: 58px;
        }
    }

    &__pass-desc {
        font-size: 12px;
        line-height: 20px;
    }

    &__container {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        border-radius: 8px; 
        width: 274px;
        padding: 20px
    }

    &__btn-pink {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 14px;
        &:hover {
            background-color: #DB003C;
            color: white;
        }
    }

    &__btn-white {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 14px;
        &:hover {
            background-color: #FFCFDC;
            color: #FF0F51;
        }

        &-disabled {
            cursor: unset !important;
            color: gray;
            border-color: gray;
            background-color: rgba(128, 128, 128, 0.151);
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
            &:hover {
                background-color: rgba(128, 128, 128, 0.151) !important;
                color: gray !important;
            }
        }
    }

    &__form-label {
        font-family: 'Montserrat';
        font-weight: 300;
        font-size: 14px;
        color: black;
    }

    &__form {
        font-family: 'Montserrat';
        border: 1px solid #dddddd;
        border-radius: 4px;
        color: black !important;
        margin-bottom: 20px;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        padding: 8px;
        font-weight: 300;
        font-size: 14px;
        line-height: 32px;
        height: unset;
    }
}

.modal-size-edit {
    width: 540px;
    max-width: 100vw;
}