@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-display: swap;
    src : url("./Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-display: swap;
    src : url("./Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-display: swap;
    src : url("./Montserrat-Medium.ttf");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-display: swap;
    src : url("./Montserrat-Bold.ttf");
}

@font-face {
    font-family: "Poppins";
    font-weight: 300;
    font-display: swap;
    src : url("./Poppins-Light.ttf");
}

@font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-display: swap;
    src : url("./Poppins-Regular.ttf");
}

@font-face {
    font-family: "Lato";
    font-weight: 400;
    font-display: swap;
    src : url("./Lato-Regular.ttf");
}

@font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-display: swap;
    src : url("./Poppins-Medium.ttf");
}

@font-face {
    font-family: "Poppins";
    font-weight: 600;
    font-display: swap;
    src : url("./Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins";
    font-weight: 700;
    font-display: swap;
    src : url("./Poppins-Bold.ttf");
}

@font-face {
    font-family: "Lato";
    font-weight: 700;
    font-display: swap;
    src : url("./Lato-Bold.ttf");
}

@font-face {
    font-family: "Poppins";
    font-weight: 300;
    font-display: swap;
    src : url("./Poppins-LightItalic.ttf");
    font-style: italic;
}