.menu-user {
    padding: 16px 30px;
    background-color: white;
    @media screen and (max-width: 991px) {
        padding: 8px 20px;
    } 
    &__title {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        @media screen and (max-width: 991px) {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &__link {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 14px;
        padding-top: 8px;
    }

    &__link_selected {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 14px;
        padding-top: 8px;
        color: #FF0F51;
    }

    &__small-text {
        font-family: 'Montserrat';
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
    }

    &__container {
        @media screen and (max-width: 991px) {
            border-bottom: 1px solid #F2F2F2;
        } 
    }

}

.content-user-container {
    padding: 16px 30px;
    background: white;
    @media screen and (max-width: 991px) {
        background: none;
        padding: 16px 8px;
    } 
    h1, h2, h3, p {
        font-family: 'Montserrat';
    }
}

.content-user-container-profile {
    padding: 16px 30px;
    @media screen and (max-width: 991px) {
        padding: 0;
        margin-top: 44px;
        border-top-right-radius: 26px;
        border-top-left-radius: 26px;
    } 
    h1, h2, h3, p {
        font-family: 'Montserrat';
    }

}

.content-user {
    &__title {
        font-weight: 700;
        font-size: 24px;
        @media screen and (max-width: 991px) {
            font-size: 16px;
        } 
    }
}

.mt-10px-mob {
    @media screen and (max-width: 991px) {
        margin-top: 10px
    }
}