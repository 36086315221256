@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import '../../../node_modules/bootstrap/scss/grid';

@import './variables';

*,
*::before,
*:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: $body-bg;
}

.list-reset {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    display: inline-block;
    color: $black;
    &:hover,
    &:focus {
        cursor: pointer;
        text-decoration: none;
        color: $blue-4 !important;
        transition: all 0.3s;
    }
}