.all-course {
    & p, h1, h2, h3, h4, a, span, button, div { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    margin-top: 60px;
    margin-right: 35px;
    margin-left: 35px;
    padding: 0 12px;
    @media screen and (max-width: 767px) {
        margin-right: 0;
        margin-left: 0;
        padding: 0;
    }

    &__margin {
        margin: 20px 35px;
        @media screen and (max-width: 767px) {
            margin: 0;
        }
    }

    &__text {
        & p, h1, h2, h3, h4, a, span, button, div { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    }

    &__search {
        width: 274px;
        background-color: white;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    &__btn-sort {
        border: 0;
        font-weight: 500;
        padding-bottom: 4px;
        background-color: white;
        text-align: right;
        cursor: pointer;
    }

    &__class-list { 
        margin-right: -12px;
        margin-left: -12px;
        @media screen and (max-width: 767px) {
            margin-top: 36px;
        } 
        &-wrapper {
          padding-right: 12px;
          padding-left: 12px;  
          margin-bottom: 24px;
        }
    }

    &__filter-mob {
        & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
        cursor: pointer;
        &-active {
            background-color: #76CAEA;
        }
        &-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            @media screen and (max-width: 767px) {
                font-size: 12px;
            }

            &-active {
                cursor: pointer;
                color: white;
                @media screen and (max-width: 767px) {
                    font-size: 12px;
                }
            }
        }
    }

}

.all-course-new {
    & p, h1, h2, h3, h4, a, span, button, div { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 60px;
    & > div {
        width: 83.33333%;
        margin-bottom: 40px;
        max-width: 1200px;
        @media screen and (max-width: 991px) {
            width: 100%;
            padding: 0 16px;
        }

        & > h1 {
            font-weight: 500;
            font-size: 24px;
        }

        
        & > div:nth-child(5) {
            margin-top: 32px;
        }
    }

    &__wrapper {
        display: flex;
        &__inside {
            width: 77%;
            display: flex;
            flex-wrap: wrap;
            margin-right: -12px;
            margin-left: auto;
            @media screen and (max-width: 991px) {
                flex-grow: 1;
                margin-left: -12px;
                margin-right: -12px;
                min-height: 480px;
            }
        }
    }

    & .all-course-not-found {
        background: url('/images/background/blank-bg-all-course.png') center no-repeat;
    }

}

.filter-top-new {
    & p, h1, h2, h3, h4, a, span, button, div { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    display: flex;
    @media screen and (max-width: 991px) {
        flex-direction: column;
    }
    margin: 12px 0 20px 0;
    & > div:nth-child(1) {
        @media screen and (max-width: 991px) {
            display: none;
        }
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 2px 8px;
        & > p {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
        }
        & > svg {
            margin-left: 8px;
        }
    }

    & > div:nth-child(2) {
        @media screen and (max-width: 991px) {
            margin-left: 0;
            margin-bottom: 16px;
            display: flex;
        }
        display: flex;
        align-items: center;
        margin-left: 16px;
        background: #FFFFFF;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        & .input-group-text {
            background-color: white;
            border: none;
            font-size: 14px;
            padding-right: 0;

        }
        & .custom-select {
            background-color: white;
            background: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41 0.579956L6 5.16996L10.59 0.579956L12 1.99996L6 7.99996L0 1.99996L1.41 0.579956Z' fill='%23004F8A'/%3E%3C/svg%3E%0A") 0 0 no-repeat;
            background-position: 96% 50%;
            border: none;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            & > option {
                font-size: 14px;
                font-weight: 500;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }

    & > div:nth-child(3) {
        @media screen and (max-width: 991px) {
            display: none;
        }
        display: flex;
        align-items: center;
        margin-left: 16px;
        & > h2 {
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            color: #FF0F51;
            margin-bottom: 0;
            &:hover {
                color: #bd0536;
            }
        }
    }

    &__input {
        display: flex;
        align-items: center;
        margin-left: auto;
        background: #FFFFFF;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 2px 8px;
        
        & .form-control {
            border: none;
            margin-right: 8px;
            padding: 0;
            width: 188px;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.filter-new {
    @supports(-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'] {
      --active: #76CAEA;
      --active-inner: #fff;
      --focus: 2px rgba(39, 94, 254, .3);
      --border: #BBC1E1;
      --border-hover: #76CAEA;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 16px;
      outline: none;
      display: inline-block;
      @media screen and (max-width: 991px) {
        display: none;
      }
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: .9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
        & + label {
          cursor: not-allowed;
        }
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:not(.switch) {
        width: 16px;
        &:after {
          opacity: var(--o, 0);
        }
        &:checked {
          --o: 1;
        }
      }
      & + label {
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-bottom: 0;
        margin-left: 16px;
        @media screen and (max-width: 991px) {
            margin-left: 0;
            display: inline-block;
            padding: 4px 8px;
            background: #fff;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            white-space: nowrap;
            font-size: 14px;
            margin-right: 8px;
        }
      }
    }
    input[type='checkbox'] {
      &:not(.switch) {
        border-radius: 4px;
        &:after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 5px;
          top: 2px;
          transform: rotate(var(--r, 20deg));
        }
        &:checked {
          --r: 43deg;
        }
      }
      &.switch {
        width: 38px;
        border-radius: 11px;
        &:after {
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          background: var(--ab, var(--border));
          transform: translateX(var(--x, 0));
        }
        &:checked {
          --ab: var(--active-inner);
          --x: 17px;
        }
        &:disabled {
          &:not(:checked) {
            &:after {
              opacity: .6;
            }
          }
        }
      }
    }
    }
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    display: block;
    @media screen and (max-width: 991px) {
        display: block;
        min-width: unset;
        width: unset;
    }
    width: 23%;
    min-width: 220px;
    & > div {
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;
        @media screen and (max-width: 991px) {
            background: none;
            box-shadow: none;
            border-radius: 10px;
            padding: 0;
            margin-bottom: 16px;
        }
        & > h2 {
            font-weight: 700;
            font-size: 16px; 
            margin-bottom: 16px;
            margin-top: 36px;
            @media screen and (max-width: 991px) {
                display: none;
            }
        }
        & > img {
            @media screen and (max-width: 991px) {
                display: none;
            }
        }
        & > h2:nth-child(1) {
            margin-top: 0;
        }
        & > ul {
            @media screen and (max-width: 991px) {
                display: flex;
                max-width: 100%;
                overflow-x: scroll;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            & > li {
                margin-bottom: 4px;
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                & > input {
                    display: none;
                }
                & > label {
                    font-weight: normal;
                    font-size: 16px;
                }
                & .filter-label-selected {
                    @media screen and (max-width: 991px) {
                        background-color: #76caea;
                        color: white;
                    }
                }
            }
        }
    }
    
}

.filter-all-course {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
}

.pagi-li {
    // border: 1px solid #DDDDDD;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
        border-right: 1px solid #DDDDDD;
    }
}

.pagination {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
}




.pagination .pageclass a{
    // border: 1px solid #DDDDDD;
    font-weight: 600;
    font-size: 14px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF0F4F;
    &:not(:last-child) {
        border-right: 1px solid #DDDDDD;
    }
    &:hover {
        color: #FF0F4F !important;
    }
}

.pageactive {
    background-color: #FF0F51;
    &:hover {
        & a {
            color: white !important;
            
        }
        background-color: #f798b1;
    }
    & a {
        color: white !important;
        
    }
}

.awal-akhir-btn {
    border: 1px solid #DDDDDD;
    width: 55px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #FF0F51;;
    cursor: pointer;
    background-color: white;
    @media screen and (max-width: 767px) {
        font-size: 12px;
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.pagination {
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    background-color: white;
    
}


.filter-top-flex {
    display: flex;
    @media screen and (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.ml-auto-mobile {
    margin-left: auto;
    margin-top: 20px;
    @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-top: 40px;
    }
}

.filter-mob-container {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.filter-mob-container::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}