.checkout-page {
    padding: 20px;
    min-height: 60vh;
    @media screen and (max-width: 991px) {
        padding: 0;
    }
    background: none;
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }

    &__main-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;

        @media screen and (max-width: 991px) {
            font-size: 16px;
            line-height: 22px;
            border: 0;
        }
    }

    &__top-text{
        border-bottom: 1px solid #DDDDDD;
    }

    &__card {
        padding: 20px 24px;
        @media screen and (max-width: 991px) {
            width: 100vw;
            margin-left: -16px;
            margin-top: 12px;
            background: none;
            box-shadow: none;
        }
    }

    &__sub-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        @media screen and (max-width: 991px) {
            font-size: 16px;
        }
    }

    &__main-content {
        display: flex;
        padding-top: 21px;
        @media screen and (max-width: 991px) {
            & > div:nth-child(1) {
                padding-right: 0;
                flex-direction: column;
            }
        }
    }

    &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin-top: 18px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 18px;
            margin-top: 0;
        }
    }

    &__pic {
        // width: 180px;
        height: 180px;
        border-radius: 8px;
        @media screen and (max-width: 991px) {
            height: auto;
            width: auto;
            max-height: 100%;
            max-width: 100%;
        }
    }

    &__voucher-content {
        padding: 0 25px;
        border-left: 1px solid #DDDDDD;
        @media screen and (max-width: 991px) {
            border-left: 0;
            margin-top: 40px;
            padding: 0 15px;
        }
    }

    &__masukan-kode {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 991px) {
            font-size: 14px;
        }
    }

    &__voucher-input {
        cursor: pointer;
        display: flex;
        padding: 12px 14px;
        border: 1px solid #FF0F51;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 28px;
        @media screen and (max-width: 991px) {
        }
    }

    &__total {
        &-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            @media screen and (max-width: 991px) {
                font-size: 16px;
            }
        }

        &-detail {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
            @media screen and (max-width: 991px) {
                font-size: 12px;

            }

            &-big {
                font-size: 18px;
                @media screen and (max-width: 991px) {
                    font-size: 16px;
                }
            }
        }

        &-bottom {
            padding-top: 16px;
            border-top: 1px solid #DDDDDD;
            margin-bottom: 26px;
            @media screen and (max-width: 991px) {
            }
        }

    }

    &__price {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 991px) {
            font-size: 12px;

        }

        &-bold {
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            @media screen and (max-width: 991px) {
                font-size: 16px;

            }
        }

        &-btn {
            padding: 12px;
            @media screen and (max-width: 991px) {
                padding: 10px;
            }
        }
    }

    &__prakerja {
        border-left:1px solid #DDDDDD;
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    &__sertif {
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    &__pemilihan-jadwal {
        padding: 6px 6px 6px 11px;
        background: rgba(164, 230, 255, 0.19);
        border-left: 4px solid #A4E6FF;
        @media screen and (max-width: 991px) {
            font-size: 12px;
            margin-bottom: 12px;
        }
    }
}

.discount-voucher-modal {
    background-color: rgba(0, 0, 0, .9);
    bottom: 0;
    content: "";
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    &__btn {
        font-family: 'Montserrat';
        font-weight: 500;
        padding: 8px 30px;
        margin-left: auto;
        @media screen and (max-width: 670px){
            margin-left: 0;
            margin-top: 20px;
        }
    }

    &__oke-btn {
        font-family: 'Montserrat';
        font-weight: 500;
        margin-top: 20px;
        padding: 8px 30px;
        width: 90%;
        margin-bottom: 32px;
    }

    &__size {
        width: 512px;
        max-width: 100vw;
        @media screen and (max-width: 670px){
            width: 92vw;
        }
    }

    &__dialog {
        transform: none;
        margin: 1.75rem auto;
    }

    &__input {
        margin-right: 20px;
        margin-left: 37px;
        padding: 6px 7px 7px 8px;
        border: 1px solid $gray-2;
        border-radius: 4px;
        background-color: $white;
        width: 80%;
        @media screen and (max-width: 670px){
            width: 100%;
            padding: 12px 16px;
        }

        &::placeholder {
            color: $gray-1;
            font-size: 14px;
            font-family: 'Montserrat';
        }

        &:focus {
            outline: none;
        }

        &-container {
            display: flex;
            padding: 12px 24px;
            margin-bottom: 32px;
            margin-top: 12px;
            @media screen and (max-width: 670px){
                flex-direction: column;
            }
        }
    }

    &__title {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 20px;
        line-height: 38px;
        margin-left: 24px;
        text-align: start;
        @media screen and (max-width: 991px){
            font-size: 16px;
            line-height: 38px;
        }
    }

    &__berhasil {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 20px;
        line-height: 38px;
        text-align: center;
        @media screen and (max-width: 991px){
            font-size: 16px;
            line-height: 38px;
        }

       
    }

    &__berhasil-img {
        @media screen and (max-width: 670px){
            width: 40%;
            height: 40%;
        }
    }
}

.order-detail-bg {
    min-height: 65vh;
    background-color: white;
    @media screen and (max-width: 991px){
        background: none;
    }
}

.order-detail-page {
    padding: 36px;
    background-color: white;
    @media screen and (max-width: 991px){
        background: none;
        padding: 16px;
    }
    & h1 {font-family: 'Montserrat';}
    & h2 {font-family: 'Montserrat';}
    & p {font-family: 'Montserrat';}
    & h3 {font-family: 'Montserrat';}

    &__title {
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        @media screen and (max-width: 991px){
            font-size: 20px;
        }
    }


    &__text {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        @media screen and (max-width: 991px){
            font-size: 12px;
        }
    }

    &__btns {
        margin-top: 20px;
        margin-bottom: 24px;
        display: flex;
        @media screen and (max-width: 991px){
            display: block;
        }
    }

    &__lihat {
        &-pesanan {
            padding: 12px 72px;
        }
        &-kembali {
            padding: 12px 24px;
            margin-left: 12px;
            border: 0;
            box-shadow: none;
            @media screen and (max-width: 991px){
                background: none;
                margin-left: 0;
                padding: 12px  0;
            }
        }
    }

    &__jadwal {
        margin-top: 48px;
        background: #EEFAFF;
        border-radius: 4px;
        padding: 16px;
        @media screen and (max-width: 991px){
            margin-top: 28px;
        }

        &-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 38px;
            margin: 0;
            @media screen and (max-width: 991px){
                font-size: 20px;
            }
        }

        &-btn {
            padding: 12px 36px;
            margin-top: 24px;
            margin-bottom: 12px;
            @media screen and (max-width: 991px){
                padding: 6px 36px;
            }
        }
    }

    &__detail {
        &-card {
            padding: 16px 24px;

        }
        &-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;            
            border-bottom: 1px solid #DDDDDD;
            
        }

        &-name {
            margin-top: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            @media screen and (max-width: 991px){
                font-size: 14px;
                line-height: 20px;
            }
        }

        &-pic {
            width: 120px;
            height: 120px;
            background-position: left;
            background-size: cover;
        }
    }

    &__number {
        &-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            @media screen and (max-width: 991px){
                font-size: 16px;
                line-height: 26px;
            }
        }

        &-price {
            margin-left: auto;
            @media screen and (max-width: 991px){
                font-size: 12px;
                line-height: 20px;
            }
        }

        &-detail {
            font-weight: 500;
            @media screen and (max-width: 991px){
                font-size: 12px;
                line-height: 20px;
            }
        }

        &-total {
            border-top: 1px solid #DDDDDD;

            &-detail {
                font-size: 18px;
                font-weight: 700;
                @media screen and (max-width: 991px){
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            &-price {
                font-size: 18px;
                margin-left: auto;
                font-weight: 700;
                @media screen and (max-width: 991px){
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
}



