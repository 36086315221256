.logo {
    width: 210px;
    margin-bottom: 40px;
}

.silahkan {
    color: black;
    font-size: 16px;
}

.modal-size {
    width: 380px;
    max-width: 100vw;
    max-height: 100vh;
    
}

.login-label {
    color: black;
    font-size: 10px;
}

.forgot-pass {
    font-size: 14px;
    color: #004F8A;
}

.masuk-btn {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
    background-color: #FF0F51;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        width: 20px;
        height: 20px;
        display: flex;
        margin-right: 12px;
    }
    & > p {
        margin-bottom: 0;
    }
}

.login_form .form-control {
    color: black;
}

.login_form {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    padding: 0 24px 24px 24px;
    @media screen and (max-width: 991px) {
        padding: 0 24px;
    }
    & > div:nth-child(1) {
        & > h3 {
            text-align: center;
            align-self: flex-start;
            margin-bottom: 16px;
        }
        & > p {
            text-align: left;
            align-self: flex-start;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 12px;
        }
    }
    
    &__form {
        padding: 4px 8px;
        height: 40px !important;
    }

    &__header {
        & h3 {
            font-size: 20px !important;
            font-weight: 700 !important;
            width: 100%;
        }
        & > p {
            font-size: 14px;
        }
    }

    &__bottom-text {
        border-top: 1px solid #DDDDDD;
        padding-top: 16px;

        & > div {
            display: flex;
        }

        & h3 {
            font-weight: 400;
            font-size: 14px;
        }
    }
    &__bottom-text-2 {
        display: flex;
        justify-content: center;
        margin: 36px 0 0 0;

        & h3 {
            font-size: 16px;
        }
    }

    &__atau {
        width: 100%; 
        display: flex;
        & p {
            width: 100%; 
            font-size: 12px;
            text-align: center; 
            border-bottom: 1px solid #dfdfdf; 
            line-height: 0.1em;
            margin: 10px 0 20px; 
         } 
         
        & p span { 
            background:#fff; 
            padding:0 10px; 
         }
    }
    & button {
        @media screen and (max-width: 991px) {
            margin-bottom: 8px !important;
        }
    }

    &__googlefbbtn {
        margin-top: 12px;
        & > div {
            display: flex;
            margin-top: 12px;
            margin-bottom: 12px;
            justify-content: center;
            & > button {
                background: white;
                border: 0.25px solid rgba(102, 102, 102, 0.3);
                box-sizing: border-box;
                border-radius: 4px;
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 100px;
                width: 50px;
                height: 50px;
                margin-right: 12px;
                margin-left: 12px;

                & > img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
        & > p {
            font-weight: normal;
            font-size: 16px;
        }
    }

    & > form > .form-group {
        & > p {
            font-size: 10px;
            color: #FF0F51;
            font-weight: 500;
        }
        & .sign_up_form__form-error {
            margin-bottom: 4px;
            border: 1px solid #FF0F51;
        }
    }
}

.sign_up_form {
    padding: 0 28px 28px 28px;

    &__title {
        font-size: 25px;
        font-weight: 500;
    }

    &__form {
        padding: 8px
    }
}

.sign_up_modal:before {
    background-color: rgba(0, 0, 0, .6);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

.modal-dialog {
    @media (min-width: 260px) {
        max-width: 500px;
        margin: 0 auto 0 auto;
    }
}

.signin-logos{
    width: 36px;
}

.logos-border {
    border-bottom: 1px solid #DDDDDD;
}

.membuat-akun {
    font-size: 16px;
    color: #004F8A;
    font-weight: 700;
    font-family: 'Montserrat';
}

.close {
    border-radius: 50%;
    border: none;
    height: 50px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 50px;
}

.overflow-scroll::-webkit-scrollbar {
    width: 4px;
}
.overflow-scroll::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
.overflow-scroll::-webkit-scrollbar-thumb {
    background: #888; 
}
.overflow-scroll::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.login-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1090;
}

.login-popup-content {
    
}

.modal-content {
    max-height: 100vh;
    height: fit-content;
    @media screen and (max-height: 660px) {
        height: 100vh;
        overflow-y: scroll;
    } 
    @media screen and (max-width: 410px) {
        width: 100vw;
    }

    &::-webkit-scrollbar-track {
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #828282;
    }
}