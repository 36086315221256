.banner {
    background-image: url('../../static/banner_new.jpg');
    background-size: cover;
    background-position-x: 10% ;
    padding: 97px 10% 5% 72px;

    &__title {
        margin-bottom: 10px;
        text-align: center;
        font-weight: 500;
        font-family: 'Montserrat';
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 20px;
        color: white;
    }

    &__instructor {
        text-align: center;
        color: white;
        font-size: 24px;
    }

    &__main {
        margin-left: 20%;
        margin-right: 18vw;
    }
}

.InfoBanner {
    display: none;
    padding-top: 16px;

    &__info {
        border-bottom: 1px solid $gray-2;
        padding-bottom: 8px;
    }

    &__name {
        padding-left: 9px;
        font-family: 'Montserrat';
        font-weight: 500;
    }

    &__name-top {
        font-size: 24px;
        line-height: 32px;
        font-family: 'Montserrat';
        text-align: center;
    }
}

@media screen and (max-width: 1200px) {
    .banner {
        &__main {
            display: none;
        }
    }
}

@media screen and (max-width: 990px) {
    .banner {
        height: 151px;

        &__title {
            display: none;
        }

        &__instructor {
            display: none;
        }
    }

    .InfoBanner {
        display: block;
        &__title {
            line-height: 1.28;
            margin-bottom: 10px;
            font-weight: 500;
            font-family: 'Montserrat';
            font-size: 24px;
        }
    }

    
}

.banner-new {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    margin-top: 18px;
    & > div {
        display: flex;
        justify-content: flex-end;
        margin-bottom: -40px;
        margin-right: 16px;
        & > p {
            background: #222a30c4;
            opacity: 0.8;
            border-radius: 100px;
            color: #FFFFFF;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            padding: 0 10px;
            text-transform: capitalize;
        }
    }
    & > img {
        border-radius: 8px;
        max-width: 100%;
        height: auto;
    }
    @media screen and (max-width: 991px) {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        & > div {
            margin-top: -40px;
            margin-bottom: 32px;
            margin-right: 24px;
            & > p {
                margin-bottom: 0;
                font-weight: 300;
                font-size: 14px;
                margin-right: 8px;
            }
        }
        & > img {
            border-radius: 0;
            max-width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @media screen and (max-width: 460px) {
        & > div {
            margin-right: 16px;
        }
    }
}