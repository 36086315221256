.faq-page {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    margin-bottom: 32px;
    margin-top: 32px;
    @media screen and (max-width: 767px) {
        padding: 5px;
        margin-bottom: 0;
    }

     &__side-card {
        padding: 30px 30px 15px 30px;
        @media screen and (max-width: 767px) {
            padding: 5px;
        }
     }

     &__main {
        @media screen and (max-width: 767px) {
        }
    }

     &__nav-list {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        padding-left: 30px;
        padding-bottom: 11px;
        padding-top: 11px;
        cursor: pointer;
     }

     &__title {
        font-weight: 600;
        font-size: 18px;
        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
     }

     &__title-big {
        font-weight: 400;
        font-size: 24px;
        padding: 0;
        @media screen and (max-width: 767px) {
            font-size: 18px;
            margin-bottom: 0;
            font-size: 16px;
        }
        
     }

     &__nav-list-active {
        border-left: 3px solid #FF0F51;
        color: #FF0F51;
    }

    & .hosted-table-subcontent {
        & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
        font-family: 'Poppins' !important; 
        font-size: 18px;
        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
        & > ol {
            list-style: unset;
            list-style-type:decimal;
            margin-left: 20px;
            
            & > li {
                margin-bottom: 8px;
                padding-inline-start: 0.7ch;

                &::marker {
                    font-weight: 500;
                }
            }
        }
        & p {
            font-size: 18px;
            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }
        
    }
}

.faq-new {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    margin-top: 60px;
    @media screen and (max-width: 613px) {
        margin-top: 20px;
    }
    & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & > section {
            width: 276px;
            height: 276px;
            background: #FFFFFF;
            box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
            border-radius: 8px;
            margin-bottom: 60px;
            margin-right: 8px;
            margin-left: 8px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 613px) {
                margin-bottom: 16px;
            }

            & > div:nth-child(1) {
                display: flex;
                & > div {
                    z-index: 2;
                    background: #FFC6D3;
                    border-radius: 80px;
                    width: 36px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & > div {
                        background: #FF0F4F;
                        border-radius: 80px;
                        width: 32px;
                        height: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        & > p {
                            font-weight: bold;
                            font-size: 18px;
                            color: #FFFFFF;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            & > div:nth-child(2) {
                margin-top: -30px;
                padding: 0 16px;
                margin-bottom: 16px;
                & > div {
                    display: flex;
                    justify-content: center;
                    & > img {
                        z-index: 1;
                        max-height: 162px;
                    }
                    z-index: 1;
                }
                z-index: 1;
            }
            & > h2 {
                font-weight: 700;
                font-size: 14px;
                text-align: center;
                margin-top: auto;
                margin-bottom: 0;
            }
            & > p {
                font-weight: 400;
                font-size: 14px;
                text-align: center;
                margin-bottom: auto;
            }
        }
        & > div {
            margin-left: -90px;
            margin-right: -90px;
            position: relative;
            bottom: 33px;
        }
        & > .faq-section-two-digits {
            & > div:nth-child(1) {
                & > div {
                    & > div {
                        padding: 2px 8px !important;
                    }
                }
            }
        }
        & > .faq-section-no-p {
            & > h2 {
                margin-bottom: auto !important;
            }
        }
    }
}