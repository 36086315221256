 .landing-banner {
    margin: 20px 10px 0 10px;
    background: url("/images/background/landingBg.png");
    height: 300px;

    @media screen and (max-width: 991px) {
        margin: 20px 0 0 0;
        height: 100%;
        padding: 48px 15px 48px 15px;
        border-radius: 4px;
        background: url("/images/background/landingBg.png");
        background-size: cover ;
    } 

    &__text {
        &-big {
            font-weight: 500;
            font-size: 30px;
            font-family: 'Montserrat';
            line-height: 32px;
            margin: 0;
            @media screen and (max-width: 767px) {
                font-size: 16px;
            } 
        }

        &-small {
            font-weight: 300;
            font-size: 20px;
            font-family: 'Montserrat';
            line-height: 32px;
            text-align: left;
            @media screen and (max-width: 767px) {
                font-size: 16px;
            } 
        }
    }
}

.faq-banner {
    background: url("/images/background/faq-banner-bg.jpg");
    background-size: cover ;
}

.btn-pink-hover:hover {
    background-color: #DB003C !important;
    color: white !important;
}

.landing-banner-card {
    padding: 15px 30px;
    @media screen and (max-width: 767px) {
        padding: 12px;
    } 
    &__text {
        &-big {
            font-weight: 700;
            font-size: 16px;
            font-family: 'Montserrat';
            color: black;
            margin: 0;
            @media screen and (max-width: 767px) {
                font-size: 16px;
            } 
        }

        &-info {
            font-family: 'Montserrat';
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            color: #626262;
            @media screen and (max-width: 767px) {
                font-size: 12px;
            } 
        }

        &-text {
            font-family: 'Montserrat';
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            @media screen and (max-width: 767px) {
                font-size: 12px;
            } 
        }
    }
}

.slick-slide {
    cursor: default;
}

.keunggulan {
    margin-top: 80px;
    @media screen and (max-width: 767px) {
        margin-right: 22px;
        margin-left: 22px;
    }

    &__title {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        @media screen and (max-width: 767px) {
            text-align: center;
            font-size: 20px;
        }
    }
    
    &__title-real {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        @media screen and (max-width: 767px) {
            text-align: center;
            font-size: 20px;
            margin-bottom: 30px;
        }
    }

    &__card {
        min-height: 203px;

        &-img {
            background: url("/images/landing/bg.png");
            background-repeat: no-repeat;
            background-position: 40%;
            min-height: 101px;
        }

        &-title {
            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 0;
        }

        &-desc {
            font-family: 'Montserrat';
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-top: 3px;
            padding: 0 20px;
        }

        @media screen and (max-width: 767px) {
           padding: 10px;
        }
    }
}

.beranda-link:hover {
    text-decoration: underline;
}

.kelas-populer  {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    display: flex;
    justify-content: center;
    & > div {
        width: 83.33333%;
        max-width: 1200px;
        @media screen and (max-width: 991px) {
            width: 100%;
            padding: 0 16px;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        @media screen and (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 0;
        }
    }

    &__desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        @media screen and (max-width: 767px) {
            margin-bottom: 0;
            font-size: 12px;
        }
    }

    & .row {
        margin-right: -12px;
        margin-left: -12px;
        & > div {
            padding-right: 12px;
            padding-left: 12px;
            & > div {
                width: 100%;
            }
        } 
    }

    & .slick-track {
        & > div:nth-child(1) {
            padding-left: 0;
        }
    }

    & .slider {
        padding-top: 12px;
    }
}

.kategori-kelas {
    margin-top: 80px;
    padding: 0 12px;
}

.event-card {
    max-width: 274px;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    display: flex;
    
    &__bg {
        position: relative;
        height: 319px;
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        padding: 8px 0 12px;
        flex-shrink: 0;
    }

    &__content {
        padding: 0 17px;
    }

    &__schedule {
        height: 66px;
        width: 47px;
        background: url("/images/events/schedule.png");

        &-date{
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            color: #FFFFFF;
        }
        &-month{
            color: #FFFFFF;
            font-size: 14px;

            
            
        }
    }

    &__price {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        color: #004F8A;
    }

    &__content-main {
        margin-top: 10px;
        height: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 700;
        letter-spacing: 0.5px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        
    }
}

.instruktur-landing {
    margin-top: 140px;
    margin-right: 90px;
    margin-left: 90px;
    @media screen and (max-width: 991px) {
        margin-top: 30px;
        margin-right: 0;
        margin-left: 0;
    } 
    &__bg {
        height: 256px;
        width: 100vw;
        border-top: 1px solid #DDDDDD;
        border-bottom: 1px solid #DDDDDD;
        background: #FFFFFF;
        position: absolute;
        left: 0;
        z-index: -2;
        margin-top: 29px;
    }

    &__padding-mobile {
        @media screen and (max-width: 991px) {
            padding: 0;
        }
    }

    &__img {
        height: 314px;
        width: 314px;
        @media screen and (max-width: 991px) {
            height: 100%;
            width: 100%;
        }
    }

    &__text-small {
        font-size: 16px;
        line-height: 28px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
            margin-left: 5px;
        }
    }


    &__text-big {
        font-weight: 700;
        font-size: 30px;
        line-height: 44px;
        @media screen and (max-width: 991px) {
            font-size: 20px;
            margin-top: 20px;
            margin-left: 5px;
        }
    }

    &__text-btn {
        width: 260px;
        @media screen and (max-width: 991px) {
            margin-left: 5px;
            width: 173px;
        }
    }

}

.footer-landing {
    margin-top: 150px;
    padding: 78px 10vw 78px 17vw;
    @media screen and (max-width: 991px) {
        padding: 10px;
        margin-top: 50px;
    }
    width: 100vw;
    background: url('/images/landing/footer-bg2.jpg') ;
    background-size: cover;
    left: 0;

    &__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        @media screen and (max-width: 991px) {
            font-size: 20px;
        }
    }

    &__card {
        width: 412px;
        @media screen and (max-width: 991px) {
            width: 100%;
            max-width: 100vw;
         }
        min-height: 240px;
        padding: 20px;
    }

    &__text {
        font-style: 300;
        font-size: 20px;
        line-height: 28px;
        @media screen and (max-width: 1092px) {
            font-size: 12px;
        }
    }

    &__link {
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #FF0F51;

        &hover {
            color: #DB003C;
        }
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    
}

.kategori-kelas-card {
    cursor: pointer;
    &__text {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
    }
}

.red-screen {
    margin-top: 80px;
    margin-right: 35px;
    margin-left: 35px;
    @media screen and (max-width: 991px) {
        margin-right: 0;
        margin-left: 0;
    }
    &__bg {
        height: 200px;
        width: 100vw;
        background: #FF0F51;
        background-image: url("/images/landing/redbg.png");
        position: absolute;
        left: 0;
        z-index: -2;
        @media screen and (max-width: 991px) {
            height: 100%;
            margin-bottom: 20px;
        }
    }

    &__content {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    &__content-text-big {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        color: white;
    }

    &__content-text-small {
        font-family: 'Montserrat';
        font-weight: 300;
        font-size: 16px;
        text-align: center;
        color: white;
    }
}

.kontak-page {
    margin-top: 80px;
    padding-left: 60px;
    padding-right: 60px;
    @media screen and (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
        margin-top: 40px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 35px;
        padding-bottom: 18px;
    }

    &__bold-text {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        
    }

    &__text {
        font-family: 'Montserrat';
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
    }

    &__bantuan-kami {
        padding-left: 40px;
        @media screen and (max-width: 991px) {
            padding: 10px;
            margin-top: 20px;
        }   
        
    }
    &__btn {
        height: 40px !important;
        width: 220px !important;
        @media screen and (max-width: 991px) {
            width: 100% !important;
        }
    }

    &__map {
        height: 100%;
        width: 100%;
        @media screen and (max-width: 991px) {
            width: 100%;
            height: 300px;
        } 
    }
}


.card_mobile {
    @media screen and (max-width: 767) {
        margin-bottom: 16px;
    }
}

.text-mob-center {
    @media screen and (max-width: 767px) {
        text-align: center;
    }
}

.red-mob-flex {
    display: flex;
    @media screen and (max-width: 991px) {
        display: block
    }
}

.red-btm-margin {
    @media screen and (max-width: 991px) {
        margin-bottom: 80px;

    }
}

.red-bg-mobile {
    width: 100%;
    @media screen and (max-width: 991px) {
        background: #FF0F51;
        background-image: url("/images/landing/redbg.png");
        width: 100vw;
        margin-left: -16px!important;
        margin-right:-16px !important;
    }

}

.route-margin {
    display: flex;
    justify-content: center;
    & > div {
        display: flex;
        width: 83.33333%;
        max-width: 1200px;
        margin-top: 20px;
        margin-bottom: 12px;
        @media screen and (max-width: 767px) {
            margin-left: 0;
            margin-top: 12px;
            width: 100%;
            margin: 12px 16px;
        }
    }
    
}

.map-butuh-container {
    margin-top: 80px;
    @media screen and (max-width: 991px) {
        margin-top: 50px;
    }
}

.voucher-modal-content {
    display: grid;
    place-items: center;
    padding: 0 16px 24px 16px;

    & > .btn {
        width: 100%;
    }
    & p {
        font-family: 'Montserrat';
    }

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 12px;
    }

    &__desc-blue {
        padding: 8px 12px;
        border-radius: 4px;
        margin-bottom: 12px;
        text-align: center;
    }

}
