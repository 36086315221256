@import '../../styles/variables';

.social {
    padding: 20px;
    margin-top: 20px;

    &__title {
        padding-bottom: 20px;
        margin-bottom: 0;
        font-family: 'Montserrat';
        font-weight: 700 ;
        color: black;
    }

        
    &__wrap {
        padding-top: 18px;
    }

    &__link-pad {
        padding-left: 17px;
        font-size: 16px;
    }

    &__list {
        @media screen and (min-width: 960px) {
            padding-bottom: 20px;
        }
    }

    &__list-item {
        a {
            display: flex;
            align-items: center;
        }
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.social-new {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    @media screen and (max-width: 991px) {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 0;
    }
    padding: 16px;
    margin-top: 16px;
    background: #FFFFFF;
    border: 1px solid #ECEDEE;
    box-sizing: border-box;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 8px;

    &__title {
        margin-bottom: 16px;
        font-weight: 700 ;
        color: black;
    }

        
    &__wrap {
        padding-top: 18px;
    }

    &__link-pad {
        padding-left: 17px;
        font-size: 16px;
    }

    &__list {
        @media screen and (min-width: 960px) {
        }
    }

    &__list-item {
        a {
            display: flex;
            align-items: center;
        }
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: 960px) {
    .social {
        padding: 16px 0 0 8px;
        margin-top: 0;
        display: flex;
        flex-direction: column-reverse;

        &__title {
            padding: 24px 0 16px;
            text-align: center;
            margin-top: 24px;
            font-family: 'Montserrat';
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 8px;
        }

        &__wrap {
            padding-top: 12px;
        }

        &__termasuk-title {
            padding: 16px 0 12px;
            border-top: 1px solid $gray-2;
            text-align: start;
            font-family: 'Montserrat';
            font-size: 18px;
            font-weight: 500;
        }

        &__link-pad {
            padding-left: 13px;
        }

        &__link {
            display: flex !important;
            justify-content: center !important;
        }

        &__bagikan {
            margin-left: auto;
            margin-right: auto;
        }

        &__list-item {
            a {
                
                width: 42px;
            }
        }
    }

    .social.card {
        box-shadow: none;
        background-color: $body-bg;
    }

}