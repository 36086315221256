.menu {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #000000;
    padding-bottom: 20px;
    color: #FFFFFF;
    z-index: 4001;
    & .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        padding: 0;
    }
    a:hover {
        color: #FFFFFF;
    }
    & .ant-drawer-content {
        background: black;
    }

    &__header {
        position: relative;
        padding: 19px 0;
        margin-left: 16px;
        border-bottom: 0.5px solid #FFFFFF;
    }

    & .ant-collapse {
        & p, h1, h2, h3, h4, a, span, button, div { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: unset;
        list-style: none;
        font-feature-settings: "tnum";
        background-color: #000000;
        border: none;
        border-bottom: 0;
        border-radius: 2px;
        padding: 16px 0;
        & > .ant-collapse-item {
            border-bottom: none;
            margin-bottom: 16px;
            & > .ant-collapse-header {
                color: white;
                cursor: pointer;
                padding: 0 0 0 32px;
                font-size: 16px;
                font-weight: 500;
                position: unset;
                line-height: unset;
                cursor: pointer;
                transition: unset;
                & svg {
                    margin-left: 12px !important;
                }
            }
        }
    }

    & .ant-collapse-content {
        background: black;
        & > .ant-collapse-content-box {
            padding: 0 16px;
            background: black;
            & > li {
                margin: 6px 0;
            }
        }
        border: none;
    }
    
    &__title {
        position: absolute;
        top: calc(50% - 14px);
        left: calc(50% - 26px);
        color: #FFFFFF;
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        margin: 0;
    }

    &__list {
        padding: 16px 0; 
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__list-item {
        color: #FFFFFF;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
    }

    &__account {
        padding-top: 16px;
        margin-left: 16px;
        padding-left: 16px;
        border-top: 0.5px solid #FFFFFF;    
    }

    &__socials {
        padding-left: 30px;
        padding-right: 30px;
        max-width: 360px;
        width: 100%;

        a {
            color: #fff;
        }
    }
    
    &__socials-list {
        margin-top: 14px;
        padding: 0 42px;
    }

    .header {
        &__user a {
            color: #fff;
        }
    }
    &__sign {
        margin-top: auto;
        margin-bottom: 32px;
        & > div {
            width: 90%;
            margin-left: 16px;
            margin-right: 16px;
            & .btn {
                width: 100%;
            }
        }
        & > div:nth-child(2) {
            margin-top: 16px;
        }
    }
} 

.menu-item {

    .ant-collapse-header {
        color: #FFFFFF !important;
        padding: 0 !important;
        font-weight: 500;
        font-size: 16px;
        margin-left: 32px;
    }

    .ant-collapse-content.ant-collapse-content-active {
        padding-left: 12px;
    }
    
    .ant-collapse-borderless > .ant-collapse-item {
        border-bottom: 0;
        padding-top: 4px;
    }

    &__link {
        color: #FFFFFF !important;
        padding-left: 32px;
        font-size: 16px;
        font-weight: 500;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 16px 8px !important;

        li a{
            padding-top: 8px;
            font-weight: 300;
            letter-spacing: 1.5px;
        }
    }
}

.ant-drawer {
    z-index: 2000;
}

.modal-content {
    max-width: 100vw;
}

