.course-feature {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  }
  & img {
    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
  }
  @media screen and (max-width: 991px) {
    margin-right: 16px;
    margin-left: 16px;
  }
  & > div:nth-child(1) {
    display: flex;
    border-bottom: 1px solid #ced3d7;
    padding-bottom: 32px;
    padding-left: 4px;

    & > div {
      display: flex;
      flex-direction: column;
      align-content: center;
      max-width: 118px;
      margin-right: 36px;
      @media screen and (max-width: 991px) {
        margin-right: 16px;
      }
      & > p {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
      }
      & > div {
        display: flex;
        justify-content: center;
        & > div {
          background: #e6f7ec;
          border-radius: 100%;
          padding: 16px;
          margin-bottom: 16px;
        }
      }
    }
    & > div:nth-child(2) {
      & > div {
        & > div {
          background: #fcf7e2;
        }
      }
    }
  }

  & > div:nth-child(3) {
    margin-bottom: 60px;
    display: flex;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      margin-bottom: 40px;
    }
    & > div {
      width: 31%;
      @media screen and (max-width: 991px) {
        width: 100%;
        margin-bottom: 32px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;

      & > div {
        width: 223px;
        height: 158px;
        margin-bottom: 32px;
        @media screen and (max-width: 991px) {
          margin-bottom: 24px;
        }
      }
      & > h2 {
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        @media screen and (max-width: 991px) {
          margin-bottom: 16px;
        }
      }
      & > p {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
      }
    }
    & > div:nth-child(1) {
      margin-left: 0.47%;
      margin-right: 2.87%;
      @media screen and (max-width: 991px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    & > div:nth-child(2) {
      & > div {
        background: #ffffff;
        border: 1px solid #ecedee;
        box-sizing: border-box;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        & > p {
          font-weight: 600;
          font-size: 12px;
          text-align: center;
          margin-top: 8px;
        }
        & > h2 {
          background: #e6f7ec;
          border-radius: 8px;
          font-weight: 600;
          font-size: 12px;
          text-align: center;
          margin-top: 8px;
          padding: 4px 8px;
        }
      }
    }
    & > div:nth-child(3) {
      margin-right: 0.47%;
      margin-left: 2.87%;
      @media screen and (max-width: 991px) {
        margin-left: 0;
        margin-right: 0;
      }
      & > div {
        background: #ffffff;
        border: 1px solid #ecedee;
        box-sizing: border-box;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        & > p {
          font-weight: 600;
          font-size: 12px;
          text-align: center;
          margin-top: 12px;
        }
      }
    }
  }
}

.py-mob6-des10 {
  padding: 40px 0;
  border-bottom: 1px solid #ced3d7;
  margin-bottom: 40px;

  @media screen and (max-width: 991px) {
    padding: 24px 0;
    margin-bottom: 24px;
  }
}
