.prakerja-title {
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    margin-bottom: 0;
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
    @media screen and (max-width: 680px) {
        font-size: 16px;
    }
}

.prakerja-banner {
    height: 432px;
    display: flex;
    align-items: flex-end;
    padding-left: 12.6%;
    padding-bottom: 40px;
    background: url('/images/prakerja/banner-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
    @media screen and (max-width: 768px) {
        padding-bottom: 16px;
    }
    & > div {
        background: #FFFFFF;
        box-shadow: 5px 4px 9px 2px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        padding: 12px 20px;
        @media screen and (max-width: 768px) {
            padding: 8px 12px;
            border-radius: 8px;
        }
        & > img:nth-child(1) {
            max-height: 20px;
            max-width: 137px;
            @media screen and (max-width: 768px) {
                max-height: 12px;
                max-width: 82px;
            }
        }
        & > img:nth-child(3) {
            max-height: 40px;
            max-width: 122px;
            @media screen and (max-width: 768px) {
                max-height: 32px;
                max-width: 82px;
            }
        }
        & svg {
            margin: 0 12px;
            @media screen and (max-width: 768px) {
                margin: 0 8px;
            }
        }
        & > h2 {
            font-weight: 700;
            font-size: 32px;
            margin-bottom: 0px;
            color: white;
        }

        & .btn {
            background: #007EA7;
            border-radius: 8px;
            display: flex;
            align-items: center;
            padding: 8px 24px;
            margin-top: 24px;

            & p {
                margin-right: 8px;
            }

            &:hover {
                background: #01678a !important;
                border-color: #01678a !important;
            }
            &:focus {
                background: #01678a !important;
                border-color: #01678a !important;
            }
        }
    }

    @media screen and (max-width: 991px) {
        height: 284px;
        padding-left: 9%;
        & > div {
            & > h2 {
                font-size: 28px;
            }
        }
    }
    @media screen and (max-width: 680px) {
        height: 55vw;
        padding-left: 15px;
        background-image: url('/images/prakerja/banner-bg-mob.png');
        & > div {
            & > h2 {
                font-size: 24px;
            }
            & .btn {
                background: #007EA7;
                border-radius: 8px;
                padding: 8px 12px;
                margin-top: 8px;
            }
        }
    }
}

.prakerja-layanan {
    padding: 48px 0;
    @media screen and (max-width: 991px) {
        padding: 0 0 48px 0;
    }
    & > p {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        color: #7D7D7D;
        margin-top: 12px;
        margin-bottom: 36px;
        @media screen and (max-width: 991px) {
            font-size: 14px;
            margin-bottom: 16px;
        }
    }
    & > div {
        display: flex;
        flex-wrap: wrap;
        & > div {
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 32px;
            width: 340px;
            height: 120px;
            display: flex;
            background: #FFFFFF;
            box-shadow: 0px 0px 77px rgba(0, 0, 0, 0.06);
            border-radius: 16px;
            padding: 24px 32px;
            @media screen and (max-width: 991px) {
                width: 280px;
                height: 140px;
                margin-bottom: 16px;
            }
            @media screen and (max-width: 440px) {
                width: 100%;
                height: unset;
            }
            & > div:nth-child(2) > p {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 0;
                margin-left: 16px;
            }
        }
    }
    
}

.prakerja-partner {
    padding: 24px 0 32px 0;
    margin-bottom: 60px;
    background: #faf9f9;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
    border-radius: 12px;

    @media screen and (max-width: 991px) {
        margin-left: -15px;
        width: calc(100% + 30px);
        padding-top: 48px;
    }

    & > div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        & > h2 {
            margin-right: auto;
            margin-left: auto;
            padding-left: 124px;
            @media screen and (max-width: 991px) {
                padding-left: 0;
            }
        }
        & > div {
            display: flex;
            margin-right: 24px;
            @media screen and (max-width: 991px) {
                display: none;
            }
            & > div {
                background: #faf9f9;
                border: 1px solid #DFE6E5;
                box-sizing: border-box;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: background 0.2s ease;
                
                &:hover {
                    background: #FFF;
                    transition: background 0.2s ease;
                }
            }
        }
    }

    & > div:nth-child(2) {
        margin-top: 24px;
        & ul {
            & > li:first-child {
                & > div {
                    padding-left: 24px;
                }
            }
            & > li:last-child {
                & > div {
                    padding-right: 24px;
                }
            }
        }
    }

    &__partners {
        padding-right: 12px;
        padding-left: 12px;
        & > div {
            background: #FFFFFF;
            border-radius: 16px;
            height: 180px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 991px) {
            }
            & > div:nth-child(1) {
                display: flex;
                align-items: center;
                height: 62px;
                justify-content: space-between;
                border-bottom: 1px solid #ECEDEE;
                margin-bottom: 8px;
                & h2 {
                    padding: 13px 16px 20px 0;
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 20px;
                    text-transform: uppercase;
                    margin-left: 12px;
                }
                & img {
                    margin-bottom: 13px;
                }
            }
            & > div:nth-child(2) {
                & > h2 {
                    font-weight: 500;
                    font-size: 14px;
                    color: #8B9AA5;
                    margin-bottom: 8px;
                }
                & > p {
                    font-weight: 700;
                    font-size: 14px;
                    color: #313C45; 
                    margin-bottom: 24px;
                }
            }
            & > div:nth-child(3) {
                margin-top: 24px;
                & .btn {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #C90018;
                    & > p {
                        margin-right: 12px;
                    }
                }
            }
        }
    }

}

.prakerja-langkah {
    padding: 40px 0;
    background: linear-gradient(326.78deg, #007EA7 -25.82%, #00A8E8 114.92%);
    & > div {
        & > h2 {
            color: #FFF;
        }
        & > div:nth-child(2) {
            margin-top: 36px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        & > div:nth-child(3) {
            display: flex;
            justify-content: center;
            & > div {
                display: flex;
                background: #FFF;
                border-radius: 8px;
                padding: 8px 16px;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background: rgb(243, 243, 243);
                }
                & > p {
                    font-weight: 600;
                    font-size: 16px;
                    color: #007EA7;
                    margin-bottom: 0;
                    margin-right: 8px;
                }
            }
        }
    }

    &__imgs {
        width: 33.33%;
        margin-bottom: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 1260px) {
            width: 50%;
        }
        @media (max-width: 539px) {
            width: 100%;
            margin-bottom: 20px;
        }
        
        & > div:nth-child(1) {
            position: relative;
            height: 48px;
            display: flex;
            align-self: flex-start;
            left: 172px;
            @media (max-width: 848px) {
                left: 0;
                align-self: center;
            }
            @media (max-width: 539px) {
                height: unset;
                position: unset;
                margin-bottom: 20px;
            }
            & > div:nth-child(1) {
                background: #F2902F;
                border-radius: 80px;
                padding: 2px 0;
                height: fit-content;
                width: 32px;
                display: flex;
                justify-content: center;
                position: relative;
                z-index: 2;
                & > p {
                    font-weight: 700;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin-bottom: 0;
                }                
            }
            & > div:nth-child(2) {
                background: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
                border-radius: 12px;
                width: 224px;
                padding: 12px;
                height: fit-content;
                position: relative;
                z-index: 1;
                top: 12px;
                left: -16px;
                & > p {
                    margin-bottom: 0;
                    font-size: 14px;
                }
            }
        }
    }
}

.prakerja-course {
    padding: 60px 0;

    &__top {
        display: flex;
        @media (max-width: 848px) {
            flex-direction: column;
        }
        & > div:nth-child(1) {
            width: 50%;
            margin-right: 36px;
            @media (max-width: 1052px) {
                width: fit-content;
            }
            @media (max-width: 848px) {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
            @media (max-width: 480px) {
                & img {
                    width: 100%;
                    height: auto;
                }
            }
            
        }
        & > div:nth-child(2) {
            width: 50%;
            @media (max-width: 848px) {
                width: 100%;
            }
            & > h2 {
                font-weight: 700;
                font-size: 24px; 
                letter-spacing: 2.1px;
                margin-bottom: 16px;
                @media (max-width: 440px) {
                    font-size: 16px; 
                }
            }
            & > h3 {
                font-weight: 700;
                font-size: 16px; 
                margin-bottom: 4px;
                @media (max-width: 440px) {
                    font-size: 14px; 
                }
            }
            & > p {
                font-weight: 400;
                font-size: 16px; 
                margin-bottom: 16px;
                @media (max-width: 1052px) {
                    font-size: 14px; 
                }
            }
            & > p:nth-child(5) {
                margin-bottom: 12px;
            }
            & > div:nth-child(6) {
                display: flex;
                @media (max-width: 1052px) {
                    flex-direction: column;
                }
                & > div {
                    display: flex;
                    align-items: center;
                    & > p {
                        font-weight: 400;
                        font-size: 14px;
                        color: #5B7080;
                        margin: 0 8px;
                    }
                    @media (max-width: 1052px) {
                        margin-bottom: 8px;
                        & > svg {
                            width: 22px;
                        }
                    }
                }
            }
            & > div:nth-child(7) {
                display: flex;
                margin-top: 20px;
                @media (max-width: 1052px) {
                    flex-direction: column;
                    margin-top: 12px;
                }
                & > div:nth-child(1) {
                    width: 50%;
                    @media (max-width: 1052px) {
                        width: 100%;
                    }
                    & > h2 {
                        font-weight: 700;
                        font-size: 30px; 
                        margin-bottom: 8px;
                    }
                    & > div {
                        display: flex;
                        & > div {
                            padding: 2px 4px;
                            background: #CFF3FF;
                            border-radius: 4px;
                            & > p {
                                font-weight: 700;
                                font-size: 16px; 
                                margin-bottom: 0;
                            }
                        }
                        & > p {
                            font-weight: 500;
                            font-size: 16px;
                            text-decoration-line: line-through;
                            color: #738492; 
                            margin-bottom: 0;
                            margin-left: 8px;
                        }
                    }
                }
                & > div:nth-child(2) {
                    width: 50%;
                    @media (max-width: 1052px) {
                        width: 100%;
                        margin-top: 20px;
                    }
                    & .btn {
                        background: #C90018;
                        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                        border-radius: 8px;
                        padding: 12px 48px;
                        font-weight: 600;
                        @media (max-width: 440px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &__bottom {
        margin: 100px 0 40px 0;
        & > div {
            margin-top: 16px;
            @media (max-width: 991px) {
                width: calc(100% + 30px);
                margin-left: -15px;
                & ul {
                    & > li:first-child {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}

.prakerja-testimoni {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    padding-bottom: 100px;
    & > p {
        font-weight: 600;
        font-size: 22px;
        text-align: center;
        color: #7D7D7D;
        margin-top: 12px;
        @media (max-width: 991px) {
            font-size: 14px;
            margin: 12px 20px 0 20px
        }
    }
    & > div > div {
        padding-top: 80px;
        padding-bottom: 80px;
        @media (max-width: 448px) {
            padding-top: 24px;
        }
    }
    & ul {
        & li {
            & > div {
                @media (max-width: 448px) {
                    width: calc(100vw - 56px);
                }
            }
            
        }
        & > li:first-child {
            margin-left: 4.3333%;
            @media (max-width: 991px) {
                margin-left: 0;
            }
            @media (max-width: 448px) {
                margin-left: 28px;
            }
        }
        & > li:last-child {
            margin-right: 4.3333%;
            @media (max-width: 991px) {
                margin-left: 0;
            }
            @media (max-width: 448px) {
                margin-right: 28px;
            }
        }
    }

    &__testimonies {
        background: #FFFFFF;
        border-radius: 29px; 
        padding: 32px;
        box-shadow: 0px 0px 77px rgba(0, 0, 0, 0.06);
        height: 100%;
        margin-right: 28px;
        margin-left: 28px;
        @media (max-width: 448px) {
            margin-right: 0;
            margin-left: 0;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        }
        & > div {
            display: flex;
            margin-bottom: 28px;
            @media (max-width: 991px) {
                margin-bottom: 14px;
            }
            & > div:nth-child(1) {
                width: 48px !important;
                height: 48px;
                & > img {
                    width: 48px;
                    height: 48px;  
                }
            }
            & > div:nth-child(2) {
                margin-left: 20px;
                width: calc(100% - 48px);
                & > h2 {
                    font-weight: 600;
                    font-size: 22px;
                    margin-bottom: 0;
                    @media (max-width: 991px) {
                        font-size: 16px;
                    }
                }
                & > h3 {
                    font-weight: 600;
                    font-size: 14px;
                    color: #37474F;
                    margin-bottom: 0;
                    opacity: 0.65;
                    height: 20px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
            }
        }
        & > p {
            color: rgba(55, 71, 79, 0.721569);
            margin-bottom: 0;
        }
    }
    & .react-multi-carousel-dot button {
        width: 10px;
        height: 10px;
        border: none;
        background: rgba(124, 124, 124, 0.31);
        margin-right: 20px;
        transition: background .3s;
        &:hover {
            width: 12px;
            height: 12px; 
        }
    }
    & .react-multi-carousel-dot--active button {
        background: #C90018;
        width: 14px;
        height: 14px;
        transition: background .3s;
    }
}