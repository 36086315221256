.progress-bar {
    background-color: #FF0F51;
}

.progress-bar-empty {
    background-color: #C4C4C4;
}

.progress-bar-vid {
    background-color: #189e11;
    cursor: pointer;
}

.progress-bar-empty-vid {
    background-color: #ca2323;
    cursor: pointer;
}

.progress {
    background-color: #C4C4C4;
    height: 7px;

    &__text {
        font-family: 'Montserrat';
        font-size: 16px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }
}

.progress-vid {
    background-color: #ca2323;
    height: 5px;
    margin: 0 4px 0 4px;

    &__text {
        font-family: 'Montserrat';
        font-size: 16px;
        
    }
}


.progress-container {
    background-color: #F9F9F9;
    padding: 12px 16px;
    border-radius: 4px;
    margin-top: 12px;
    @media screen and (max-width: 991px) {
        padding: 4px;;
        margin-top: 4px;
    }
}

.vid-progress-container {
    background-color: #F9F9F9;
    padding: 6px 0;
    border-radius: 4px;
}

.info-kelas-self-vprogress {
    padding: 8px 16px;
    margin-top: 16px;
    background: rgba(164, 230, 255, 0.19);
    border-radius: 8px;
    border-left: 10px solid #A4E6FF;
    & h2 {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    & h3 {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        text-decoration: underline;
        cursor: pointer;
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    & p {
        font-family: 'Montserrat';
        font-weight: 300;
        font-size: 14px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 24px;
    }
}