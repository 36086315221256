.carousel-banner {
  margin-bottom: 32px;
  &__carousel {
    & .slick-slide {
      padding: 0 !important;
    }

    & .carousel-img-container {
      display: flex !important;
      justify-content: center;
    }

    & img {
      width: 100%;
      height: 100%;
    }

    & .slick-dots-bottom {
      bottom: 8%;
    }

    & .slick-dots li button {
      background: #ffffff;
      opacity: 1;
    }

    & .slick-dots li.slick-active button {
      background: #000000;
      opacity: 1;
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 24px;
    & .slick-dots-bottom {
      bottom: 16%;
    }
  }
}

.voucher-prakerja-new {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;

  & > div {
    width: 83.33333%;
    max-width: 1200px;
    display: flex;
    background: #e0f3fc no-repeat;
    padding: 16px 32px 0px 0;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 8px;

    & > img {
      max-height: 142px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > h1 {
        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 12px;
      }
      & > p {
        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        font-size: 12px;
      }
      & > img {
        margin-bottom: 6px;
      }
      margin-bottom: 16px;
    }

    & > div:nth-child(3) {
      margin-left: 2.5%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;

      & .form-prakerja {
        display: flex;
        gap: .5rem;
        flex-direction: column;
        // margin-bottom: 12px;

        & .search__input {
          flex-grow: 1;
          max-width: unset;
          margin: 0;
        }
      }

      & > h3 {
        color: #2e5aac;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        & > svg {
          margin-right: 8px;
        }
      }
    }
  }

  & .secondary-style {
    width: 100%;
    margin-top: 16px;
    margin-bottom: -40px;

    & > img {
      max-height: 142px;
    }

    & > div {
      & > h1 {
        font-size: 16px;
        margin-bottom: 12px;
        font-weight: 600;
        margin-top: 8px;
      }
      & > p {
        font-size: 12px;
      }
      & > img {
        margin-bottom: 6px;
      }
      margin-bottom: 16px;
    }

    & > div:nth-child(3) {
      margin-left: 2.5%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > div {
        display: flex;
        margin-bottom: 12px;

        & > input {
          flex-grow: 1;
          max-width: unset;
        }
      }

      & > h3 {
        color: #2e5aac;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        & > svg {
          margin-right: 8px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 48px;
    & > div {
      width: 100%;
      margin: 0 16px;
      flex-direction: column;
      padding: 24px 16px;
      & > div {
        & > h1 {
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 4px;
        }
        & > p {
          font-weight: 400;
          font-size: 12px;
        }
        & > img {
          margin-bottom: 6px;
        }
        margin-bottom: 8px;
      }
      & > div:nth-child(3) {
        margin-left: 0;
        & > div {
          flex-direction: column;
          & > input {
            font-size: 14px;
            max-width: unset;
            margin-bottom: 8px !important;
            padding: 8px 12px;
          }
        }

        & > h3 {
          color: #2e5aac;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          & > svg {
            margin-right: 8px;
          }
        }
      }
    }
    &__images-mob {
      & > div:nth-child(2) {
        width: 100%;
        height: 100%;
        text-align: center;
      }
      & > div:nth-child(1) {
        display: flex;
        justify-content: flex-end;
      }
    }
    & .slick-dots-bottom {
      bottom: 16%;
    }
  }
}

.sertifikat-landing {
  display: flex;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
    margin-bottom: 60px;
  }

  &__nav {
    &-selected:after {
      width: 100% !important;
      left: 0 !important;
    }
    & > div:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: '';
      display: block;
      left: 50%;

      height: 3px;
      position: relative;
      background: rgb(0, 0, 0);
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
      margin-bottom: -3px;
    }

    display: flex;
    border-bottom: 1px solid #ced3d7;

    & > div {
      & > div {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        & > p {
          font-size: 14px;
          font-weight: 400;
          margin-left: 8px;
        }
      }
      margin-right: 24px;
      transition: 0.2s all;
      cursor: pointer;
    }

    & > div:nth-child(3) {
      margin-right: 0;
    }

    &-selected {
      & p {
        font-weight: 700 !important;
        margin-bottom: -3px;
      }
      & svg {
        margin-bottom: -3px;
      }
    }
  }

  &__content {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > p {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 24px;
      text-align: center;
      max-width: 83.33333%;
    }

    & img {
      -webkit-backface-visibility: hidden;
      -ms-transform: translateZ(0); /* IE 9 */
      -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
      transform: translateZ(0);
    }

    & > .e-sertifikat-landing {
      display: flex;
      justify-content: center;
      & > div:nth-child(1) {
        width: 18.58%;
        & > img {
          left: 60%;
          top: 28%;
          position: relative;
        }
      }
      & > div:nth-child(2) {
        width: 43.72%;
        z-index: -2;
      }
      & > div:nth-child(3) {
        width: 18.58%;
        & > img {
          left: -78%;
          top: 50%;
          position: relative;
        }
      }
    }

    & > .self-paced-landing {
      display: flex;
      justify-content: center;
      & > div:nth-child(1) {
        width: 46.72%;
        z-index: -2;
        margin-right: -21.52%;
      }
      & > div:nth-child(2) {
        display: flex;
        align-items: flex-end;
        width: 18.52%;
        margin-right: 4.52%;
        & > img {
          left: 50%;
          top: 5%;
          position: relative;
        }
      }
    }

    & > .diskusi-landing {
      display: flex;
      justify-content: center;
      & > div:nth-child(1) {
        width: 46.72%;
        z-index: -2;
        margin-right: -21.52%;
      }
      & > div:nth-child(2) {
        display: flex;
        align-items: flex-end;
        width: 18.52%;
        margin-right: 4.52%;
        & > img {
          left: 50%;
          top: 5%;
          position: relative;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div {
      flex-direction: column;
      padding: 24px 16px;
      margin-bottom: 24px;
    }
    &__nav {
      & > div {
        & > div {
          & > p {
            font-size: 12px;
            margin-left: 4px;
          }
        }
        margin-right: 12px;
      }

      & > div:nth-child(3) {
        margin-right: 0;
      }

      &-selected {
        & p {
          font-weight: 700 !important;
          margin-bottom: -3px;
        }
        & svg {
          margin-bottom: -3px;
        }
      }
    }

    &__content {
      & > .self-paced-landing {
        & > div:nth-child(1) {
          width: 90%;
          z-index: -2;
          margin: 0;
        }
      }
      & > .e-sertifikat-landing {
        & > div:nth-child(1) {
          width: 100%;
          & > img {
            left: 0;
            top: 0;
            position: relative;
          }
        }
      }
      & > .diskusi-landing {
        & > div:nth-child(1) {
          width: 100%;
          margin: 0;
          & > img {
            left: 0;
            top: 0;
            position: relative;
          }
        }
      }
    }
  }
}

.featured-by {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  & > div {
    width: 83.33333%;
    max-width: 1200px;

    & > h1 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      color: #20282e;
    }

    & > div {
      display: flex;
      margin-top: 32px;
      justify-content: center;
      & > div {
        display: flex;
        align-items: center;
        margin-right: 24px;
        margin-left: 24px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin-top: 40px;
    & > div {
      width: 100%;
      padding: 0 16px;

      & > h1 {
        font-size: 16px;
      }
      & > div {
        margin-top: 16px;
        & > div {
          display: flex;
          align-items: center;
          margin-right: 8px;
          margin-left: 8px;
        }
      }
    }
  }
}

.our-client {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #20282e;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      color: #ffffff;
    }

    & > p {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
      margin-top: 12px;
      margin-bottom: 24px;
    }

    & > .btn {
      padding: 8px 48px;
      border-radius: 8px;
    }

    & > div {
      flex-wrap: wrap;
      display: flex;
      margin-top: 12px;
      justify-content: center;
      & > div {
        display: flex;
        align-items: center;
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    & > div:nth-child(3) {
      margin-top: 24px;
      & > div {
        margin-right: 24px;
        margin-left: 24px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div {
      & > div {
        margin: 0 16px 16px 16px;
        & > div {
          margin-top: 16px;
        }
      }
      & > div:nth-child(3) {
        border-top: 1px solid #c2c2c2;
        padding-top: 16px;
      }
    }
  }
}

.instructor-banner {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 100px;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;

  & > div {
    width: 83.33333%;
    max-width: 1200px;
    display: flex;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    background: url('/images/landing/homepage-new/instruktur-banner-bg.png') #ccf4e1;
    background-size: cover;
    background-position: center;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > h1 {
        font-weight: 700;
        text-align: center;
        font-size: 24px;
        margin-bottom: 12px;
        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
        color: #20282e;
      }
      & > p {
        text-align: center;
        font-weight: 400;
        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
        font-weight: 300;
        font-size: 14px;
        color: #20282e;
      }

      & > button {
        background: #ffffff;
        border: 1px solid #20282e;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 8px 48px;
        color: black;
        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #20282e;
        margin-top: 24px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin-top: 0;
    & > div {
      width: 100%;
      margin: 0 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      & > div {
        width: 100%;
        & > h1 {
          font-size: 16px;
        }
        & > p {
          font-size: 12px;
        }

        & > button {
          font-size: 14px;
          padding: 8px 16px;
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: 0;
    & > div {
      background: url('/images/landing/homepage-new/instruktur-banner-bg-mob.png') #b7dfcc no-repeat;
      background-position: top;
      background-size: contain;
      & > div {
        margin-top: 60%;
      }
    }
  }
}

.ekosistem {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  background: #e0f3fc;
  padding: 48px 0;

  & > div {
    width: 83.33333%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      color: #20282e;
    }

    & > div {
      display: flex;
      margin-top: 32px;
      & > div {
        color: #20282e;
        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
        padding: 0 32px;
        border-right: 1px solid #94acbf;

        & > h2 {
          font-weight: 700;
          font-size: 20px;
          margin-top: 24px;
        }
        & > p {
          font-weight: 400;
          font-size: 18px;
        }
      }

      & > div:nth-child(1) {
        padding: 0 32px 0 0;
      }

      & > div:nth-child(3) {
        padding: 0 0 0 32px;
        border-right: 0;
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 24px 0;
    & > div {
      width: 100%;
      padding: 0 16px;

      & > h1 {
        font-size: 16px;
      }
      & > p {
        font-size: 12px;
      }

      & > div {
        flex-direction: column;
        align-items: center;
        margin-top: 12px;
        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 16px 0;
          border-bottom: 1px solid #94acbf;
          border-right: 0;

          & > h2 {
            font-weight: 700;
            font-size: 16px;
            margin-top: 24px;
          }
          & > p {
            font-weight: 400;
            font-size: 14px;
            text-align: center;
          }
        }

        & > div:nth-child(1) {
          padding: 16px 0 !important;
        }

        & > div:nth-child(3) {
          padding: 16px 0 !important;
          border-bottom: 0;
        }
      }
    }
  }
}

.pengalaman-belajar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  padding: 48px 0;

  & > div {
    width: 83.33333%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      color: #20282e;
    }

    & > h2 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
    }

    & > div {
      display: flex;
      margin-top: 40px;
      & > div {
        width: 33.33%;

        & > div {
          margin: 0 8px 16px 8px;
          background: #ffffff;
          border: 1px solid #ced3d7;
          box-sizing: border-box;
          box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
          border-radius: 8px;
          padding: 16px;
          margin-bottom: 16px;

          & > div {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            & > div:nth-child(3) {
              min-width: 90px;
              & > svg {
                margin-right: 2px;
              }
            }

            & > h2 {
              font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
              margin: 0 auto 0 8px;
              font-weight: 700;
              font-size: 16px;
            }
          }

          & > h2 {
            font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
            font-weight: 600;
            font-size: 16px;
          }

          & > p {
            font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
            font-weight: 300;
            font-size: 16px;
            font-style: italic;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 48px;
    & > div {
      width: 100%;
      padding: 0;

      & > h1 {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 4px;
        padding: 0 16px;
      }
      & > h2 {
        font-weight: 400;
        font-size: 12px;
        padding: 0 16px;
      }
      & > div {
        width: 100%;
        margin-top: 16px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        & > div {
          width: unset;
          display: flex;
          flex-direction: row;
          & > div {
            width: 298px;
            max-width: 90vw;
            & > div {
              & > div:nth-child(3) {
                margin-top: -3px;
                & > svg {
                  margin-right: 2px;
                }
              }

              & > h2 {
                font-size: 14px;
              }
            }

            & > h2 {
              font-size: 12px;
            }

            & > p {
              font-size: 12px;
            }
          }
        }
        & > div:nth-child(1) {
          & > div:nth-child(1) {
            margin-left: 16px;
          }
        }
        & > div:nth-child(3) {
          & > div:nth-child(2) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}

.tentang {
  display: flex;
  justify-content: center;
  margin-top: 40;
  margin-bottom: 40px;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  padding: 48px 0;

  &__modal {
    display: flex;
    flex-direction: column;
    padding: 0 0 12px 0;
    & > div {
      display: flex;
      justify-content: flex-end;
      & > svg {
        cursor: pointer;
      }
    }

    & > h1 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      margin-top: -16px;
      z-index: -1;
    }

    & > p {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
    }
  }

  & > div {
    width: 83.33333%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      color: #20282e;
    }

    & > h2 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
    }

    & > div {
      display: flex;
      & > div {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 12px;
        margin-top: 32px;

        & > h1 {
          font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
          font-weight: 700;
          font-size: 48px;
          text-align: center;
          margin-top: 32px;
          margin-bottom: 12px;
        }

        & > h2 {
          font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          margin-bottom: 16px;
          display: flex;
          & > div > svg {
            margin-left: 4px;
            cursor: pointer;
          }
        }

        & > p {
          font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin-bottom: 24px;

    &__modal {
      & > h1 {
        font-size: 16px;
      }

      & > p {
        font-size: 12px;
      }
    }

    & > div {
      width: 100%;
      padding: 0 16px;
      & > h1 {
        font-size: 16px;
      }

      & > h2 {
        font-size: 10px;
      }

      & > div {
        & > div {
          & > h1 {
            font-size: 16px;
            margin-top: 24px;
          }

          & > h2 {
            display: block;
            font-size: 10px;

            & > div > svg {
              margin-top: 4px;
            }
          }
          & > div {
            height: 63px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.instructor-landing-new {
  display: flex;
  justify-content: center;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  padding: 48px 0;

  & > div {
    width: 86.1111%;
    max-width: 1240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > h1 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
    }

    & > p {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
    }

    & > button {
      padding: 8px 48px;
      margin-top: 48px;
    }

    & > div {
      width: 100%;
      & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        & > div {
          margin-right: 1.45%;
          margin-left: 1.45%;
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          cursor: pointer;
          & > div {
            display: flex;
            flex-direction: column;
            height: 180px;
            // padding-top: 69.12%;
            width: 180px;

            background-size: contain !important;
            background-position: center !important;
            & > div {
              overflow: hidden;

              & > div:nth-child(2) {
                transition: top 0.1s ease 0s;
                background: rgba(32, 40, 46, 0.8);
                min-height: 51.2px;
                position: relative;
                top: -2px;
                padding: 8px;

                & > h3 {
                  margin-bottom: 0;
                  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                  font-weight: 600;
                  color: white;
                  font-size: 14px;
                  max-height: 16.37px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  -webkit-line-clamp: 1; /* number of lines to show */
                  -webkit-box-orient: vertical;
                }
                & > p {
                  margin-bottom: 0;
                  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                  color: white;
                  font-weight: 400;
                  font-size: 12px;
                }
              }
            }
            @media screen and (max-width: 1200px) {
              height: 140px;
              width: 140px;
              & > div {
                & > div:nth-child(2) {
                  min-height: 30px;
                  & > h3 {
                    font-size: 10px;
                  }
                  & > p {
                    font-size: 8px;
                  }
                }
              }
            }
          }
        }

        & > div:hover > div > div > div:nth-child(2) {
          transition: top 0.1s ease 0s;
          position: relative;
        }

        & > div:nth-child(1) {
          margin-left: 0px;
        }
        & > div:nth-child(6) {
          margin-left: 0px;
        }
        & > div:nth-child(5) {
          margin-right: 0px;
        }
        & > div:nth-child(10) {
          margin-right: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    & > div {
      width: 100%;
      max-width: unset;
      & > h1 {
        font-size: 16px;
        margin-right: 16px;
        margin-left: 16px;
      }

      & > p {
        font-size: 12px;
        margin-right: 16px;
        margin-left: 16px;
      }

      & > button {
        font-size: 12px;
      }

      & > div {
        width: 100%;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        & > div {
          width: 1024px;
          & > div {
            & > div {
              height: 180px;
              // padding-top: 69.12%;
              width: 180px;
              & > div {
                & > div:nth-child(2) {
                  & > h3 {
                    font-size: 14px;
                  }
                  & > p {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.course-landing-new {
  display: flex;
  justify-content: center;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  padding: 48px 0;
  margin-top: 40px;

  & > div {
    width: 83.33333%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    & > h1 {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 700;
      font-size: 24px;
    }

    & > p {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      font-size: 16px;
      border-bottom: 1px solid #ced3d7;
      padding-bottom: 24px;
    }
    & > div:nth-child(3) {
      display: flex;
      margin-top: 20px;
      & > div {
        margin-right: 24px;
        cursor: pointer;

        & > h2:after {
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: '';
          display: block;
          left: 50%;

          height: 3px;
          position: relative;
          background: #e5002c;
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
          margin-bottom: -3px;
          margin-top: 8px;
        }
        & > h2 {
          font-family: 'Lato';
          font-weight: 400;
          font-size: 16px;
          color: #20282e;
          text-transform: uppercase;
        }
      }

      & > .h2-selected {
        & h2:after {
          width: 100% !important;
          left: 0 !important;
        }

        & > h2 {
          font-family: 'Lato';
          font-weight: 700;
          font-size: 16px;
          text-transform: uppercase;
          color: #e5002c;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 40px;
    & > div {
      width: 100%;
      padding: 0 16px;

      & > h1 {
        font-size: 16px;
      }
      & > p {
        font-size: 12px;
        border-bottom: 0;
        padding-bottom: 0;
      }
      & > div:nth-child(3) {
        border-bottom: 1px solid #ced3d7;
        padding-bottom: 8px;
        margin-bottom: 8px;
        margin-top: 0;
        & > a {
          border: none;
          text-transform: uppercase;
          box-shadow: none;
          font-weight: 700;
        }
      }
      & > div:nth-child(4) {
        display: flex;
        flex-wrap: wrap;
        & > div {
          background: #ecedee;
          transition: all 0.2s;
          border-radius: 100px;
          padding: 10px;
          margin-right: 4px;
          margin-bottom: 16px;

          & > h2 {
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }

        & > .h2-selected {
          transition: all 0.2s;
          background: #ffe8ef;

          & > h2 {
            color: #e5002c;
            font-weight: 700;
          }
        }
      }
      & > div:nth-child(5) {
        margin-right: -16px;
        margin-left: -16px;
        & > div {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
}

.course-landing-new-not-landing {
  padding-top: 0;
  margin-top: 0;

  & > div {
    width: 100%;
    max-width: 1200px;
    @media screen and (max-width: 991px) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.container-homepage {
  padding: 0 !important;
}

.bg-container-white {
  background: white;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.react-multiple-carousel__arrow {
  color: red !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 6rem;
}

.react-multiple-carousel__arrow::before {
  color: black !important;
}

.carousel-outer-container {
  margin-left: -12px;
  margin-right: -12px;
}

.react-multiple-carousel__arrow--right {
  right: 0px !important;
}

.react-multiple-carousel__arrow--left {
  left: 4px !important;
}

.course-landing-arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  border: 0;
  opacity: 1;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  & > svg {
    border-radius: 100px;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  }
}

.course-landing-arrow--right {
  right: 0px !important;
  top: 0px !important;
}

.course-landing-arrow--left {
  left: 0px !important;
  // top: 0px !important;
}

.carousel-button-group {
  position: relative;
  margin: 0 -85px;
  bottom: 300px;
}
