.kelas-saya-link {
    font-weight: 500;
    font-family: 'Poppins';
    border: 0 !important;
    &__active {
        color: #FF0F51 !important;
        border-bottom: 3px solid #FF0F51 !important;
        transition: none;

        &:hover {
            color: #FF0F51 !important;
            border-bottom: 3px solid #FF0F51 !important;
        }


        @media screen and (max-width: 991px) {
            color: black !important;
        }
    }
    &:hover {
        border: 0;
        transition: none;
    }
    &:focus {
        transition: none;
    }
    @media screen and (max-width: 991px) {
        min-width: 100%;
        white-space: nowrap;
        padding-bottom: 16px;
    }
}

.modal-size-sertifikat {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    width: 860px;
    max-width: 100vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    @media (max-width: 768px) {
        align-items: flex-end;
    }
}

.modal-size-sertifikat-overlay  {
}

.btn-download-sertifikat:hover {
    background-color: #DB003C !important;
    color: white !important;
}

.btn-download-sertifikat {
    background-color: #DB003C !important;
    color: white !important;
    width: 100%;
    margin-top: auto;
}

.course-card {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 90%;
    margin-bottom: 20px;
    @media screen and (max-width: 991px) {
        width: 100%;
        padding: 8px;
    }

    &__padding-sertifikat {
        padding: 0 40px 20px 40px;
        @media screen and (max-width: 991px) {
            padding: 0 20px 10px 20px;
        }
    }

    &__img-modal {
        max-height: 360px;
        @media screen and (max-width: 991px) {
            max-height: 300px;
            margin: 0 10px 10px 10px;
            max-width: 330px;
        }
    }

    &__img {
        height: 180px;
        background-repeat:no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 4px;
        @media screen and (max-width: 991px) {
            width: 100%;
            height: 100%;
            margin-right: 0;
            align-self: center;
            background-size: contain;
            background-color: #f2efea;
        }

        &__sertifikat {
            height: 180px;
            background-repeat:no-repeat;
            background-size: cover;
            background-position: center center;
            margin: 0 16px;
            border-radius: 4px;
            @media screen and (max-width: 991px) {
                width: 100%;
                height: 120px;
                margin-right: 0;
                align-self: center;
            }
            &:hover {
                border: 1px solid black;
            }

        }

        &__sertifikat-modal {
            height: 180px;
            background-repeat:no-repeat;
            background-size: cover;
            background-position: center center;
            margin: 0 16px;
            border-radius: 4px;
        }

    }

    &__type {
        font-size: 16px;
        line-height: 24px;
        color: #004F8A;
        @media screen and (max-width: 991px) {
            font-size: 10px;
         }

         &-svg {
            width: 20px;
            height: 20px;
            @media screen and (max-width: 991px) {
                width: 10px;
                height: 10px;
            }
         }
    }

    &__class-name {
        color: black;
        font-weight: 700;
        font-size: 20px;
        margin-top: 23px;
        @media screen and (max-width: 991px) {
            font-size: 14px;
            line-height: 20px;
            margin-top: 0;
        }
    }

    &__course-name {
        color: black;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        margin-top: 23px;
        @media screen and (max-width: 991px) {
            font-size: 14px;
            line-height: 20px;
            margin-top: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
        }
    }

    &__instructor-name {
        color: black;
        font-weight: 300;
        font-size: 18px;
        margin-top: 20px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__bold-text {
        color: black;
        font-weight: 700;
        font-size: 14px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
        }
    }

    &__materi-text {
        font-size: 14px;
        @media screen and (max-width: 991px) {
            font-weight: 700;
            font-size: 8px;
        }
    }

    &__materi-button {
        @media screen and (max-width: 991px) {
            padding: 4px;
        }
    }

    &__mobile-container-img {
        @media screen and (max-width: 991px) {
            padding-right: 0;
        }
    }

    &__mobile-container-content {
        @media screen and (max-width: 991px) {
            padding: 0 8px;
        }
    }

    &__content{
        flex-direction: column;

        &__review {
            width: 100%;
            flex: 1;
            border-top: 1px solid #DDDDDD;
        }
    
        &__bottom {
            margin: 16px -15px 0 -15px;
            display: flex;
            align-items: center;
            padding: 8px 15px 0 15px;
            border-top: 1px solid #DDDDDD;
            @media screen and (max-width: 991px) {
                margin: 16px -7px 0 -7px;
            }
        }

        &__consultation-booked {
            display: flex;
            align-items: flex-end;
            border-top: 1px solid #ECEDEE;
            padding-top: 12px;
            @media screen and (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;
            }

            & > div {
                margin-top: 0;
                & > p {
                    font-weight: 600;
                    font-size: 10px; 
                }
                & > h2 {
                    font-weight: 400;
                    font-size: 14px;
                    margin-bottom: 0;
                    @media screen and (max-width: 991px) {
                        font-size: 12px; 
                    }
                }
            }
            & > div:nth-child(1) {
                margin-right: auto;
                & > p {
                    margin-bottom: 4px;
                }
            }
            & > div:nth-child(2) {
                display: flex;
                @media screen and (max-width: 991px) {
                    margin-top: 8px;
                }
            }
        }
        &__consultation-unbooked {
            display: flex;
            align-items: center;
            border-top: 1px solid #ECEDEE;
            padding-top: 12px;
            @media screen and (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;
            }
            & > p {
                font-size: 12px;
                margin-bottom: 0;
                @media screen and (max-width: 991px) {
                    margin-bottom: 8px;
                }
            }
            & > .btn {
                font-size: 12px;
            }
        }
    }

    &__code_voucher {
        position: relative;
        top: 30px;
        left: 120px;
    }

    &__prakerja {
        background-color: #76CAEA;
        color: white;
        border-radius: 4px;
        @media screen and (max-width: 991px) {
           font-size: 8px;
        }
    }

    &__selesai {
        background-color: rgba(111, 207, 151, 0.25);
        width: 88px;
        height: 30px;
        border: 1px solid #27AE60;
        box-sizing: border-box;
        border-radius: 100px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #219653;
        @media screen and (max-width: 991px) {
            font-size: 12px;
            width: 60px;
            height: 20px;
        }
    }

    &__qna {
        &__status {
            background: #F7D6DF;
            border: 1px solid #FF0F51;
            box-sizing: border-box;
            padding: 4px 12px;
            border-radius: 100px;
            @media screen and (max-width: 991px) {
                margin-right: 8px;
                padding: 4px 4px;
                border-radius: 20px;
            }
            & p {
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 14px;
                color: #FF0F51;
                @media screen and (max-width: 991px) {
                    font-size: 8px;
                }
            }
        }

        &__sched {
            margin-top: 16px;
            display: flex;
            @media screen and (max-width: 991px) {
                margin-top: 4px;
                flex-direction: column;
            }
        }
    }

    &__jadwal-hosted {
        font-family: 'Montserrat';
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 991px) {
            font-size: 10px;
        }
        &__btn {
            padding: 9px 16px;
            @media screen and (max-width: 991px) {
                font-size: 10px;
                padding: 4px;
            }
        }
    }
}

.btn-pilih-jadwal {
    width: 160px;
    height: 40px;
    border-radius: 4px;

    &:hover {
        background-color: #DB003C;
        color: white;
    }
    @media screen and (max-width: 991px) {
        width: 105px;
        font-size: 12px;
        height: unset;
    }
}

.prakerja {
    font-family: 'Montserrat';

    &__title {
        font-weight: 350;
        font-size: 20px;
        line-height: 24px;
        color: white;
        padding: 11px 19px;
        background-color: #FF0F51;
        text-align: center;
        width: 70%;

        @media screen and (max-width: 991px) {
            width: 100%;
            font-size: 12px;
            text-align: left;
        }
    }

    &__content {
        @media screen and (max-width: 991px) {
            font-family: 'Montserrat';
            font-weight: 300;
            font-size: 12px;
            line-height: 24px;
        }

        &-number {
            font-family: 'Montserrat';
            font-size: 18px;
            font-weight: 500;
            @media screen and (max-width: 991px) {
                font-weight: 300;
                font-size: 12px;
            }
        }

        @media screen and (max-width: 991px) {
            background: #EDF8FF;
            border: 1px solid #5A97BA;
            border-radius: 4px;
            padding: 8px;
            margin-bottom: 12px;
        }

    }

    &__content2 {
        @media screen and (max-width: 991px) {
            font-family: 'Montserrat';
            font-weight: 300;
            font-size: 12px;
            line-height: 24px;
        }

        &-number {
            font-family: 'Montserrat';
            font-size: 18px;
            font-weight: 500;
            @media screen and (max-width: 991px) {
                font-weight: 300;
                font-size: 12px;
            }
        }
    }
}

.btn-white:hover {
    background-color: #FFCFDC;
    color: #FF0F51;
}

.pilih-jadwal-content {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    padding: 0 30px 10px 30px;
    width: 952px;
    max-width: 100vw;
    &__img {
        width: 220px;
        height: 160px;
        @media screen and (max-width: 991px) {
            font-size: 10px;
            padding: 4px;
            width: 120px;
            height: 90px;
        }
    }

    &__consultation {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 1px solid #ECEDEE;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 12px 20px 4px 20px;
        & > div {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            border-bottom: 1px solid #ECEDEE;
            padding-bottom: 8px;
            & > p {
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 0;
                width: 40%;
            }
            & > p:nth-child(2) {
                text-align: center;
            }
            & > h2 {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 0;
                width: 40%;
            }
            & > h2:nth-child(2) {
                text-align: center;
            }
            & > div {
               flex-grow: 1;
               display: flex;
               justify-content: flex-end;
               & .btn {
                   font-size: 12px;
                   font-weight: 600;
                }
            }
        }
        & > div:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
        &__pagination {
            margin-bottom: 20px;
            justify-content: flex-end;
            margin-right: 30px;
            align-items: center;
            @media screen and (max-width: 991px) {
                margin-left: 30px;
            }
        }
    }
}

.btn-schedule {
    font-family: 'Montserrat';
    width: 160px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
}

.nav-kelas-mob{
    margin-top: 1.5rem;
    @media screen and (max-width: 991px) {
        margin-top: 0;
        width: 99vw;
        margin-left: -8px;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;
        flex-wrap: nowrap;
        flex-direction: column;
        border-bottom: none !important;
        & > li {
            padding-left: 16px;
            & > a {
                font-weight: 600;
                font-size: 16px;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.nav-tabs {
    @media screen and (max-width: 991px) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.205);
    }
}

.nav-kelas-mob::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.ml-auto-mobile-done {
    margin-left: auto;
    @media screen and (max-width: 991px) {
        margin-left: 0;
    }
}

.d-flex-desktop-profile {
    display: flex;
    @media screen and (max-width: 991px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

.ml-4-mobile {
    margin-left: 20px;
    @media screen and (max-width: 991px) {
        margin-left: 0;
    }
}

.mt-3-mobile {
    margin-top: 1rem;
    @media screen and (max-width: 991px) {
        margin-top: 0;
    }
}

.list-background-course {
    background: url('/images/background/blank-bg.png') center no-repeat;
}

.list-background-events {
    background: url('/images/background/blank-event-bg.png') center no-repeat;
    min-width: 400px;
    min-height: 400px;
    @media screen and (max-width: 991px) {
        min-width: 98vw;
    }
}

.list-background-orders {
    background: url('/images/background/blank-transaction-bg.png') center no-repeat;
}

.list-background-course-empty {
    background: white;
    @media screen and (max-width: 991px) {
        background: none;
    }
}

.info-kelas-self {
    padding: 16px;
    background: rgba(164, 230, 255, 0.19);
    border-radius: 16px;
    border-left: 10px solid #A4E6FF;
    height: 100%;
    & h2 {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    & p {
        font-family: 'Montserrat';
        font-weight: 300;
        font-size: 14px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 24px;
    }
}

.btn-download-course-list {
    padding: 7px;
    & svg {
        margin-right: 8px;
    }
    @media screen and (max-width: 991px) {
        margin-top: 8px;
        font-size: 12px;
    }
}

.content-drawer-kormo-job {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px !important;
    @media screen and (max-width: 991px) {
        margin: 0;
    }
}

.kormo-job-header {
    background: #FFFFFF;
    border-radius: 8px !important;
    border-radius: 8px;
    padding: 16px;
    & h1 {
        font-weight: 700;
        font-size: 16px;
    }
    & > div {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100%;
        & > div {
            display: flex;
            justify-content: space-between;
            & img {
                height: 24px;
                width: auto;
            }
        }
        & > p {
            font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
            font-size: 14px;
        }
    }
    
    &__header-closed {
        margin-bottom: 0 !important; 
        padding-bottom: 0 !important;
        border-bottom: none !important;
    }

    &:hover {
        color: unset !important;
    }
    
}

.kormo-job-header--opened {
    background: #FFFFFF;
    border-radius: 8px !important;
    padding: 16px;
    & h1 {
        font-weight: 700;
        font-size: 16px;
    }
    & > div {
        border-bottom: 1px solid#D1D1D1;
        padding-bottom: 16px;
        margin-bottom: 0;
        width: 100%;
        & > div {
            display: flex;
            justify-content: space-between;
            & img {
                height: 24px;
                width: auto;
            }
        }
        & > p {
            font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
            font-size: 14px;
        }
    }
    &:hover {
        color: unset !important;
    }
}

.kormo-job {
    background: #FFFFFF;
    border-radius: 8px !important;
    padding: 16px;
    margin-bottom: 16px;
    & h1 {
        font-weight: 700;
        font-size: 16px;
    }
    
    & > div:nth-child(1) {
        border-bottom: 1px solid#D1D1D1;
        padding-bottom: 8px;
        margin-bottom: 16px;
        & > div {
            flex-wrap: wrap;
            display: flex;
            margin: 16px -8px 0 -8px;
            @media screen and (max-width: 655px) {
                justify-content: center;
            }
            & > div {
                display: flex;
                align-items: center;
                border: 1px solid #CED3D7;
                border-radius: 4px;
                min-width: 295px;
                width: 31.55%;
                padding: 8px 12px;
                margin: 0 8px 8px 8px;
                & > h2 {
                    font-weight: 600;
                    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-right: auto;
                    margin-left: 8px;
                }
                & > button {
                    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                    font-size: 14px;
                    padding: 4px;
                }
            }
            
        }
    }
    & > div:nth-child(2) {
        & > div {
            flex-wrap: wrap;
            display: flex;
            margin: 16px -8px 0 -8px;
            @media screen and (max-width: 655px) {
                justify-content: center;
            }
            & > div {
                display: flex;
                border: 1px solid #CED3D7;
                border-radius: 4px;
                min-width: 295px;
                width: 31.55%;
                padding: 8px 0 0 8px;
                margin: 0 8px 8px 8px;
                & > img {
                    margin-left: auto;
                }
                & > div > h1 {
                    background: #FF0F4F;
                    border-radius: 80px;
                    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                    font-weight: 700;
                    font-size: 14px;
                    color: #FFFFFF;
                    width: 1.5rem;
                    display: flex;
                    justify-content: center;
                    box-shadow: 2px 2px 4px #FFC6D3;
                }
                & > p {
                    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
                    font-size: 14px;
                    margin-left: 8px;
                }
            }
            
        }
    }
}