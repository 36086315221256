.notification-dropdown {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    box-shadow: 0px 2px 6px #b8b8b8 !important;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    margin-right: 0;
    & > div:nth-child(1) {
        display: flex;
        align-items: center;
        & > h2 {
            margin-bottom: 0;
            font-weight: 700;
            font-size: 16px;
        }
        & > div {
            cursor: pointer;
        }
        border-bottom: 1px solid #ECEDEE;
        padding: 24px 24px 12px 24px;
    }

    & .ant-menu-item {
        padding: 0;
        margin-top: 0;
        height: unset;
        white-space: unset;
        line-height:initial;
        display: flex;
        flex-direction: column;
        cursor: unset;
        &:hover {
            color: unset !important;
        }
        &  > a {
            margin: 0;
            font-family: 'Poppins';
            color: #000000;
            font-weight: 400;
            &:hover {
                color: #FF0F51 !important;
            }
        }
        & a:before {
            display: none;
        }
    }

    &__header {
        padding: 12px 24px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        & > h2 {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
        }
        & > div {
            cursor: pointer;
            & > p {
                font-size: 14px;
                color: #FF0F4F;
                font-weight: 500;
                transition: color 0.1s;
            }
            & > p:hover {
                color: #b00633;
                transition: color 0.1s;
            }
        }
        & > div:nth-child(2) {
            margin-left: auto;
            margin-right: 12px;
        }
    }

    &__header_2 {
        padding: 0 24px;
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > h2 {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
        }
        & > div {
            cursor: pointer;
            & > p {
                font-size: 14px;
                color: #FF0F4F;
                font-weight: 500;
                transition: color 0.1s;
            }
            & > p:hover {
                color: #b00633;
                transition: color 0.1s;
            }
        }
    }

    &__child-unread {
        & > div {
            background: #F2FBFF;
        }
    }
    &__child {
        & > div {
            background: white;
        }
    }

    &__review {
        padding: 0 24px;
        margin-bottom: 12px;
        & > div {
            padding: 8px 16px 16px 16px;
            border: 1px solid #ECEDEE;
            border-radius: 8px;
            & > div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                & > h2 {
                    font-weight: 600;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-left: 8px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-align: right;
                }
            }
            & > div:nth-child(2) {
                background: #FFFFFF;
                border: 1px solid #ECEDEE;
                border-radius: 8px;
                padding: 16px;
                & > h2 {
                    font-weight: 600;
                    font-size: 14px;
                    margin-left: 8px;
                    text-align: center;
                    margin-bottom: 8px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-align: center;
                }
                & > p:nth-child(2) {
                    margin-bottom: 12px;
                }
                & > div:nth-child(3) {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 12px;
                    border-bottom: 1px solid #ECEDEE;
                    margin-bottom: 12px;
                    & > div {
                        cursor: pointer;
                        & > svg {
                            margin-right: 4px;
                            margin-left: 4px;
                        }
                    }
                }
                & > p:nth-child(4) {
                    text-align: left;
                    margin-bottom: 8px;
                }
                & > div:nth-child(5) {
                    display: flex;
                    flex-wrap: wrap;
                    padding-bottom: 12px;
                    border-bottom: 1px solid #ECEDEE;
                    margin-bottom: 12px;
                    & > p {
                        background: #FFFFFF;
                        border: 1px solid #ECEDEE;
                        box-sizing: border-box;
                        border-radius: 100px;
                        font-weight: 400;
                        font-size: 12px;
                        text-transform: uppercase;
                        padding: 8px 12px;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        cursor: pointer;
                    }
                    & > p:hover {
                        background: #e3e3e3;
                    }
                    & > .pros-selected {
                        background: #FF0F51;
                        color: #ffffff;
                    }
                    & > .pros-selected:hover {
                        background: #c70438;
                        color: #ffffff;
                    }
                }
                & > div:nth-child(6) {
                    display: flex;
                    justify-content: flex-end;
                    @media screen and (max-width: 560px) { 
                        width: 100%;
                    }
                    & button {
                        font-weight: 600;
                        background-color: #C90018;
                        font-size: 12px;
                        @media screen and (max-width: 560px) { 
                            width: 100%;
                        }
                        
                    }
                    & .btn:disabled {
                        background: #8B9AA5 !important;
                        opacity: .65;
                        border-color: gray !important;
                    }
                }
            }
        }
        &__modals {
            padding: 12px 28px 12px 20px;
            display: block;
            & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
            & > div {
                display: flex;
                justify-content: flex-end;
                height: 0;
                cursor: pointer;
                & > svg {
                    position: relative;
                    left: 24px;
                    bottom: 10px;
                }
            }
            & > h2 {
                text-align: center;
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 12px;
            }
            & > form {
                width: 420px;
                & > input {
                    width: 100%;
                    margin-bottom: 12px;
                }
                & > textarea {
                    width: 100%;
                }
                & > div {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 16px;
                    & > button {
                        font-weight: 600;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        & > div {
                            width: 16px;
                            height: 16px;
                            margin-bottom: 2px;
                            margin-right: 8px;
                        }
                    }
                    & .btn:disabled {
                        background: #8B9AA5 !important;
                        opacity: .65;
                        border-color: gray !important;
                    }
                }
            }
        }
    }

    &__certificate {
        padding: 0 24px;
        margin-bottom: 12px;
        & > div {
            padding: 8px 16px 16px 16px;
            border: 1px solid #ECEDEE;
            border-radius: 8px;
            & > div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                & > h2 {
                    font-weight: 600;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-left: 8px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-align: right;
                }
            }
            & > div:nth-child(2) {
                background: #FFFFFF;
                border: 1px solid #ECEDEE;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                @media screen and (max-width: 560px) { 
                    flex-direction: column;
                }
                & > div:nth-child(1) {
                    white-space: inherit;
                    margin-right: 16px;
                    min-width: 50%;
                    border-radius: 8px;
                    @media screen and (max-width: 560px) { 
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                    }
                    & > img {
                        border-radius: 8px;
                        width: 100%;
                        max-width: 240px;
                        height:  100%;
                    }
                }
                & > div:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    & > h2 {
                        font-weight: 700;
                        font-size: 16px;
                    }
                    & > h3 {
                        font-weight: 600;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    & > p {
                        font-weight: 400;
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                    & > div {
                        display: flex;
                        margin-top: auto;
                        justify-content: flex-end;
                        @media screen and (max-width: 560px) { 
                            width: 100%;
                        }
                        & a {
                            @media screen and (max-width: 560px) { 
                                width: 100%;
                            }
                            margin-top: 12px;
                            font-weight: 600;
                            font-size: 12px;
                            color: white;
                        }
                        & button {
                            @media screen and (max-width: 560px) { 
                                width: 100%;
                            }
                            margin-top: 12px;
                            font-weight: 600;
                            background-color: #C90018;
                            font-size: 12px;
                            color: white;
                            
                        }
                        & .btn:disabled {
                            background: #8B9AA5 !important;
                            opacity: .65;
                            border-color: gray !important;
                        }
                    }
                }
                
            }
        }
    }

    &__free {
        padding: 0 24px;
        margin-bottom: 12px;
        & > div {
            padding: 8px 16px 16px 16px;
            border: 1px solid #ECEDEE;
            border-radius: 8px;
            & > div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                & > h2 {
                    font-weight: 600;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-left: 8px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-align: right;
                }
            }
            & > div:nth-child(2) {
                background: #FFFFFF;
                border: 1px solid #ECEDEE;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                @media screen and (max-width: 560px) { 
                    flex-direction: column;
                }
                & > div:nth-child(1) {
                    white-space: inherit;
                    margin-right: 16px;
                    min-width: 50%;
                    border-radius: 8px;
                    @media screen and (max-width: 560px) { 
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                    }
                    & > img {
                        border-radius: 8px;
                        width: 100%;
                        max-width: 240px;
                        height:  100%;
                    }
                }
                & > div:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    @media screen and (max-width: 560px) { 
                        flex-direction: row;
                        flex-wrap: wrap;
                    }
                    & > h2 {
                        font-weight: 700;
                        font-size: 16px;
                    }
                    & > h3 {
                        font-weight: 600;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    & > h4 {
                        font-weight: 400;
                        font-size: 12px;
                        margin-bottom: 0;
                        @media screen and (max-width: 560px) { 
                            margin-bottom: 12px;
                            margin-top: 12px;
                        }
                        
                    }
                    & > p {
                        font-weight: 400;
                        font-size: 12px;
                        margin-bottom: 0;
                        display: flex;
                        align-items: center;
                        margin-top: auto;
                        @media screen and (max-width: 560px) { 
                            margin-left: auto;
                            margin-bottom: 12px;
                            margin-top: 12px;
                        }
                        & > p {
                            font-weight: 400;
                            font-size: 12px;
                            margin-bottom: 0;
                        }
                    }
                    & > div {
                        display: flex;
                        margin-top: auto;
                        align-items: center;
                        @media screen and (max-width: 560px) { 
                            width: 100%;
                            flex-wrap: wrap;
                        }
                        & > h4 {
                            margin-bottom: 0;
                            font-weight: 600;
                            font-size: 12px;
                        }
                        & > p {
                            font-weight: 400;
                            font-size: 12px;
                            color: #738492;
                            margin-left: 4px;
                        }
                        & button {
                            @media screen and (max-width: 560px) { 
                                width: 100%;
                            }
                            margin-left: auto;
                            background-color: #C90018;
                            margin-top: 12px;
                            font-weight: 600;
                            font-size: 12px;
                            color: white;
                            
                        }
                        & .btn:disabled {
                            background: #8B9AA5 !important;
                            opacity: .65;
                            border-color: gray !important;
                        }
                    }
                }
                
            }
        }
    }

    &__profile {
        padding: 0 24px;
        margin-bottom: 12px;
        & > div {
            padding: 8px 16px 16px 16px;
            border: 1px solid #ECEDEE;
            border-radius: 8px;
            & > div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                & > h2 {
                    font-weight: 600;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-left: 8px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-align: right;
                }
            }
            & > div:nth-child(2) {
                background: #FFFFFF;
                border: 1px solid #ECEDEE;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                & > h2 {
                    font-weight: 700;
                    font-size: 16px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 12px;
                    margin-bottom: 0;
                }
                & > div {
                    display: flex;
                    margin-top: auto;
                    justify-content: flex-end;
                    & button {
                        margin-top: 12px;
                        background-color: #C90018;
                        font-weight: 600;
                        font-size: 12px;
                        
                    }
                    & .btn:disabled {
                        background: #8B9AA5 !important;
                        opacity: .65;
                        border-color: gray !important;
                    }
                }
            }
        }
    }

    &__nps {
        padding: 0 24px;
        margin-bottom: 12px;
        & > div {
            padding: 8px 16px 16px 16px;
            border: 1px solid #ECEDEE;
            border-radius: 8px;
            & > div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                & > h2 {
                    font-weight: 600;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-left: 8px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-align: right;
                }
            }
            & > div:nth-child(2) {
                background: #FFFFFF;
                border: 1px solid #ECEDEE;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                & > h2 {
                    font-weight: 600;
                    font-size: 14px;
                    margin-left: 8px;
                    text-align: center;
                    margin-bottom: 8px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-align: center;
                }
                & > div:nth-child(3) {
                    @media screen and (max-width: 560px) { 
                        width: 100%;
                    }
                    & > div:nth-child(1) {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin-top: 16px;
                        margin-bottom: 8px;
                        & .notification-dropdown__nps-selected {
                            border: 1px solid #C90018;
                            border-left: none;
                            & > p {
                                color: #C90018;
                            }
                        }
                        & > .notification-dropdown__nps-selected:nth-child(1) {
                            border-left: 1px solid #C90018;
                        }
                        & > div {
                            cursor: pointer;
                            display: flex;
                            border: 1px solid #8B9AA5;
                            border-left: none;
                            padding: 10px 14px;
                            @media screen and (max-width: 560px) { 
                                padding: 10px 0;
                                width: 10%;
                                justify-content: center;
                                
                            }
                            & > p {
                                color: #8B9AA5;
                                font-weight: 700;
                                font-size: 18px;
                                margin-bottom: 0;
                            }
                        }
                        & > div:nth-child(1) {
                            border-radius: 4px 0px 0px 4px;
                            border-left: 1px solid #8B9AA5;
                        }
                        & > div:nth-child(10) {
                            border-radius: 0px 4px 4px 0px;
                        }
                    }
                    & > div:nth-child(2) {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 16px;
                        & > p {
                            font-weight: 600;
                            font-size: 12px;
                            color: #8B9AA5;
                        }
                        & > p:nth-child(2) {
                            text-align: right;
                        }
                        
                    }
                    
                }
                & > div:nth-child(4) {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    & button {
                        margin-left: auto;
                        margin-top: 12px;
                        font-weight: 600;
                        font-size: 12px;
                        display: flex;
                        background-color: #C90018;
                        align-items: center;
                        justify-content: center;
                        @media screen and (max-width: 560px) { 
                            width: 100%;
                        }
                        & > div {
                            width: 16px;
                            height: 16px;
                            margin-bottom: 2px;
                            margin-right: 8px;
                        }
                    }
                    & .btn-disabled {
                        background-color: #8B9AA5;
                        cursor:default;
                        &:hover {
                            background-color: #8B9AA5 !important;
                            border-color: #8B9AA5;
                        }
                    }
                    & .btn:disabled {
                        background-color: #8B9AA5;
                        cursor:default;
                        &:hover {
                            background-color: #8B9AA5 !important;
                            border-color: #8B9AA5;
                        }
                    }
                }
            }
        }
    }

    &__satisfaction {
        padding: 0 24px;
        margin-bottom: 12px;
        & > div {
            padding: 8px 16px 16px 16px;
            border: 1px solid #ECEDEE;
            border-radius: 8px;
            & > div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                & > h2 {
                    font-weight: 600;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-left: 8px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-align: right;
                }
            }
            & > div:nth-child(2) {
                background: #FFFFFF;
                border: 1px solid #ECEDEE;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                & > h2 {
                    font-weight: 600;
                    font-size: 14px;
                    margin-left: 8px;
                    text-align: center;
                    margin-bottom: 8px;
                }
                & > p {
                    font-weight: 400;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-align: center;
                }
                & > div:nth-child(3) {
                    width: 100%;
                    display: flex;
                    margin-top: 12px;
                    justify-content: space-between;
                    @media screen and (max-width: 560px) { 
                        flex-direction: column-reverse;
                    }
                    & .satisfaction-selected {
                        border: 1px solid #D70C24 !important;
                    }
                    & > div {
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background: #FFFFFF;
                        border: 1px solid #ECEDEE;
                        border-radius: 8px;
                        padding: 12px;
                        width: 18%;
                        @media screen and (max-width: 560px) { 
                            width: 100%;
                        }
                        & p {
                            font-weight: 600;
                            font-size: 12px;
                            margin-bottom: 0;
                            text-align: center;
                            margin-top: 6px;
                        }
                    }
                    
                }
                & > div:nth-child(4) {
                    width: 100%;
                    display: flex;
                    & button {
                        margin-left: auto;
                        margin-top: 12px;
                        font-weight: 600;
                        font-size: 12px;
                        background-color: #C90018;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media screen and (max-width: 560px) { 
                            width: 100%;
                        }
                        & > div {
                            width: 16px;
                            height: 16px;
                            margin-bottom: 2px;
                            margin-right: 8px;
                        }
                    }
                    & .btn-disabled {
                        background-color: #8B9AA5;
                        cursor:default;
                        &:hover {
                            background-color: #8B9AA5 !important;
                            border-color: #8B9AA5;
                        }
                    }
                    & .btn:disabled {
                        background-color: #8B9AA5;
                        cursor:default;
                        &:hover {
                            background-color: #8B9AA5 !important;
                            border-color: #8B9AA5;
                        }
                    }
                }
            }
        }
    }

    & .ant-menu-item-selected {
        background-color: unset !important;
        color: unset !important;
    }
    & .ant-menu-item:active {
        background-color: unset !important;
        color: unset !important;
    }
    
}

.notification-overlay {
    width: 600px;
    max-width: 600px;
    position: fixed;
    right: 0;
    left: unset !important;
    max-height: 91.5vh;
    overflow-y: scroll;
    scrollbar-color: light;
    scrollbar-width: thin;
    box-shadow: 0px 2px 6px #b8b8b8 !important;
    top: 68px !important;

    &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
        background: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
        background: #ECEDEE;
    }

    @media screen and (max-width: 991px) { 
        top: 48px !important;
        width: 100vw;
        max-height: 93.5vh;
    }
}

.header-notification {
    display: block !important;
}

.header-notification-button {
    min-width: unset !important;
}

@media screen and (max-width: 991px) { 
    .ant-dropdown.ant-dropdown-placement-bottomLeft {
        top:48px !important;
    }
}

.notification-icon {
    display: flex;
    & > p {
        margin-bottom: 0;
        font-size: 10px;
        color: #E5002C;
        font-weight: 600;
        margin-left: -2px;
    }
}