.schedule {
    width: 100%;
    max-width: 960px;
    padding: 18px 24px 18px;
    margin-top: 14px;
    background: #FFFFFF;
    
    &__header {
        display: flex;
        flex-flow: column;
        margin-bottom: 14px;

        @media screen and (min-width: 576px) {
            flex-flow: row;
            align-items: center;
        }
    }

    &__title {
        font-weight: 500;
        font-family: 'Montserrat';
        font-size: 16px;
        padding-right: 21px;
        margin: 0 0 12px 0;

        @media screen and (min-width: 576px) {
            margin: 0;
            font-size: 24px;
        }
    }

    &__btn-disabled {
        background: #BDBDBD !important;
        min-width: 107px !important;
        &:hover {
            background-color: #BDBDBD !important;
            color: white !important;
        }
        @media screen and (max-width: 575px) {
            min-width: 60px !important;
        }
    }

    &__btn-size {
        min-width: 107px !important;
        @media screen and (max-width: 575px) {
            min-width: 60px !important;
            font-size: 10px !important;
        }
    }
}
.schedule-pick {
    width: 100%;
    max-width: 960px;
    padding: 18px 24px 18px;
    margin-top: 14px;
    background: #FFFFFF;

    @media screen and (max-width: 991px) {
        background: none;
    }

    &__scroll {
        overflow-y: scroll;
        max-height: 300px;
        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
    
    &__header {
        display: flex;
        flex-flow: column;
        margin-bottom: 14px;

        @media screen and (min-width: 576px) {
            flex-flow: row;
            align-items: center;
        }
    }

    &__title {
        font-weight: 500;
        font-family: 'Montserrat';
        font-size: 16px;
        padding-right: 21px;
        margin: 0 0 12px 0;

        @media screen and (min-width: 576px) {
            margin: 0;
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 510px) {
    .schedule__header {
        flex-direction: column;
        align-items: flex-start;
    }
}

.table-dropdown-schedule {
    padding: 8px 36px;
    width: 880px;
    margin: -8px;
    background: white;

    @media screen and (max-width: 945px) {
        width: 95vw;
    }
}

// style={{ margin: 0, maxWidth: '850px', padding: '12px', background: 'white' }}
