.tag {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    padding: 16px;
    margin-top: 20px;
    border: 1px solid #ECEDEE;
    box-sizing: border-box;
    @media screen and (max-width: 991px) {
        margin: 16px;
    }
    /* Elevation / 02 */

    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 8px;


    &__text {
        display: inline-block;
        padding: 8px 10px;
        margin-top: 8px;
        margin-right: 12px;
        background: #FFFFFF;
        border: 1px solid #ECEDEE;
        box-sizing: border-box;
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
        &:focus {
            color: black !important;
        }
        &:hover {
            color: black !important;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 8px;

    }
}