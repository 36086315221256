.content-drawer {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button,
  div {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
  }
  margin-bottom: 20px;
}

.class-content {
  font-size: 16px;
  margin: 0;
}

.class-content-detail {
  font-size: 16px;
  margin: 0;
}

.class-subcontent {
  font-size: 24px;
  font-weight: 500;
}

.class-subcontent-materi {
  font-size: 18px;
  font-weight: 500;
}

.class-subcontent-detail {
  font-size: 16px;
  font-weight: 300;
}

.class-subcontent-list {
  font-size: 14px;

  max-width: 70%;

  &-disabled {
    color: rgb(183, 180, 180);
    font-size: 14px;
  }
}

.class-subcontent-listQuiz {
  font-size: 12px;

  &-disabled {
    color: rgb(183, 180, 180);
    font-size: 12px;
  }
}

.content-drawer-project {
  border: 1px solid #dddddd;
  border-top: none;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;
  padding: 12px 24px;
  & > h2 {
    font-weight: 700;
    font-size: 14px;
  }
  & p {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
  }
  & > div {
    margin-top: 24px;
    & .btn {
      font-size: 12px;
      font-weight: 500;
    }
    & > div {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}

.class-subcontent-list-dl {
  font-size: 16px;

  // white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 430px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 1.5rem;
  overflow-y: hidden;
}

.hosted-table {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 12px 24px;
  box-sizing: border-box;
  margin-top: -2px;
  cursor: pointer;
  transition: ease;
  border-radius: 8px;
}

.rating-exam-selected {
  border: 1px solid #dddddd;
  border-left: 6px solid #ff0f51;
  padding: 12px 24px;
  box-sizing: border-box;
  margin-top: -2px;
  cursor: pointer;
  transition: ease;
  border-radius: 8px;
}

.hosted-table-subcontent {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 12px 24px;
  box-sizing: border-box;
  margin-top: -2px;
  cursor: pointer;
  transition: ease;
}

.hosted-table-subcontent-selected {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 12px 24px;
  box-sizing: border-box;
  margin-top: -2px;
  cursor: pointer;
  transition: ease;
  border-left: 3px solid #ff0f51;
}

.hosted-table-mob {
  background-color: #ffffff;
  border: 0;
  padding: 12px 24px;
  box-sizing: border-box;
  margin-top: -2px;
  cursor: pointer;
  transition: ease;
}

.hosted-table-selected {
  background-color: #eefaff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rating-exam {
  margin-left: 18px !important;
}

@media screen and (max-width: 1610px) {
  .class-content {
    font-size: 16px;
  }

  .class-content-detail {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .class-subcontent-materi {
    font-size: 14px;
    font-weight: 500;
  }
  #class-subcontent {
    padding: 0 16px;
  }
  #class-subcontent-small {
    padding: 0 0;
  }
  .hosted-table {
    padding: 12px 12px;
  }
  .rating-exam {
    margin-left: 9px !important;
    font-weight: normal;
  }
  .class-subcontent-list {
    font-size: 12px;

    max-width: 50%;
    // margin-left: 20px !important;
    &-disabled {
      color: rgb(183, 180, 180);
      font-size: 12px;
      max-width: 50%;
      // margin-left: 20px !important;
    }
  }
  .class-subcontent-list-dl {
    font-size: 12px;

    margin-left: 20px !important;
    max-width: 170px;
  }
}

.hosted-jadwal-qna {
  background: #f5f5f5;
  padding: 12px 16px;
  margin-top: 36px;
  &__jadwal {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 24px;
    margin-top: 48px;

    &__bold {
      font-weight: 500;
      font-size: 12px;
    }

    &__text {
      font-weight: 300;
      font-size: 12px;
    }
  }

  &__wa {
    cursor: pointer;
    margin-top: 8px;
    display: flex;
    align-items: center;
    background: #adf8cc;
    border: 1px solid #219653;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    @media screen and (max-width: 991px) {
      padding: 2px;
      margin-top: 0px;
    }
    & p {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-left: 8px;
      color: #067134;
      @media screen and (max-width: 991px) {
        font-size: 10px;
        font-weight: 300;
      }
    }
  }
  &__zoom {
    cursor: pointer;
    margin-top: 8px;
    display: flex;
    margin-left: 16px;
    align-items: center;
    background: #97e3fc;
    border: 1px solid #2f80ed;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    @media screen and (max-width: 991px) {
      padding: 2px;
      margin-top: 0px;
    }
    & p {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-left: 8px;
      color: #004fb9;
      @media screen and (max-width: 991px) {
        font-size: 10px;
        font-weight: 300;
      }
    }
  }
}

.course-nav-title {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button,
  div {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 991px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  & h2 {
    @media screen and (max-width: 991px) {
      margin-bottom: 16px;
    }
  }
}

.title {
  font-size: 18px;
  margin-bottom: 0;

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
}
