@import '../../styles/variables';

.text-color-black {
    color: black !important;
}

.slider {
    padding: 80px 55px 0;
    margin-bottom: 78px;

    &__title {
        margin-bottom: 20px;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 24;
    }

    &__small-text {
        padding-bottom: 40px;
    }

    &__arrow {
        position: absolute;
        top: 44%;
        
        &--left {
            left: -55px;
        }

        &--right {
            right: -55px;
        }
    }
}

.sliderCard {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    width: 100%;
    height: 100%;
    // @media screen and (max-width: 767px) {
    //     width: 274px;
    //     height: 372px;
    // }
    overflow: hidden;
    flex-direction: column;
    display: flex;
    
    &__bg {
        position: relative;
        height: 201px;
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        padding: 8px 0 12px;
        flex-shrink: 0;
    }

    &__content-main {
        font-family: 'Montserrat';
        margin-top: 20px;
        height: 48px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 700;
        letter-spacing: 0.5px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    &__labelTop {
        padding-right: 8px;

        &-text {
            background-color: $blue-5;
            border-radius: 4px;
            font-weight: 500;
            font-size: 12px;
            padding: 3px 5px;
            color: $white;
        }
    }

    &__labelBottom {
        position: absolute;
        bottom: 12px;
        right: 0;

        &-text {
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(4px);
            border-radius: 40px 0px 0px 40px;
            font-weight: 700;
            font-size: 14px;
            padding: 8px 25px;
            color: $white;
        }
    }

    &__text {
        margin-top: 8px;
    }

    &__content {
        padding: 8px 20px 27px;
        height: 100%;
        max-height: 200px;
        flex-direction: column;
        display: flex;
    }

    &__content-bottom {
        margin-top: auto;
        padding-top: 8px;
    }

    &__price {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 16px;
    }

    &__instructor-text {
        font-family: 'Montserrat';
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 1.5em;
        white-space: nowrap;
    }
}

.arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $white;
    border: 0.5px solid $gray-2;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
        transition: all 0.3s;
    }
}

.ant-carousel .slick-dots-bottom {
    bottom: -42px;
}

.ant-carousel .slick-dots li.slick-active button {
    background: $blue-1;
    opacity: 1;
}

.ant-carousel .slick-dots li button {
    background: $gray-6;
    opacity: 1;
    width: 8px;
    height: 8px;
    border-radius: 50%;

    @media screen and (min-width: 596px) {
        width: 12px;
        height: 12px; 
    }
}

.ant-carousel .slick-dots li {
    margin: 0 6px;
    height: auto;
    text-align: center;
}

.slick-slide {
    padding: 0 10px 0 11px;
}

.slick-track {
    padding-bottom: 10px;
}

.ant-carousel .slick-track {
    display: flex;
}

.ant-carousel .slick-list .slick-slide {
    height: auto;

    & > div {
        height: 100%;
    }
}

.ant-carousel .slick-dots li.slick-active {
    width: 16px;
}

@media screen and (max-width: 1200px) {
    .slider {
        padding-left: 47px;
        padding-right: 47px;
        padding-top: 41px;
        margin-bottom: 60px;
    }

    .ant-carousel .slick-dots-bottom {
        bottom: -24px;
    }
}

@media screen and (max-width: 960px) {
    .slider {
        margin-bottom: 90px;

        &__small-text {
            padding-bottom: 20px;
        }

        &__title {
            margin-bottom: 12px;
            font-family: 'Montserrat';
            font-size: 18px;
            font-weight: 500;
        }
    }
    .sliderCard {
        &__content {
            padding: 8px 12px 12px ;
        }

        // &__content-main {
        //     padding-top: 13px;
        // }

        &__labelTop-text {
            font-size: 8px;
            padding: 4px;
        }

        &__labelBottom-text {
            font-size: 12px;
            padding: 3px 12px 2px;
        }

        &__labelBottom {
            bottom: 8px;
        }

        &__price {
            font-size: 14px;
        }

        &__content-bottom {
            margin-top: auto;
            padding-top: 32px;
        }
    }

    .ant-carousel {
        font-size: 12px;
    }
}