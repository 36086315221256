.journal_list {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.journal_list_item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
