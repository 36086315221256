@import '../../styles/variables';

.beli-kelas {
    &:hover {
        color: white !important;
        background-color: #DB003C !important;
    }
}

.bar {
    padding: 10px 20px;
    margin-top: -180px;
    overflow: hidden;
    
    &__button {
        padding-bottom: 20px;
        border-bottom: 1px solid $gray-2;
    }

    &__text {
        font-size: 12px;
        padding: 8px 0 16px;
    }
}

.bar-new {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    padding: 24px 16px;
    overflow: hidden;
    background: #FFFFFF;
    border: 1px solid #ECEDEE;
    box-sizing: border-box;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
    
    &__button {
        padding-bottom: 20px;
        border-bottom: 1px solid $gray-2;
    }

    &__text {
        font-size: 12px;
        padding: 8px 0 16px;
    }

    & > div:nth-child(2) {
        margin-top: 12px;
        & > h3 {
            font-weight: 600;
            font-size: 14px;
            color: #FF0F4F;
            margin-bottom: 2px;
        }
    }

    & > h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

    }
   
    
    & > p {
        display: flex;
        align-items: center;
        margin: 16px 0;
        padding-bottom: 16px;
        border-bottom: 1px solid #ECEDEE;

    }

    & > div:nth-child(5) {
        & > div {
            display: flex;
            margin-bottom: 4px;
            & > p {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin-left: 8px;
            }
            & > div {
                width: 22px;
                height: 22px;
                align-self: flex-start;
                display: flex;
                align-items: flex-start;
            }
        }
    }

    & > div:nth-child(6) {
        margin-top: 16px;
        background: #FF0F51;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-weight: 700;
        line-height: 16px;
        padding: 20px;
    }
}

.bar-new-bottom {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    @media screen and (max-width: 991px) {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 0;
        margin-bottom: 24px;
    }
    padding: 24px 16px;
    overflow: hidden;
    background: #FFFFFF;
    border: 1px solid #ECEDEE;
    box-sizing: border-box;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
    margin-top: 24px;

    & > h2 {
        font-weight: 600;
        font-size: 16px;
    }

    & > p {
        font-weight: 400;
        font-size: 12px;
    }

    &__button > .btn {
        background: #FFFFFF !important;
        border: 1px solid #FF0F51 !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25) !important;
        border-radius: 8px;
        font-weight: 600 !important;
        font-size: 16px !important;
    }

    &__text {
        font-size: 12px;
        padding: 8px 0 16px;
    }
}

.price-new {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    margin-top: 21px;
    @media screen and (max-width: 991px) {
        margin-top: 0;
        & > div {
            display: flex;
            align-items: center;
        }
    }

    &__wrap {
        margin-top: 8px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 991px) {
            margin-top: 0;
        }
    }

    &__old {
        text-decoration: line-through;
        margin-left: 8px;
        line-height: 24px;
        color: #738492;
        font-size: 16px;
        margin-bottom: 0;
        @media screen and (max-width: 991px) {
            color: #CED3D7;
            font-size: 12px;
            margin-left: 0;
        }

    }

    &__diskon {
        font-size: 16px;
        background: #FFE8EF;
        border-radius: 4px;
        text-transform: capitalize;
        white-space: nowrap;
        padding: 2px 4px;

        @media screen and (max-width: 991px) {
            font-size: 12px;
            margin-left: 12px;
            padding: 2px 4px;
        }
    }

    &__title {
        margin-bottom: 0;
        display: flex;
        & > span, p {
            font-weight: bold;
            font-size: 30px;
            @media screen and (max-width: 991px) {
                font-size: 14px;
                color: #FFFFFF;
            }
           
        }
    }

    &__gratis {
        font-weight: 500;
        margin-top: 12px;
        margin-bottom: 12px;
    }

}
.price-new-mobile {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    margin-top: 0;
    & > div {
        display: flex;
        align-items: center;
    }

    &__wrap {
        display: flex;
        align-items: center;
        margin-top: 0;
    }

    &__old {
        text-decoration: line-through;
        line-height: 24px;
        margin-bottom: 0;
        color: #CED3D7;
        font-size: 12px;
        margin-left: 0;
    }

    &__diskon {
        background: #FFE8EF;
        border-radius: 4px;
        text-transform: capitalize;
        font-size: 12px;
        margin-left: 12px;
        padding: 2px 4px;
        white-space: nowrap;
        @media screen and (max-width: 420px) {
            margin-left: 4px;
        }
    }

    &__title {
        margin-bottom: 0;
        display: flex;
        & > span, p {
            font-weight: bold;
            font-size: 20px;
            color: #FFFFFF;
            display: flex;
            align-self: center;
            @media screen and (max-width: 991px) {
                padding: 0;
                margin-right: 8px;
            }
            @media screen and (max-width: 420px) {
                font-size: 14px;
            }
        }
    }

    &__gratis {
        font-weight: 500;
        margin-top: 12px;
        margin-bottom: 12px;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 20px;
        margin-left: auto;
    }

}

.bar-new-mobile {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    padding: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 1020;
    background: #20282E;
    display: flex;
    justify-content: center;
    transition: bottom 0.2s ease;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
    & > div {
        width: 83.3333333%;
        max-width: 1200px;
        padding: 24px 0 24px 0;
        
        @media screen and (max-width: 991px) {
            padding: 16px;
        }
        @media screen and (max-width: 991px) {
            width: 100%;
        }
        @media screen and (max-width: 420px) {
            padding: 16px 8px;
        }
        display: flex;
        & > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        margin-right: auto;
        & > h3 {
            font-weight: 600;
            font-size: 20px;
            color: #FFFFFF;
            margin-bottom: 0;
        }
        & > h4 {
            margin-bottom: 0;
            color: #FF0F4F;
            font-weight: 600;
            font-size: 14px;
        }

        @media screen and (max-width: 1360px) {
            width: 55%;
        }
        @media screen and (max-width: 1215px) {
            width: 50%;
        }
    }
    &__price {
        margin-right: 26px;
        width: 206px;
        @media screen and (max-width: 991px) {
            width: unset;
            flex-grow: 1;
        }
        @media screen and (max-width: 420px) {
            margin-right: 12px;
        }
    }
    &__btn {
        width: 206px;
        @media screen and (max-width: 1215px) {
            width: 180px;
        }
        @media screen and (max-width: 991px) {
            width: unset;
        }
        & .btn {
            padding: 16px 30px;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            border-radius: 8px;
            white-space: nowrap;
            line-height: 16px;
            margin-bottom: 8px;

            @media screen and (max-width: 1215px) {
                padding: 16px;
                font-size: 14px;
            }
            @media screen and (max-width: 420px) {
                padding: 4px 8px;
                font-size: 10px;
            }
        }
    }
}

.harga {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat';

    &__title {
        font-weight: 700;
        font-size: 16px;
        font-family: 'Montserrat';
    }
}

.harga-format {
    font-size: 30px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.btn-buy-edu {
    color: #FF0F51 !important;
    font-weight: 500 !important;
    font-family: 'Montserrat'!important;
    &:hover {
        color: #FF0F51 !important;
        background-color: #FFCFDC !important;
    }

}

.btn-pink:hover {
    background-color: #DB003C !important;
    color: white !important;
}

.btn-pink:focus {
    background-color: #DB003C !important;
    color: white !important;
}

.btn-white:hover {
    color: #FF0F51 !important;
    background-color: #FFCFDC !important;
}

.harga-format-disc {
    font-family: 'Montserrat';
}

.ticket {
    background: rgba(164, 230, 255, 0.19);
    border-radius: 4px;
    border-left: 4px solid $blue-3;
    padding: 7px 20px 6px 10px;
    font-family: 'Montserrat';
}

.price {
    padding-bottom: 8px;

    &__wrap {
        padding-left: 19px;
        display: flex;
    }

    &__old {
        font-size: 12px;
        text-decoration: line-through;
        padding-right: 20px;
    }

    &__diskon {
        color: $gray-3;
        font-size: 12px;
    }

    &__title {
        margin-bottom: 0;
    }

    &__gratis {
        font-family: 'Montserrat';
        font-weight: 500;
        margin-top: 12px;
        margin-bottom: 12px;
    }
}

.payment {
    padding: 20px 0;

    &__info {
        font-size: 12px;
        padding-bottom: 12px;
    }

    &__button {
        &:first-of-type {
            padding-bottom: 60px;
        }

        &:nth-last-of-type(2) {
            padding-bottom: 50px;
        }
    }

    &__image--first {
        width: 100%;
        max-width: 73px;
    }

    &__image--second {
        width: 100%;
        max-width: 89px;
    }

    &__image--last {
        width: 100%;
        max-width: 162px;
    }
}

.payment-new {
    margin: 10px 0 20px 0;
    display: block;
    &__info {
        font-size: 12px;
        padding-bottom: 12px;
    }

    &__button {
        &:first-of-type {
            padding-bottom: 60px;
        }

        &:nth-last-of-type(2) {
            padding-bottom: 50px;
        }
    }

    & > div {
        background: #FFFFFF;
        border: 1px solid #ECEDEE;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px 5% 4px 16px;
        margin-bottom: 8px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16 8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114 0 8 0Z' fill='%23CED3D7'/%3E%3Cpath d='M12.0547 6.30469L7.72131 10.6379C7.59131 10.7679 7.42065 10.8334 7.25 10.8334C7.07935 10.8334 6.90869 10.7679 6.77869 10.6379L4.61206 8.47131C4.35132 8.21069 4.35132 7.78931 4.61206 7.52869C4.87268 7.26794 5.29395 7.26794 5.55469 7.52869L7.25 9.224L11.1121 5.36206C11.3727 5.10132 11.7939 5.10132 12.0547 5.36206C12.3153 5.62268 12.3153 6.04395 12.0547 6.30469Z' fill='white'/%3E%3C/svg%3E%0A");
        background-position: 95% 50%;
        background-repeat: no-repeat;
    }

    &__selected {
        border: 1px solid #FF0F4F !important;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16 8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114 0 8 0Z' fill='%23FF0F4F'/%3E%3Cpath d='M12.0547 6.30469L7.72131 10.6379C7.59131 10.7679 7.42065 10.8334 7.25 10.8334C7.07935 10.8334 6.90869 10.7679 6.77869 10.6379L4.61206 8.47131C4.35132 8.21069 4.35132 7.78931 4.61206 7.52869C4.87268 7.26794 5.29395 7.26794 5.55469 7.52869L7.25 9.224L11.1121 5.36206C11.3727 5.10132 11.7939 5.10132 12.0547 5.36206C12.3153 5.62268 12.3153 6.04395 12.0547 6.30469Z' fill='white'/%3E%3C/svg%3E%0A") !important;
    }

    &__image--first {
        height: 100%;
        max-height: 32px;
        width: auto;
        -webkit-backface-visibility: hidden; 
        -ms-transform: translateZ(0); /* IE 9 */
        -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
        transform: translateZ(0);
       
    }

    

}

@media screen and (max-width: 1200px) {
    .bar {
        margin-top: -100px;
    }
}

@media screen and (max-width: 990px) {
    .bar {
        margin-top: 0;
        padding: 16px 0 0;

        &__text {
            padding: 16px 0;
        }

        &__button {
            padding-bottom: 24px;
        }
    }

    .bar.card {
        box-shadow: none;
        border-radius: none;
        background-color: $body-bg;
    }

    .price {
        display: flex;
        align-items: center;
        padding-top: 8px;

        &__old {
            padding-right: 16px;
        }

        &__wrap {
            padding-left: 16px;
        }
    }

    .ticket {
        display: flex;
        align-items: center;
        text-align: center;
        padding: 9px 12px 9px;

        &__text {
            padding-right: 5px;
        }
    }

    .payment {
        padding: 16px 0 26px;

        &__button {
            &:first-of-type {
                padding-bottom: 26px;
            }
    
            &:nth-last-of-type(2) {
                padding-bottom: 26px;
            }
        }
    
        &__image--first {
            max-width: 41px;
        }
    
        &__image--second {
            max-width: 52px;
        }
    
        &__image--last {
            max-width: 101px;
        }
    }
}

.free-modal {
    background-color: rgba(0, 0, 0, .9);
    bottom: 0;
    content: "";
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    &__dialog {
        transform: none;
        margin: 1.75rem auto;
    }

    &__size {
        width: 560px;
        max-width: 100vw;
        @media screen and (max-width: 660px) {
            width: 92vw;
        }
    }

    &__content-course {
        & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: .3rem;
        outline: 0;
        padding: 16px 20px;

        & > div:nth-child(1) {
            display: flex;
            justify-content: flex-end;
            & > div {
                cursor: pointer;
            }
        }

        & > h2 {
            text-align: center;
            width: fit-content;
            align-self: center;
            margin-top: -16px;
            margin-bottom: 24px;
        }

        & > div:nth-child(4) {
            display: flex;
            justify-content: space-around;
            margin-top: 32px;
            margin-bottom: 12px;
            & .btn {
                width: 48%;
            }
            & > div:nth-child(1) {
                margin-right: 8px;
            }
            & > div:nth-child(2) {
                margin-left: 8px;
            }
        }
    }
}