.my-order {
    line-height: 17px;
    padding-bottom: 36px;
    &__table-header th {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        border-top: 1px solid #DDDDDD;
        border-bottom: 1px solid #DDDDDD;
    }

    &__order-name {
        text-overflow: ellipsis;
        overflow: hidden; 
        width: 280px; 
        height: 1.6em; 
        white-space: nowrap;
        font-weight: 700;
    }

    &__table-content  {
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        font-size: 14px;
        border-collapse: collapse;
        & td {
            font-weight: 300;
            font-size: 14px;
            padding: 18px 12px 16px 12px;
            cursor: pointer;
            overflow: hidden; 
            width: 160px; 
            vertical-align: middle !important;
            height: 1.2em; 
            & div {
                overflow: hidden;
            }
        }

        & p {
            font-size: 14px !important;
        }
    }

    &__table-content-selected {
        background-color: #EEFAFF;
    }

    &__name {
        font-weight: 500;
        font-size: 16px;
    }

    &__mob-bold {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
    }

    &__mob-text {
        font-family: 'Montserrat';
        font-weight: 300;
        font-size: 12px;
        line-height: 22px;
        text-align: right;
    }

    &__mob-content {
        background-color: white;
        
        margin-bottom: 1.5rem;
    }
}

.hosted-table-selected-order {
    background-color: #EEFAFF;
    margin-bottom: 0 !important;
}

.hosted-table-order {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    padding: 12px 24px;
    box-sizing: border-box;
    margin-top: -2px;
    cursor: pointer;
    margin-bottom: 1.5rem;
    transition: ease;
}

.thead-light th {
    color: black !important;
    padding: 16px 12px 16px 12px;
}

.pagination li {
    // border: 1px solid #DDDDDD;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
        border-right: 1px solid #DDDDDD;
    }
}

.awal-akhir-btn {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
    border: 1px solid #DDDDDD;
    width: 55px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #FF0F51;;
    cursor: pointer;

}

.pagination-wrapper {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }

}

.pagination {
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    
}

.pageactive {
    background-color: #FF0F51;

    & a{
        color: white !important;
    }
    
    
}

.additional-row tr {
    height: 0;
    padding: 0;

}

.additional-row td {
    padding: 0;
    height: 0;

}

.additional-row td div {
    max-height: 0;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;
    transition: max-height 0.3s, padding 0.3s;
    border: 0
}

.additional-row-active td div {
    max-height: 260px;
    transition: max-height 0.6s, padding 0.6s;
}

.order-row {
    flex-direction: row;
    padding: 24px;
    margin-bottom: 16px;
    &__col {
        margin-right: 24px;
        display: flex;
        flex-direction: column;
    }

    &__name {
        font-weight: 700;
        font-size: 18px;
    }

    &__date {
        font-weight: 300;
        font-size: 14px;
    }

    &__small {
        font-weight: 300;
        font-size: 14px;
        & b {
            font-weight: 700;
        }
    }
}

.order-modal {
    padding: 16px 24px;
    &__title {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 12px;
    }

    &__img {
        min-width: 35%;
        height: 96px;
        background-position: left;
        background-size: cover;
        margin-right: 8px;
    }

    &__type {
        color: #004F8A;
        font-weight: 500;
        font-size: 14px;
    }

    &__name {
        font-weight: 700;
        font-size: 14px;
        margin-top: 12px;
        margin-bottom: 0;
    }

    &__instructor {
        font-size: 14px;
    }

    &__total {
        background-color: rgba(0, 0, 0, 0.034);
        margin: 4px 0 16px 0;
        padding: 8px 0;
    }
}