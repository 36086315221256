.my-referral {
    &__desc {
        background: #FFFFFF;
        padding: 36px 48px; 

        &__img {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 574px) {
                justify-content: center;
                margin-top: 40px;
            }
        }

        & img {
            height: auto;
            max-width: unset !important;
        }
    }

    &__box {
        @media screen and (max-width: 960px) {
            margin-top: 0;
            box-shadow: none;
            margin-top: 20px;
        }
        box-shadow: 0px 0px 10px rgba(85, 188, 245, 0.35);
        background: #FFFFFF;
        border-radius: 10px;
        padding: 36px 48px;
        & > div {
            background: #FFFFFF;
             

            & > h2 {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    &__status {
        margin-top: 40px;
        & > div {
            & > .d-flex {
                margin-top: 20px;
                & > img {
                    height: 36px;
                    width: 36px;
                    margin-right: 16px;
                }
                & > div {
                    flex-grow: 1;
                    & > h3 {
                        font-weight: 700;
                        font-size: 20px;
                        margin-bottom: 0;
                    }
                    & > h4 {
                        font-weight: 700;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__list {
            border-radius: .25rem;
            padding: 8px 8px 8px 0;
            & ul {
                list-style-type: circle;
                overflow: hidden;
            }
            & li {
                font-weight: 500;
                font-size: 14px;
            }

            & p {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 12px;
                display: flex;
                align-items: center;
                margin-left: 8px;
                margin-top: 8px;
                cursor: pointer;
            }

            & img {
                width: 40px;
                height: 40px;
                color: rgb(255, 255, 255);
                border-radius: 100%;
                cursor: pointer;
            }

            & > .d-flex {
                flex-wrap: wrap;
            }
        }
    }

    &__undang {
        margin-top: 40px;
        
        & > div {
            & > div {
                margin-top: 72px;
                display: flex;
                align-items: center;
                @media screen and (max-width: 960px) {
                    display: block;
                }
            }

            & > div > form {
                @media screen and (max-width: 960px) {
                    margin-bottom: 24px;
                }
                flex-grow: 1;
                display: flex;
                & > button { 
                    background: #FF0F51;
                    font-weight: 500;
                    font-size: 12px;
                    &:hover {
                        background: #af0d38;
                    }
                }

                & > div {
                    display: flex !important;
                    flex-grow: 1 !important;
                    & > input {
                        background: #F6F1F1;
                        font-weight: 700;
                        width: 100% !important;
                    }
                }
            }
        }
    }
}