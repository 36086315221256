.event-detail {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    
    &__main {
        padding: 40px;
        background-color: white;
        @media screen and (max-width: 991px) {
            padding: 16px;
        }
    }

    &__main-flex {
        display: flex;
        align-items: flex-start;
        @media screen and (max-width: 991px) {
            display: block;
        }
    }

    &__img {
        width: 380px;
        max-height: 380px;
        margin-right: 32px;
        @media screen and (max-width: 991px) {
            width: 100%;
            margin-right: 0;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        @media screen and (max-width: 991px) {
            margin-top: 20px;
            font-size: 20px;
            line-height: 30px;
        }
    }   

    &__text-bold {
        font-weight: 700;
        font-size: 16px;
        line-height: 38px;
    }   

    &__mediumtext {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        @media screen and (max-width: 991px) {
            margin-bottom: 20px;
            font-size: 16px;
        }
    }

    &__text {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 991px) {
            font-size: 14px;
        }
    }

    &__instructor {
        display: flex;
        align-items: center;
        border-right: 1px solid #DDDDDD;
        @media screen and (max-width: 991px) {
            border-right: 0;
        }
    }

    &__instructor-container {
        @media screen and (max-width: 991px) {
            padding: 0;
        }
    }

    &__instructor-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
        @media screen and (max-width: 991px) {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            margin-left: 10px;
        }

        &:hover {
            color: #FF0F51;
        }
    }
    
    &__card {
        @media screen and (max-width: 991px) {
            background: none;
            box-shadow: none;
            margin-top: 16px
        }
    }
}

.event-countdown {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    background: #EEFAFF url('/images/events/eventCountdownBg.png') no-repeat;
    height: 165px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__clock {
        width: 50%;
        margin-left: 0.5rem;
        margin-right: 0.3rem;
        @media screen and (max-width: 767px) {
            width: 70%;
            margin-top: 0;
            margin-left: auto;
        }

        &-number {
            font-weight: 700;
            font-size: 30px;
            line-height: 38px;
            @media screen and (max-width: 767px) {
                margin-top: 0;
                font-size: 20px;
            }
        }

        &-text {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            margin-top: 1rem;
            @media screen and (max-width: 767px) {
                margin-top: 0;
                font-size: 12px;
            }
        }

        &-el {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;
            @media screen and (max-width: 767px) {
                padding: 0 !important;
                margin-top: 0;
            }
        }
    }

    &__text {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 1rem;
            @media screen and (max-width: 767px) {
            font-size: 14px;
            width: 60%;
            margin-top: 0;
            margin-left: auto;
        }
    }

    @media screen and (max-width: 767px) {
        background-size: cover;
        height: 101px;
    }
}

.mt-4-mobile {
    margin-top: 20px;
    @media screen and (max-width: 991px) {
        margin-top: 0;
    }
}

.pl-3-mobile {
    padding-left: 1.5rem;
    @media screen and (max-width: 991px) {
        padding-left: 0;
    }
}

.w-50-mobile {
    width: 100%;
    @media screen and (max-width: 991px) {
        width: 100%;
    }
}

.p-0-mobile {
    @media screen and (max-width: 991px) {
        padding: 0 !important;
    }
}