@import '../../styles/variables';

.search {
  background-color: $blue-2;
  background-image: url('../../static/voucher.svg');
  background-repeat: no-repeat;
  background-position: 23%;
  min-height: 68px;
  display: flex;
  align-items: center;

  &__input {
    margin-right: 20px;
    margin-left: 37px;
    padding: 6px 7px 7px 8px;
    border: 1px solid $gray-2;
    border-radius: 4px;
    background-color: $white;
    max-width: 237px;
    width: 100%;
    @media screen and (max-width: 991px) {
      margin: 0px;
    }

    &::placeholder {
      color: $gray-1;
      font-size: 14px;
      font-family: 'Montserrat';
    }

    &:focus {
      outline: none;
    }
  }
}

.search__mob {
  display: none;
}

.search__desc {
  display: flex;
}

@media screen and (max-width: 750px) {
  .check-voucher {
    font-size: 12px;
    margin: 8px 0;
    text-align: center;
  }

  // .search {
  //     height: 32px;
  //     background-size: 30%;
  //     background-position: 12%;
  // }

  // .search__desc {
  //     display: none;
  // }

  // .search__mob {
  //     display: flex;

  //     &-text {
  //         margin-bottom: 0;
  //         padding-right: 9px;
  //     }
  // }
}

.search-new {
  & p,
  h1,
  h2,
  h3,
  h4,
  a,
  span,
  button,
  input {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  }
  background-color: #20282e;
  min-height: 68px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 991px) {
    min-height: 48px;
  }

  & > div {
    width: 100%;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        margin-right: 16px;
      }
      & > p {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
        & > span {
          cursor: pointer;
          margin-left: 8px;
          color: #ff0f4f;
          font-size: 12px;
          text-decoration: underline;
        }
      }

      & .form-prakerja-small,
      .form-prakerja-small__mobile {
        display: flex;
        gap: 0.5rem;
        margin-left: 0.5rem;
        flex-direction: column;

        @media screen and (min-width: 991px) {
          align-items: center;
          flex-direction: row;
        }

        & input {
          background: #ffffff;
          border: 1px solid #ecedee;
          border-radius: 8px;
          //   max-width: 237px;
          padding: 6px 12px;
          //   margin-left: 16px;
          //   margin-right: 8px;
          min-width: 50%;
          font-size: 14px;
          @media screen and (max-width: 991px) {
            margin: 0px;
            padding: 2px 4px;
            font-size: 12px;
          }

          &::placeholder {
            color: $gray-1;
            font-size: 14px;
          }

          &:focus {
            outline: none;
          }
        }
        & button {
          font-size: 14px;
          box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
          border-radius: 8px;
          padding: 8px 20px;
          @media screen and (max-width: 991px) {
            // margin: 0px;
            padding: 2px 4px;
            // margin-left: 8px;
            font-size: 12px;
          }
        }

        &__mobile {
          width: 100%;
          padding: 1rem;
        }
      }
    }
  }
}

.search__mob {
  display: none;
}

.search__desc {
  display: flex;
}

@media screen and (max-width: 750px) {
  .check-voucher {
    font-size: 12px;
    margin: 8px 0;
    text-align: center;
  }

  // .search {
  //     height: 32px;
  //     background-size: 30%;
  //     background-position: 12%;
  // }

  // .search__desc {
  //     display: none;
  // }

  // .search__mob {
  //     display: flex;

  //     &-text {
  //         margin-bottom: 0;
  //         padding-right: 9px;
  //     }
  // }
}
