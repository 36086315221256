.user-profile {
    font-family: 'Montserrat';
    font-style: normal;

    &__container {
        margin-top: 80px;
        @media screen and (max-width: 991px) {
            margin-top: -26px;
            
        } 
    }

    &__name {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 22px;
        }
    }   

    &__picture {
        height: 120px;
        width: 120px;
        border-radius: 100px;
        @media screen and (max-width: 991px) {
            height: 52px;
            width: 52px;
            border-radius: 26px;
        } 
    }

    &__email {
        font-weight: 325;
        font-size: 20px;
        line-height: 32px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__btn {
        width: 120px;
        font-size: 14px;
        padding: 9px 16px;

        &:hover {
            background-color: #FFCFDC;
            color: #FF0F51;
        }
        @media screen and (max-width: 991px) {
            margin-top: 33px;
            margin-bottom: 26px;
        }
    }
}

.dashboard-container-user-profile {
    min-height: 800px;
    @media screen and (max-width: 991px) {
        min-height: unset;

    }
}

.badge {
    &__container {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 20px 30px;
        width: 270px;
        height: 300px;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
        }
    }

    &__name {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
    }
    
}

.modal-size-badge {
    width: 460px;
    max-width: 100vw;
}

.badge-modal {
    &__pic {
        width: 380px;
        height: 250px;
        background-image: url("/images/badges/bg.png");
    }
   
    &__pic_small {
        width: 202px;
        height: 195px;
        background-image: url("/images/badges/bg-bulu.png");
        background-position: center;
        background-size: cover;
    }

    &__picture {
        width: 158px;
        height: 158px;
    }

    &__by {
        font-size: 12px;
        line-height: 24px; 
        text-align: center; 
    }
}

.d-flex-desktop-profile {
    display: flex;
    @media screen and (max-width: 911px) {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
}



.edit_profile_form {
    padding: 0 28px 28px 28px;
    margin-left: 3rem;
    @media screen and (max-width: 911px) {
        padding: 10px;
        margin-left: 0;
        margin-top: 20px;
    }
}