@import '../../styles/variables';

.btn {
    outline: none;
    cursor: pointer;
    color: $white;
    line-height: 1.5;
    padding: 7px;
    min-width: 90px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 1px solid transparent;
    transition: all 0.3s;
    &-pink {
        background-color: $pink;

        &:hover,
        &:focus {
            color: $pink;
            border-color: $pink;
            background-color: $white;
        }
    }
    
    &-big {
        width: 100%;
        padding: 12px;
    }

    &-white {
        background-color: $white;
        border: 1px solid $pink;
        color: $pink;
        padding: 11px;

        &:hover,
        &:focus {
            color: $white;
            background-color: $pink;
        }
    }
}

@media screen and (max-width: 960px) {
    .btn {
        font-size: 14px;

        &-big {
            padding: 10px 0 9px;
        }
    
        &-white {
            padding: 9px 0 8px;
        }
    }
}