.course-title {
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
    font-size: 30px;
    font-weight: 500;
    margin-top: 30px;
}

// @media screen and (max-width: 1610px) {
//     .course-title {
//         font-size: 30px;
//     }
// }