.my-event {
    font-family: 'Montserrat';

    &__container {
        background: #FFFFFF;
        padding: 20px 18px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        // width: 85%;
        @media screen and (max-width: 767px) {
            width: 100%;
            padding: 8px;
        }
        display: flex;
        flex-direction: column;

        &__sub {
            justify-content: start;
            width: 100%;
            @media screen and (max-width: 991px) {
                justify-content: center;
            }
        }
    }

    &__bold-text {
        color: black;
        font-weight: 500;
        font-size: 14px;
        @media screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    &__image {
        width: 160px;
        height: 160px;
        border-radius: 4px;
        @media screen and (max-width: 991px) {
            width: 104px;
            height: 104px;
        }
        
    }

    &__prakerja {
        background-color: #76CAEA;
        border-radius: 4px;
        padding: 2px 5px;
        color: white;
        font-size: 12px;
        text-align: center;
        width: fit-content;
        margin-bottom: 24px;
    }

    &__border-right {
        border-right: 1px solid #DDDDDD;
        @media screen and (max-width: 767px) {
            border-right: 1px solid #DDDDDD;
        }
    }

    &__detil-btn {
        padding: 5px 24px;
        margin-left: auto;
        @media screen and (max-width: 1040px) {
            margin-left: 80px;
        }
        @media screen and (max-width: 991px) {
            margin-left: auto;
            padding: 5px 12px;
        }
    }
}
