.fitur {
    font-family: 'Montserrat';
    padding: 20px;
    margin-top: 20px;

    &__item {
        padding-top: 18px;

        &__last {
            text-align: right;
            margin-left: 4px;
        }
    }

    &__title {
        font-family: 'Montserrat';
        font-weight: 700;
    }
}

@media screen and (max-width: 960px) {
    .fitur.card {
        box-shadow: none;
        background-color: $body-bg;

        .card__title {
            margin-bottom: 0;
            padding-bottom: 20px;
            border-bottom: none;
        }
    }


    .fitur {
        margin-top: 22px;
        padding: 0 8px;

        &__title {
            padding-bottom: 0 !important;
            font-size: 18px;
            font-weight: 700;
            font-family: 'Montserrat';
        }

        &__item {
            flex-direction: column;
            flex: 0 0 50%;
            padding-top: 10px;
            
            &__last {
                text-align: left;
                margin-left: 0;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
        }
    }
}