@import '../fonts/index.scss';
@import '../variables';

html,
body {
    font-size: 16px;
    line-height: 1.6;
    font-family: 'Montserrat';
    color: $black;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 500;
}

h1,
.h1 {
    font-size: 30px;
}

h2,
.h2 {
    font-size: 24px;
}

h3,
.h3 {
    font-size: 16px;
    line-height: 1.25;
}

h4,
.h4 {
    font-size: 14px;
    line-height: 1.5;
}

a {
    transition: color 0.3s ease-in-out;
}

.tx {
    &-normal {
        font-size: 16px;
    }

    &-small {
        font-size: 14px;
    }

    &-tiny {
        font-size: 12px;
    }
}

$tx-colors: (
    '1': $white,
    '2': $black,
    '3': $gray-5,
);

@each $number, $color in $tx-colors {
    .tx-color {
        &-#{$number} {
            color: #{$color};
        }
    }
}

.tx-pink {
    color: $pink;
}

.tx-white {
    color: $white;
}

// Font Weight
$font-weights: (700, 500, 400, 300);

@each $value in $font-weights {
    .font-weight {
        &-#{$value} {
            font-weight: #{$value};
        }
    }
}


// Line heights 
$line-heights: (
    '1': 1,
    '11': 1.1,
    '12': 1.2,
    '13': 1.3,
    '14': 1.4,
    '15': 1.5,
);
@each $number, $value in $line-heights {
    .line-height {
        &-#{$number} {
            line-height: #{$value};
        }
    }
}

// Letter spacing 
$letter-spacings: (
    '02': 0.2,
    '03': 0.3,
);
@each $number, $value in $letter-spacings {
    .letter-spacing {
        &-#{$number} {
            line-height: #{$value}px;
        }
    }
}


@media screen and (max-width: 960px) {
    html,
    body {
        font-size: 12px;
    }

    h2,
    .h2 {
        font-size: 16px;
    }

    h1,
    .h1 {
        font-size: 22px;
    }

    h3,
    .h3 {
        font-size: 14px;
    }
}
