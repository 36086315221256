.person {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important; }
    padding: 20px 24px 36px 24px;
    margin: 20px 0;

    &__wrap {
        padding-left: 17px;
    }

    &__info {
        padding-left: 16px;
    }

    &__title {
        margin-bottom: 2px;
        font-weight: 700;
        font-size: 24px;
        line-height: 20px;
        &:hover {
            color: #288AAF !important;
        }
    }

    &__main-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
    }

    &__image{
        display: flex;
        align-items: center;
    }

    &__image img {
        height: 80px;
        width:80px;
        border-radius: 40px;
        max-width: unset;
        cursor: pointer;
        transition: 200ms;
        &:hover {
            filter: brightness(70%);
            margin-bottom: 8px;
        }
    }

    &__desc p {
        font-size: 14px !important;
    }

    &__desc ul {
        list-style-type: circle;
        font-size: 14px !important;
        margin-left: 1rem;
    }
}



@media screen and (max-width: 960px) {
    .person {
        padding: 16px;
        margin: 16px 0;
    
        &__wrap {
            padding-left: 0;
            align-items: center;
        }
    
        &__info {
            padding-left: 35px;
        }
    
        &__main-title {
            font-size: 18px;
            margin-bottom: 0;
        }

        &__title {
            font-size: 14px;
            margin-bottom: 0;
        }

        &__image{
            display: flex;
            align-items: center;
        }

        &__image img {
            height: 60px;
            width: 60px;
        }

        &__desc p {
            font-size: 12px !important;
            line-height: 14px;
        }
    }
}

.person-new {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    margin-top: 24px;
    padding-top: 0;
    padding-bottom: 0;
    @media screen and (max-width: 991px) {
        padding: 0 16px;
        margin-bottom: 40px;
        & .info__text {
            line-height: 20px;
        }
    }
    & > div:nth-child(1) {
        display: flex;
        align-items: center;
        & > div:nth-child(2) {
            margin-left: 16px;
            & > a > h2 {
                font-weight: 700;
                font-size: 16px;
            }
            & > h3 {
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 0;
            }
        }
    }

    & > div:nth-child(2) {
        margin-left: 80px;
        margin-bottom: 16px;
        margin-top: 10px;

    }

    & > p {
        font-weight: 400;
        font-size: 14px;
        & p, span {
            font-size: 14px;
            font-weight: 400;
        }
    }
    

    &__image{
        display: flex;
        align-items: center;
    }

    &__image img {
        height: 64px;
        width:64px;
        border-radius: 40px;
        max-width: unset;
        cursor: pointer;
        transition: 200ms;
        &:hover {
            filter: brightness(70%);
            position: relative;
            bottom: 2px;
        }
    }
}