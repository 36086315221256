@import '../../styles/variables';

.footerTop {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    background-color: $black;
    color: $white;
    padding: 20px 0 30px;

    &__container {
        padding: 0 136px;
    }

    &__title {
        color: $white;
        text-transform: uppercase;
        margin-bottom: 21px;
        font-family: 'Montserrat';
        font-weight: 700;
    }

    &__text-title {
        color: $white;
        line-height: 1;
        margin-bottom: 24px;
        flex-wrap: nowrap;
        font-family: 'Montserrat';
        font-weight: 500;
    }

    &__text-normal {
        font-size: 14px;
        min-width: 100px;
    }

    &__text-link {
        display: block;
        color: $white;
        line-height: 1.75;
        font-size: 14px;
        font-weight: 300;
        &:hover {
            color: #FF0F51 !important;
        }
    }

    &__text-bold {
        font-weight: 700;
    }

    &__text-normal {
        color: $white;
        font-size: 14px;
        font-weight: 300;
    }

    &__link {
        color: $white;
        font-size: 14px;
        font-weight: 500;
        margin-left: -3px;
    }

    &__contact {
        margin-top: -5px;
    }

    &__contact-link {
        column-count: 2;
        column-width: 30px;
    }
}

.footerBottom {
    & p, h1, h2, h3, h4, a, span, button { font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;; }
    padding: 13px 0;
    background-color: $white;
    display: flex;
    align-items: center;

    &__block {
        flex-wrap: wrap;
        @media screen and (max-width: 1200px) {
            flex-direction: column-reverse;
        }
    }

    &__container {
        padding: 0 136px;
    }

    &__link {
        font-size: 14px;
        margin-right: 60px !important;

        &:last-child {
            margin-right: 0;
        }
    }
    &__links_link {
        display: flex;
        align-items: center;
    }
    &__links_social {
        display: flex;
        align-items: center;
        margin-left: auto;
        @media screen and (max-width: 1720px) {
            margin-right: 15vw;
        }
        @media screen and (max-width: 1200px) {
            margin-right: 0;
            margin-left: 0;
        }
    }
    &__social-link {
        
        display: flex;
        padding: 12px;
        align-items: center;
        margin-left: 12px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.footerBottom-course-detail {
    padding-bottom: 120px;
    @media screen and (max-width: 991px) {
        padding-bottom: 80px;
    }
}


@media screen and (max-width: 960px) {
    .footerTop {
        padding: 20px 0;
        &__container {
            padding: 0 24px;
        }

        &__leftBlock {
            order: 2;
        }

        &__rightBlock {
            order: -1;
        }

        &__text-title {
            font-size: 24px;
        }

        &__myedusolve {
            padding-bottom: 25px;
        }

        &__title {
            margin-bottom: 27px;
        }

        &__contact {
            padding-top: 40px;
            padding-bottom: 36px;
        }

        &__head-office {
            padding-bottom: 19px;
        }
    }
    .footerBottom {
        &__container {
            padding: 0 24px;
        }

        &__block {
            display: block !important;
        }

        &__links_link {
            margin: 18px 0;
            text-align: center;
            justify-content: space-around;
        }

        &__links_social {
            text-align: center;
            justify-content: center;
            margin-right: 0;

        }

        &__link {
            margin-right: 0 !important;
        }
    }
}
@media screen and (max-width: 610px) {
    .footerBottom {
        &__container {
            padding: 0 24px 60px 24px;
        }
    }
}

.sv_main {
    font-family: 'Poppins' !important;
    position: fixed !important;
    width: 100vw !important;
    z-index: 2000 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
}

.sv_q_title {
    font-family: 'Poppins' !important;
    font-weight: 700 !important;
    text-align: center;
    margin-bottom: 12px !important;
}

.sv_q_rating_max_text {
    font-size: 12px !important;
    @media screen and (max-width: 720px) {
        display: none !important;
    }
}

.sv_q_rating_min_text {
    font-size: 12px !important;
    @media screen and (max-width: 720px) {
        display: none !important;
    }
}

.sv_q_rating {
    text-align: center;
}

.survey-wrapper {
    position: sticky;
    width: 100vw;
    z-index: 2000 !important;
    & > button {
        font-family: 'Poppins' !important;
        z-index: 2001 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        position: fixed !important;
        font-size: 14px;
        font-weight: 600;
        min-width: 100px;
        cursor: pointer;
        padding: 0 2em;
        border-radius: 2px;
        margin-bottom: 14.5px;
        margin-left: auto;
        margin-right: 120px;
    }
}

.aws-icon-footer-desktop {
    margin-right: 16px;
    @media screen and (max-width: 960px) {
        display: none !important;
    }
}

.aws-icon-footer-mobile {
    margin-bottom: 12px;
    display: none !important;
    @media screen and (max-width: 960px) {
        display: block !important;
    }
}

.lengkapi-profile {
    font-family: 'Montserrat' !important;
    position: fixed !important;
    width: 100vw !important;
    z-index: 2000 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    display: flex;
    justify-content: flex-end;
    padding: 24px 36px;
    @media screen and (max-width: 960px) {
        padding: 12px 20px;
    }

    & > div {
        width: 360px !important;
        padding: 16px;
        background: #FFFFFF;
        border-radius: 8px;
        color: #000000;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 1px 3px #c5c5c5;
        @media screen and (max-width: 960px) {
            width: 300px !important;
        }

        & > h1 {
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            margin-bottom: 12px;
            margin-top: -12px;
            @media screen and (max-width: 960px) {
                font-size: 16px;
            }
        }
        & > p {
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            margin-bottom: 12px;
            @media screen and (max-width: 960px) {
                font-size: 10px;
            }
        }
        
    }
}